// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
#log {
    /* font-family: Arial, Helvetica, sans-serif; */
    border-collapse:collapse;
 
    width: 100%;
  }
  
  #log td, #log th {
    border: 1px solid #60656A;
    padding: 8px;
    
  }
    
  #log th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
    /* background: #444749; */
    color: black;
    font-weight: 1500;
  }
  #log tr, #log td {
    border: 1px solid #60656A;
    padding: 8px;
    text-align: center; /* Align the content in table cells */
  }


  #alert td {
    color: white;
    font-size: 14px;
    text-align: center;
    
  }
  #alert tr {
    text-align: center;
    align-items: center;
  }`, "",{"version":3,"sources":["webpack://./src/Components/TableView/logTable.css"],"names":[],"mappings":";AACA;IACI,+CAA+C;IAC/C,wBAAwB;;IAExB,WAAW;EACb;;EAEA;IACE,yBAAyB;IACzB,YAAY;;EAEd;;EAEA;IACE,iBAAiB;IACjB,oBAAoB;IACpB,kBAAkB;IAClB,yBAAyB;IACzB,YAAY;IACZ,iBAAiB;EACnB;EACA;IACE,yBAAyB;IACzB,YAAY;IACZ,kBAAkB,EAAE,qCAAqC;EAC3D;;;EAGA;IACE,YAAY;IACZ,eAAe;IACf,kBAAkB;;EAEpB;EACA;IACE,kBAAkB;IAClB,mBAAmB;EACrB","sourcesContent":["\r\n#log {\r\n    /* font-family: Arial, Helvetica, sans-serif; */\r\n    border-collapse:collapse;\r\n \r\n    width: 100%;\r\n  }\r\n  \r\n  #log td, #log th {\r\n    border: 1px solid #60656A;\r\n    padding: 8px;\r\n    \r\n  }\r\n    \r\n  #log th {\r\n    padding-top: 12px;\r\n    padding-bottom: 12px;\r\n    text-align: center;\r\n    /* background: #444749; */\r\n    color: black;\r\n    font-weight: 1500;\r\n  }\r\n  #log tr, #log td {\r\n    border: 1px solid #60656A;\r\n    padding: 8px;\r\n    text-align: center; /* Align the content in table cells */\r\n  }\r\n\r\n\r\n  #alert td {\r\n    color: white;\r\n    font-size: 14px;\r\n    text-align: center;\r\n    \r\n  }\r\n  #alert tr {\r\n    text-align: center;\r\n    align-items: center;\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
