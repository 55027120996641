import React, { useState, useEffect } from "react";
import { baseSocketUrl, baseUrl, IntervalAPI } from "../../constants";
import ScanButton from "./ScanButton";
import CircularProgress from "@mui/material/CircularProgress";
import "./softwareUpgrade.css";
import axios from "axios";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Pagination from "@mui/material/Pagination";
import HistoryIcon from "@mui/icons-material/History";
import CompareIcon from "@mui/icons-material/Compare";
import Dialog from "../Dialog/Dialog";
import ReportPopUp from "../Dialog/ReportPopUp";
import Accordion from "../Accordion/Accordion";
import { UPDATE_MESSAGE, UPDATE_MESSAGE_TRIMMED } from "./constants";
import TableView from "../TableView/TableView";
import moment from "moment";
import { useToast } from "../../Hooks/useToast";
import NotifyJiraButton from "./NotifyJira";
import { useParams } from "react-router-dom";


let ccid = null,
  ssid = null,
  ccitid = null,
  ccmodel = null;

function VehicleScan({ navSelected }) {
  const { handleToastOpen, renderToast } = useToast();
  const MOCK = false;
  const [dialogOpen, setDialogOpen] = useState(false);
  const [compareLoading, setCompareLoading] = useState(false);
  const [compareData, setCompareData] = useState(null);
  const [updatePLMLoading, setUpdatePLMLoading] = useState(null);
  const [historyDialogOpen, setHistoryDialogOpen] = useState(false);
  const [logsDialogOpen, setLogsDialogOpen] = useState(false);
  const [historyLoading, setHistoryLoading] = useState(false);
  const [logsLoading, setLogsLoading] = useState(false);
  const [historyData, setHistoryData] = useState([]);
  const [logsData, setLogsData] = useState([]);
  const [vehicleCount, setVehicleCount] = useState(0);
  const toggleDialog = () => {
    setDialogOpen((prevState) => !prevState);
  };
  const toggleHistoryDialog = () => {
    setHistoryDialogOpen((prevState) => !prevState);
  };
  const toggleLogsDialog = () => {
    setLogsDialogOpen((prevState) => !prevState);
  };
  const modelTemp = null;
  // const [model, setModel] = useState(
  //   modelTemp === null || modelTemp === undefined
  //     ? ""
  //     : localStorage.getItem("model")
  // );
  const { mode } = useParams();
  console.log(" mode",mode);
  const [model, setModel] = useState(
    modelTemp === null || modelTemp === undefined
      ? 4
      : localStorage.getItem("model")
  );
  const [modelList, setModelList] = useState([]);
  const [pageApi, setPageApi] = useState(1);
  const [isLoaded, setIseLoaded] = useState(false);
  const [carList, setCarList] = useState([]);
  const [carStatus, setCarStatus] = useState({});
  const [carStatusTemp, setCarStatusTemp] = useState({});
  const [checkedState, setCheckedState] = useState(new Array(20).fill(false));
  const [checkedList, setcheckedList] = useState([]);
  const [checkedStateAll, setCheckedStateAll] = useState(false);
  const [scheduleDeviceList, setScheduleDeviceList] = useState([]);
  const [deviceUpgradeStats, setDeviceUpgradeStats] = useState([]);
  const [triggerApi, setTriggerApi] = useState(false);
  const [selectedVin, setSelectedVin] = useState(null);
  const toggleTriggerApi = () => {
    setTriggerApi((prevState) => !prevState);
  };

  const handleModel = (event) => {
    setModel(event.target.value);
    console.log("Model click event",event.target.value)
    localStorage.setItem("model", event.target.value);
  };

  // jwt token
  const getJwtToken = () => {
    let token = localStorage.getItem("token") || undefined;
    return token === undefined ? "" : `${JSON.parse(token)}`;
  };

  useEffect(() => {
    const fetchModelList = async () => {
      await axios
        .post(
          baseUrl + "/get/models_list/",
          {},
          {
            headers: { Authorization: `Bearer ${getJwtToken()}` },
          }
        )
        .then((res) => {
          setModelList(res.data);
        })
        .catch((e) => {
          console.log(e);
          if (MOCK)
            setModelList([
              {
                model_id: 1,
                model_name: "Porsche GT3 RS",
              },
              {
                model_id: 2,
                model_name: "BMW M5",
              },
              {
                model_id: 3,
                model_name: "Audi RS Q8",
              },
            ]);
        });
    };
    fetchModelList();
  }, []);

  // Device Update stats
  useEffect(() => {
    const updatedCheckedAllState = checkedState.map((item) => false);
    setCheckedState(updatedCheckedAllState);
    setcheckedList([]);
    setScheduleDeviceList([]);
    setCheckedStateAll(false);

    let cid = null;
    let sid = null;
    let citid = null;
    let cmodel = null;
    if (model === "" || model === undefined) {
      cmodel = null;
      ccmodel = null;
    } else {
      cmodel = model;
      ccmodel = model;
    }

    // country or city or state are selected

    if (navSelected !== undefined && navSelected !== null) {
      // console.log(navSelected)
      if (navSelected.type === "country") {
        cid = !navSelected.show ? navSelected.id : null;
      } else if (navSelected.type == "state") {
        if (!navSelected.show) {
          cid = navSelected.prevcountryid;
          sid = navSelected.id;
        } else {
          cid = navSelected.prevcountryid;
        }
      } else {
        if (!navSelected.show) {
          cid = navSelected.prevcountryid;
          sid = navSelected.prevstateid;
          citid = navSelected.id;
        } else {
          cid = navSelected.prevcountryid;
          sid = navSelected.prevstateid;
        }
      }

      console.log(cid, sid, citid);
      const param = {
        model: cmodel,
        country_id: cid,
        state_id: sid,
        city_id: citid,
        // hardware:cHardware,
      };
      console.log(param);

      //  main list view
      const url = baseUrl + "/get/vechilles_list/";
      const body = {
        model_id: cmodel,
        country_id: cid,
        state_id: sid,
        city_id: citid,
        page_limit: 8,
        page_number: pageApi,
      };
      setIseLoaded(false);
      getVehicleCount(body);
      const fetchDeviceList = async () => {
        await axios
          .post(url, body, {
            headers: { Authorization: `Bearer ${getJwtToken()}` },
          })
          .then((res) => {
            setIseLoaded(true);
            const temp = res.data;
            // console.log(searchInput)
            setCarList(temp);
            //   if(res.data.bool != 1){
            //     navigate("/login");
            // }
          })
          .catch((e) => {
            console.log("fetchDeviceList Error");
            if (MOCK) {
              setIseLoaded(true);
              setCarList([
                {
                  vin: "5XYKT4A64CG224929",
                  registration_number: "5SWR149",
                  model_name: "Porsche GT3 RS",
                  currentversion: "1.1.1",
                  last_updated: "2023-06-20T07:23:53.026728Z",
                  devicestate: "Offline",
                  scan_status: "Initiated",
                  devicename: "Device-Name-6",
                },
                {
                  vin: "3VWDZ7AJ7BM373945",
                  registration_number: "9FUH890",
                  model_name: "Porsche GT3 RS",
                  currentversion: "1.1.50",
                  last_updated: "2023-06-20T07:23:53.026728Z",
                  devicestate: "Offline",
                  scan_status: "Initiated",
                  devicename: "Device-Name-21",
                },
                {
                  vin: "WV2YB0257EH008533",
                  registration_number: "AP 01 VI 1234",
                  model_name: "Porsche GT3 RS",
                  currentversion: "1.1.1",
                  last_updated: "2023-06-20T07:23:53.026728Z",
                  devicestate: "Offline",
                  scan_status: "Initiated",
                  devicename: "Device-Name-3",
                },
                {
                  vin: "3FADP4FJ2BM113913",
                  registration_number: "KA 01 AQ 1234",
                  model_name: "Porsche GT3 RS",
                  currentversion: "1.10.4",
                  last_updated: "2023-06-20T09:14:28.036427Z",
                  devicestate: "Offline",
                  scan_status: "Initiated",
                  devicename: "nxp-goldbox-in",
                },
              ]);
            }
          });
      };
      fetchDeviceList();
      const paramSocket = {
        country_id: cid,
        state_id: sid,
        city_id: citid,
        vehiclemodel: cmodel,
        // hardware:cHardware
      };

      //  data from socket
      const getDatafromSocket = () => {
        var socket = new WebSocket(baseSocketUrl + "/devicestate/");
        socket.onopen = () => {
          console.log("connection success for Car Online Status");
          console.log(JSON.stringify(paramSocket));
          socket.send(JSON.stringify(paramSocket));
        };
        socket.onmessage = function (event) {
          setCarStatus(JSON.parse(event.data));
          if (carStatus !== carStatusTemp) {
            setCarStatusTemp(JSON.parse(event.data));
          }
        };
      };

      getDatafromSocket();

      ccid = cid;
      ssid = sid;
      ccitid = citid;
      // ccHardware = cHardware;

      const paramSocketVersion = {
        country_id: cid,
        vehiclemodel: cmodel,
        country_id: cid,
        state_id: sid,
        city_id: citid,
        // hardware:cHardware
      };

      //  data from socket
      const getDatafromSocketVersion = () => {
        var socket = new WebSocket(baseSocketUrl + "/upgrade/");
        socket.onopen = () => {
          console.log("connection success for Car stats");
          console.log(JSON.stringify(paramSocketVersion));
          socket.send(JSON.stringify(paramSocketVersion));
        };
        socket.onmessage = function (event) {
          if (
            ccid === cid &&
            ssid === sid &&
            ccitid === citid &&
            ccmodel === cmodel
            // && ccHardware === cHardware
          ) {
            if (event.data !== "Connection Successful.") {
              setDeviceUpgradeStats(JSON.parse(event.data));
            }
          }
        };
      };

      getDatafromSocketVersion();
    } else {
      // if city country state nothing salected

      const param = {
        model: cmodel,
        country_id: cid,
        state_id: sid,
        city_id: citid,
        // hardware:cHardware
      };
      console.log(param);
      const url = baseUrl + "/get/vechilles_list/";
      const body = {
        page_limit: 8,
        page_number: pageApi,
        model_id: cmodel,
        country_id: cid,
        state_id: sid,
        city_id: citid,
        // hardware:cHardware
      };
      setIseLoaded(false);
      getVehicleCount(body);
      const fetchDeviceList = async () => {
        await axios
          .post(url, body, {
            headers: { Authorization: `Bearer ${getJwtToken()}` },
          })
          .then((res) => {
            setIseLoaded(true);
            const temp = res.data;
            // console.log(searchInput)
            setCarList(temp);
            //   if(res.data.bool != 1){
            //     navigate("/login");
            // }
          })
          .catch((e) => {
            console.log("fetchDeviceList Error");
            if (MOCK) {
              setIseLoaded(true);
              setCarList([
                {
                  vin: "5XYKT4A64CG224929",
                  registration_number: "5SWR149",
                  model_name: "Porsche GT3 RS",
                  currentversion: "1.1.1",
                  last_updated: "2023-06-20T07:23:53.026728Z",
                  devicestate: "Offline",
                  scan_status: "Initiated",
                  devicename: "Device-Name-6",
                },
                {
                  vin: "3VWDZ7AJ7BM373945",
                  registration_number: "9FUH890",
                  model_name: "Porsche GT3 RS",
                  currentversion: "1.1.50",
                  last_updated: "2023-06-20T07:23:53.026728Z",
                  devicestate: "Offline",
                  scan_status: "Initiated",
                  devicename: "Device-Name-21",
                },
                {
                  vin: "WV2YB0257EH008533",
                  registration_number: "AP 01 VI 1234",
                  model_name: "Porsche GT3 RS",
                  currentversion: "1.1.1",
                  last_updated: "2023-06-20T07:23:53.026728Z",
                  devicestate: "Offline",
                  scan_status: "Initiated",
                  devicename: "Device-Name-3",
                },
                {
                  vin: "3FADP4FJ2BM113913",
                  registration_number: "KA 01 AQ 1234",
                  model_name: "Porsche GT3 RS",
                  currentversion: "1.10.4",
                  last_updated: "2023-06-20T09:14:28.036427Z",
                  devicestate: "Offline",
                  scan_status: "Initiated",
                  devicename: "nxp-goldbox-in",
                },
              ]);
            }
          });
      };
      fetchDeviceList();

      // socket data

      const paramSocket = {
        country_id: cid,
        // hardware:cHardware
      };

      //  data from socket
      const getDatafromSocket = () => {
        var socket = new WebSocket(baseSocketUrl + "/devicestate/");
        socket.onopen = () => {
          console.log("connection success for Car Online Status");
          socket.send(JSON.stringify(paramSocket));
        };
        socket.onmessage = function (event) {
          setCarStatus(JSON.parse(event.data));
          if (carStatus !== carStatusTemp) {
            setCarStatusTemp(JSON.parse(event.data));
          }
        };
      };

      getDatafromSocket();

      ccmodel = model;
      // ccHardware = hardware;
      const paramSocketVersion = {
        vehiclemodel: model,
        // hardware:cHardware
      };

      console.log(ccmodel, model);
      //  data from socket
      const getDatafromSocketVersion = () => {
        var socket = new WebSocket(baseSocketUrl + "/upgrade/");
        socket.onopen = () => {
          console.log("connection success for Car stats else block");
          console.log(JSON.stringify(paramSocketVersion));
          socket.send(JSON.stringify(paramSocketVersion));
        };
        socket.onmessage = function (event) {
          if (
            ccmodel === model &&
            ccid === cid &&
            ssid === sid
            // && ccHardware === hardware
          ) {
            console.log(JSON.parse(event.data));
            if (event.data !== "Connection Successful.") {
              setDeviceUpgradeStats(JSON.parse(event.data));
            }
          }
        };
      };

      getDatafromSocketVersion();
    }
  }, [navSelected, model, pageApi, triggerApi]);

  // checkbox
  const handleOnChangeAllCheckbox = () => {
    if (checkedStateAll) {
      const updatedCheckedAllState = checkedState.map((item) => false);
      setCheckedState(updatedCheckedAllState);
      setcheckedList([]);
      setScheduleDeviceList([]);
    } else {
      const updatedCheckedAllState = checkedState.map((item, index) => {
        if (index >= (pageApi - 1) * 8 && index < pageApi * 8) {
          return true;
        } else {
          return false;
        }
      });
      setCheckedState(updatedCheckedAllState);
      let checkedListTemp = [];
      let scheduleDeviceListTemp = [];
      carList.map((item, index) => {
        let obj = {};
        checkedListTemp.push(obj);
        setcheckedList(checkedListTemp);
        let lis = [
          item["registration_number"],
          item["model_name"],
          item["vin"],
        ];
        scheduleDeviceListTemp.push(lis);
      });
      setcheckedList(checkedListTemp);
      setScheduleDeviceList(scheduleDeviceListTemp);
    }
    setCheckedStateAll(!checkedStateAll);
  };

  const handleOnChangeCheckbox = (
    position,
    devicename,
    registrationno,
    item
  ) => {
    const positionTemp = position;
    position = position + (pageApi - 1) * 8;
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    );
    updatedCheckedState.map((item, index) => {
      if ((index === position) & updatedCheckedState[position]) {
        let obj = {};
        let lis = [
          carList[positionTemp]["registration_number"],
          carList[positionTemp]["model_name"],
          carList[positionTemp]["vin"],
        ];

        if (checkedList.length < 15) {
          let checkedListTemp = checkedList;
          checkedListTemp.push(obj);
          setcheckedList(checkedListTemp);
          let scheduleDeviceListTemp = scheduleDeviceList;
          scheduleDeviceListTemp.push(lis);
          setScheduleDeviceList(scheduleDeviceListTemp);
          // }
        } else {
          alert("Select 15 or less items");
        }
      } else if (index === position) {
        console.log(checkedList);
        const filtercheckedList = checkedList.filter(function (item) {
          console.log(Object.keys(item)[0]);
          if (Object.keys(item)[0] != devicename) {
            return item;
          }
        });
        const filterScheduleDeviceList = scheduleDeviceList.filter(
          (item) => item[0] != registrationno
        );

        setcheckedList(filtercheckedList);
        setScheduleDeviceList(filterScheduleDeviceList);
      }
    });
    setCheckedState(updatedCheckedState);
    if (checkedList.length === carList.length) {
      setCheckedStateAll(true);
    } else {
      setCheckedStateAll(false);
    }
  };

  // Device Upgrade Status
  const [upgradeStatus, setUpgradeStatus] = useState({});

  useEffect(() => {
    var socket = new WebSocket(baseSocketUrl + "/fota/");
    // console.log(socket)
    socket.onopen = () => {
      console.log("connection success for upgrade status");
    };
    socket.onmessage = function (event) {
      setUpgradeStatus(JSON.parse(event.data));
      console.log(event.data);
    };
  }, []);

  useEffect(() => {
    if (carList.length > 0) {
      if (checkedList.length === carList.length) {
        setCheckedStateAll(true);
      } else {
        setCheckedStateAll(false);
      }
    }
  }, [checkedList]);

  const getScanHistory = async (vin) => {
    setHistoryLoading(true);
    try {
      const response = await axios.post(
        baseUrl + "/get/scanning_feature/",
        {
          vin,
        },
        {
          headers: { Authorization: `Bearer ${getJwtToken()}` },
        }
      );
      console.log("history data",response);
      setHistoryData(response.data);
    } catch (e) {
      console.log("getScanHistory Error", e);
    } finally {
      setHistoryLoading(false);
    }
  };
 

  const compareScanPLM = async (vin, carModel) => {
    setCompareLoading(true);
    try {
      const response = await axios.post(
        baseUrl + "/get/compareData/",
        {
          vin,
          carModel,
        },
        {
          headers: { Authorization: `Bearer ${getJwtToken()}` },
        }
      );
      setCompareData(response.data);
    } catch (e) {
      console.log("compareScanPLM Error", e);
      if (MOCK) {
        setCompareData({
          Gateway: {
            Gateway_Name: ["s32g274ardb2", "s32g274ardb2", true, 0],

            Part_Number: ["b2:c5:d2:c3:54:4f", "ee:1b:7e:70:ca:9f", false, 1],

            Software_Version: [
              "5.10.145-rt74+g1594b25154a2",

              "1.10.12",

              false,

              2,
            ],

            Firmware_Version: ["1.10.12", "1.0", false, 3],

            Vehicle_Vin: ["Linux", "Linux", true, 4],
          },

          ECU: [
            {
              ECU1: {
                Ecu_Name: ["tda4vm-sk", "tda4vm-sk", true, 5],

                Part_Number: [
                  "24:76:25:a4:a5:78",

                  "24:76:25:a4:a5:78",

                  true,

                  6,
                ],

                Os_Version: [
                  "5.10.153-g90c3a58fd2",

                  "5.10.162-g76b3e88d56",

                  false,

                  7,
                ],

                Firmware_Version: ["1.10.26", "1.0", false, 8],

                Vehicle_Vin: ["Linux", "Linux", true, 9],
              },
            },

            {
              "Missing ECU 1": {
                Ecu_Name: ["imx8qxpc0mek"],

                Part_Number: ["00:04:9f:07:98:17"],

                Os_Version: ["5.15.52+g36363d8623ba"],

                Firmware_Version: ["1.0"],

                Vehicle_Vin: ["Linux"],
              },
            },

            {
              "Missing ECU 2": {
                Ecu_Name: ["IMX8Q"],

                Part_Number: ["00:04:9F:07:98:DF"],

                Os_Version: ["32"],

                Firmware_Version: ["1.0"],

                Vehicle_Vin: ["Android"],
              },
            },
          ],

          Peripherals: [
            {
              Peripheral1: {
                Peripheral_Name: ["8142", "8140", false],

                Vendor_Id: ["0451", "0451", true],

                Device_Type: ["usb_device", "usb_device", true],

                Subsystem: ["usb", "usb", true],
              },
            },

            {
              Peripheral2: {
                Peripheral_Name: [
                  "xHCI_Host_Controller",

                  "xHCI_Host_Controller",

                  true,
                ],

                Vendor_Id: ["1d6b", "1d6b", true],

                Device_Type: ["usb_device", "usb_device", true],

                Subsystem: ["usb", "usb", true],
              },
            },

            {
              "Missing Peripheral 1": {
                Peripheral_Name: ["Webcam_C170"],

                Vendor_Id: ["046d"],

                Device_Type: [null],

                Subsystem: ["video4linux"],
              },
            },

            {
              "Missing Peripheral 2": {
                Peripheral_Name: ["Plantronics_Blackwire_3220_Series"],

                Vendor_Id: ["047f"],

                Device_Type: [null],

                Subsystem: ["input"],
              },
            },

            {
              "Missing Peripheral 3": {
                Peripheral_Name: ["Mass_Storage_Device"],

                Vendor_Id: ["058f"],

                Device_Type: ["usb_device"],

                Subsystem: ["usb"],
              },
            },

            {
              "Missing Peripheral 4": {
                Peripheral_Name: ["USB_10_100_1000_LAN"],

                Vendor_Id: ["0bda"],

                Device_Type: [null],

                Subsystem: ["net"],
              },
            },

            {
              "Missing Peripheral 5": {
                Peripheral_Name: ["Microphone"],

                Vendor_Id: ["11"],

                Device_Type: ["15"],

                Subsystem: ["UNKNOWN_SUBSYSTEM"],
              },
            },

            {
              "Missing Peripheral 6": {
                Peripheral_Name: ["USB-C_PD3.0_Device"],

                Vendor_Id: ["2109"],

                Device_Type: ["usb_device"],

                Subsystem: ["usb"],
              },
            },

            {
              "Missing Peripheral 7": {
                Peripheral_Name: ["Ethernet"],

                Vendor_Id: ["432902426637"],

                Device_Type: ["UNKNOWN_DEVTYPE"],

                Subsystem: ["UNKNOWN_SUBSYSTEM"],
              },
            },

            {
              "Missing Peripheral 8": {
                Peripheral_Name: ["Display"],

                Vendor_Id: ["NXP"],

                Device_Type: ["UNKNOWN_DEVTYPE"],

                Subsystem: ["UNKNOWN_SUBSYSTEM"],
              },
            },
          ],
        });
      }
    } finally {
      setCompareLoading(false);
    }
  };

  const getVehicleCount = async (body) => {
    try {
      const response = await axios.post(
        baseUrl + "/get/vechilles_list/",
        {
          model_id: body.model_id ? body.model_id : null,
          country_id: body.country_id ? body.country_id : null,
          state_id: body.state_id ? body.state_id : null,
          city_id: body.city_id ? body.city_id : null,
          page_number: 1,
        },
        {
          headers: { Authorization: `Bearer ${getJwtToken()}` },
        }
      );
      setVehicleCount(response.data.length);
    } catch (e) {
      console.log("Error getVehicle Count", e);
    }
  };

  const updatePLMData = async () => {
    if (!selectedVin) {
      console.log("Invalid VIN");
      return;
    }
    setUpdatePLMLoading(true);
    try {
      const response = await axios.post(
        baseUrl + "/get/updatePlmDb/",
        {
          status: "Confirm",
          vin: selectedVin,
        },
        {
          headers: { Authorization: `Bearer ${getJwtToken()}` },
        }
      );
      handleToastOpen(response.data, "success");
    } catch (e) {
      console.log("UpdatePLMData error", e);
      handleToastOpen("Update PLM Data error", "error");
    } finally {
      setUpdatePLMLoading(false);
      toggleDialog();
      setSelectedVin(null);
      setTimeout(() => {
        setCompareData(null);
      }, 1000);
    }
  };

  const dataComparisionDialogContent = () => {
    if (Array.isArray(compareData)) {
      return compareData[0] === UPDATE_MESSAGE
        ? UPDATE_MESSAGE_TRIMMED
        : compareData[0];
    }
    return (
      <TableView
        scan={{
          GATEWAY: compareData?.Gateway,
          ECU: compareData?.ECU,
          PERIPHERAL: compareData?.Peripherals,
        }}
        isCompare
      />
    );
  };

  const dataComparisionDialogActionsEnabled = () => {
    if (!Array.isArray(compareData) && compareData?.Gateway) return true;
    if (Array.isArray(compareData) && compareData[0] === UPDATE_MESSAGE)
      return true;
    return false;
  };

  return (
    <div className="remote_device_page">
      {renderToast()}
      
        
      <div className="model_row">
        <div className="model_selector">
          <div className="filter_selector">
            <div>Model: </div>
            <div>
              <FormControl sx={{ minWidth: 199, minHeight: 36 }}>
                <Select
                  value={model}
                  onChange={handleModel}
                  displayEmpty={true}
                  style={{
                    height: 36,
                    fontFamily: "Poppins",
                    background: "white",
                    borderRadius: "0",
                  }}
                >
                  <MenuItem
                    value=""
                    onClick={() => {
                      setModel(null);
                    }}
                  >
                
                    All Models
                  </MenuItem>
                  {modelList.map((item) => (
                    <MenuItem key={item.model_id} value={item.model_id}>
                      {item.model_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
        </div>
        <div className="remote_device_buttons">
          {/* <NotifyJiraButton
          totalSelected={checkedList.length}
          /> */}
          <ScanButton
            isDisable={false}
            totalSelected={checkedList.length}
            allDeviceNameList={scheduleDeviceList}
            mode={"scan"}
            triggerApi={toggleTriggerApi}
          />
        </div>
      </div>
      <div>
        {!isLoaded ? (
          <div className="list_panel list_panel_style_scan">
            <table className="remote_device_table">
              <tr className="remote_device_table_header">
                <th style={{}}>
                  <input
                    type="checkbox"
                    style={{ width: "17px", height: "17px" }}
                    checked={checkedStateAll}
                    onChange={() => handleOnChangeAllCheckbox()}
                  />
                </th>
                <th className="remote_device_table_header_row">REGISTRATION NO</th>
                <th className="remote_device_table_header_row">MODEL</th>
                <th className="remote_device_table_header_row">COMPARE</th>
                <th className="remote_device_table_header_row">SCAN HISTORY</th>
               
                <th className="remote_device_table_header_row">
                  LAST UPDATE ON
                </th>
                <th className="remote_device_table_header_row">SCAN STATUS</th>
              </tr>
            </table>

            <div
              style={{
                height: "500px",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress color="primary" />
            </div>
          </div>
        ) : (
          <div className="list_panel list_panel_style_scan">
            <table className="remote_device_table">
              <tr className="remote_device_table_header">
                <th style={{}}>
                  <input
                    type="checkbox"
                    style={{ width: "17px", height: "17px" }}
                    checked={checkedStateAll}
                    onChange={() => handleOnChangeAllCheckbox()}
                  />
                </th>
                <th className="remote_device_table_header_row">
                  REGISTRATION NO
                </th>
                <th className="remote_device_table_header_row">MODEL</th>
                <th className="remote_device_table_header_row">COMPARE</th>
                <th className="remote_device_table_header_row">SCAN HISTORY</th>
              
                <th className="remote_device_table_header_row">
                  LAST UPDATE ON
                </th>
                <th className="remote_device_table_header_row">SCAN STATUS</th>
              </tr>
              {deviceUpgradeStats.total_vehicles === 0 ? (
                <>
                  <td colSpan={7}>
                    <div className="empty_table_text">
                      No Available vehicles...
                    </div>
                  </td>
                </>
              ) : (
                carList.map((item, index) => (
                  <tr
                    className="remote_device_table_row"
                    key={index}
                    style={{
                      background: checkedState[index + (pageApi - 1) * 8]
                        ? "#F0F8FF"
                        : "",
                    }}
                  >
                    <td>
                      <input
                        style={{ width: "17px", height: "17px" }}
                        type="checkbox"
                        id={`custom-checkbox-${index + (pageApi - 1) * 8}`}
                        // name={name}
                        // value={name}
                        checked={checkedState[index + (pageApi - 1) * 8]}
                        onChange={() =>
                          handleOnChangeCheckbox(
                            index,
                            item["devicename"],
                            item["registration_number"],
                            item
                          )
                        }
                      />
                    </td>
                    <td className="row_text_style">
                      {item["registration_number"]}
                    </td>
                    <td className="row_text_style">{item["model_name"]}</td>
                    <td
                      className="row_text_style_icon"
                      onClick={() => {
                        compareScanPLM(item["vin"], item["model_name"]);
                        setSelectedVin(item["vin"]);
                        toggleDialog();
                      }}
                    >
                      <CompareIcon />
                    </td>
                    <td
                      className="row_text_style_icon"
                      onClick={() => {
                        getScanHistory(item["vin"]);
                        toggleHistoryDialog();
                      }}
                    >
                      <HistoryIcon />
                    </td>
                   
                    <td className="row_text_style">
                      {item["last_updated"]
                        ? moment(item["last_updated"]).format("Do MMM YYYY")
                        : "-"}
                    </td>
                    {item["scan_status"] === "INITIATED" ? (
                      <td className="upgrade_status_row">
                        <div className="needs_upgrade scan_status">
                          <div>{item["scan_status"]}</div>
                        </div>
                      </td>
                    ) : (
                      <td style={{ color: "#62AD41" }}>
                        <div className="upgrade_status_row">
                          <div className="latest_version scan_status">
                            <div>{item["scan_status"]}</div>
                          </div>
                        </div>
          
                    </td>
                    )}
                  </tr>
                ))
              )}
            </table>
            <div style={{ margin: "10px" }}>
              <Pagination
                count={parseInt(vehicleCount / 8) + 1}
                page={pageApi}
                shape="rounded"
                onChange={(e, value) => setPageApi(value)}
              />
            </div>
          </div>
        )}
      </div>
      <Dialog
        dialogOpen={dialogOpen}
        toggleDialog={() => {
          toggleDialog();
          setSelectedVin(null);
          setTimeout(() => {
            setCompareData(null);
          }, 1000);
        }}
        title={"Data Comparision"}
        content={dataComparisionDialogContent()}
        actionsEnabled={dataComparisionDialogActionsEnabled()}
        onSuccess={updatePLMData}
        loading={compareLoading || updatePLMLoading}
        small={
          compareData?.[0] === "Invalid Vin" ||
          compareData?.[0] === "Car model not available in plm"
        }
      />
      <Dialog
        dialogOpen={historyDialogOpen}
        toggleDialog={() => {
          // Reset history data on close of Dialog
          setHistoryData([]);
          toggleHistoryDialog();
        }}
        title={"Vehicle Scan History"}
        
        content={<Accordion data={historyData} />}
        loading={historyLoading}
      />
      
    </div>
  );
}

export default VehicleScan;
