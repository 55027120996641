import React, { useEffect, useRef, useState } from "react";
import "./index.css";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import axios from "axios";
import { useScrollTrigger } from "@mui/material";
import { useLocation } from "react-router-dom";
import Loader from "react-js-loader";
import { baseSocketUrl, baseUrl } from "../../../constants";

const Prediction = ({ vehicleData }) => {
  const pathname = useLocation().pathname.split("/");
  const left = useRef(null);
  const right = useRef(null);
  const [vinNumber, setVinNumber] = useState(null);
  const [vehicleno, setVehicleNo] = useState("TN");
  const [carDetails, setCarDetails] = useState(null);

  const [engineSensorData, setEngineSensorData] = useState(null);
  const [rul, setRUL] = useState(0);
  const [accel1, setaccel1] = useState(40);
  const [accel2, setaccel2] = useState(20);
  const [accel3, setaccel3] = useState(30);
  const [accel4, setaccel4] = useState(30);
  const [battery_percentage, setbattery_percentage] = useState(10);
  const [engine_vibration_amplitude, setengine_vibration_amplitude] =
    useState(0);
  const [intake_air_flow_speed, setintake_air_flow_speed] = useState(30);
  const [intake_air_temp, setintake_air_temp] = useState(20);
  const [throttle_pos, setthrottle_pos] = useState(2);
  const [speed, setspeed] = useState(0);
  const [coolant_temp, setcoolant_temp] = useState(60);
  const [battery_voltage, setbattery_voltage] = useState(20);
  const [tire_pressure_1, settire_pressure_1] = useState(50);
  const [tire_pressure_2, settire_pressure_2] = useState(50);
  const [tire_pressure_3, settire_pressure_3] = useState(50);
  const [tire_pressure_4, settire_pressure_4] = useState(50);

  const [color, setColor] = useState("#98B642");
  const [StrokeColor, setStrokeColor] = useState("#98B642");

  const scroll = (scrollOffset) => {
    left.current.scrollLeft += scrollOffset;
  };

  const url =
    "http://Telematics-ML-Model-1151476303.us-east-1.elb.amazonaws.com/predict";
  const engineSensorUrl = baseUrl + "/get/sensorData/";
  const url2 = baseUrl + "/basicDetails/basic/";
  const user = {};

  const viin = { vin: pathname.at(4) };

  const getJwtToken = () => {
    let token = localStorage.getItem("token") || undefined;
    return token === undefined ? "" : `${JSON.parse(token)}`;
  };

  useEffect(() => {
    // setVehicleNo(localStorage.getItem('registrationno'));
    if (vehicleData.bolll == 0) setVinNumber(vehicleData.vin);
    if (vehicleData.registeration_no)
      setVehicleNo(vehicleData.registeration_no);
  }, [vehicleData]);

  useEffect(() => {
    console.log(viin);
    axios.post(url, viin).then((res) => {
      setRUL(Math.floor(res.data / 7));
      // var vinTemp =  pathname.at(4)
      // var rulArr = res.data;
      // var finalRul = ""
      // if(rulArr!== undefined){
      //     rulArr.map((item,index)=>{
      //         console.log(item["vin"])
      //         if(item["vin"]===vinTemp){
      //             finalRul = item["rul"]
      //         }
      //     })
      //     var rulResult = Math.floor(finalRul)
      //
      // }
      // Object.keys(res.data).map((item,index)=>{
      //     console.log(item)
      // })
      // console.log(rulDate.format('YYYY-MM-DD') + " luuuss " +todayDate.format('YYYY-MM-DD'))
    });
    // axios.get(url).then((res) => {
    //     setRUL(Math.floor(res.data/7));
    //     console.log(rul)
    // })

    axios
      .post(engineSensorUrl, viin, {
        headers: { Authorization: `Bearer ${getJwtToken()}` },
      })
      .then((res) => {
        const temp = res.dat;
        if (
          temp !== undefined &&
          temp !== null &&
          res !== undefined &&
          temp.message !== "Unable to find vin" &&
          res !== "Unable to find vin"
        ) {
          if (temp.accelerometer_1_1_value !== null)
            setaccel1(temp.accelerometer_1_1_value);
          if (temp.accelerometer_1_2_value !== null)
            setaccel2(temp.accelerometer_1_2_value);
          if (temp.accelerometer_2_1_value !== null)
            setaccel3(temp.accelerometer_2_1_value);
          if (temp.accelerometer_2_2_value !== null)
            setaccel4(temp.accelerometer_2_2_value);
          if (temp.battery_percentage !== null)
            setbattery_percentage(temp.battery_percentage);
          if (temp.battery_voltage !== null)
            setbattery_voltage(temp.battery_voltage);
          if (temp.coolant_temp !== null) setcoolant_temp(temp.coolant_temp);
          if (temp.engine_vibration_amplitude !== null)
            setengine_vibration_amplitude(temp.engine_vibration_amplitude);
          if (temp.intake_air_flow_speed !== null)
            setintake_air_flow_speed(temp.intake_air_flow_speed);
          if (temp.intake_air_temp !== null)
            setintake_air_temp(temp.intake_air_temp);
          if (temp.speed !== null) setspeed(temp.speed);
          if (temp.throttle_pos !== null) setthrottle_pos(temp.throttle_pos);
          if (temp.tire_pressure_1_1 !== null)
            settire_pressure_1(temp.tire_pressure_1_1);
          if (temp.tire_pressure_1_2 !== null)
            settire_pressure_2(temp.tire_pressure_1_2);
          if (temp.tire_pressure_2_1 !== null)
            settire_pressure_3(temp.tire_pressure_2_1);
          if (temp.tire_pressure_2_2 !== null)
            settire_pressure_4(temp.tire_pressure_2_2);
        }
      });
  }, []);

  useEffect(() => {
    var socket = new WebSocket(baseSocketUrl + "/ml/");

    socket.onopen = () => {
      // console.log("Sensor Data connection success")
      socket.send(JSON.stringify(viin));
    };
    socket.onmessage = function (event) {
      const engSenorData = JSON.parse(event.data);
      // console.log(engSenorData)
      if (engSenorData !== "Connection Successful. Send vin")
        setEngineSensorData(engSenorData);
    };
  }, []);

  useEffect(() => {
    const temp = engineSensorData;
    if (temp !== null) {
      setaccel1(temp.accelerometer_1_1_value);
      setaccel2(temp.accelerometer_1_2_value);
      setaccel3(temp.accelerometer_2_1_value);
      setaccel4(temp.accelerometer_2_2_value);
      setbattery_percentage(temp.battery_percentage);
      setbattery_voltage(temp.battery_voltage);
      setcoolant_temp(temp.coolant_temp);
      setengine_vibration_amplitude(temp.engine_vibration_amplitude);
      setintake_air_flow_speed(temp.intake_air_flow_speed);
      setintake_air_temp(temp.intake_air_temp);
      setspeed(temp.speed);
      setthrottle_pos(temp.throttle_pos);
      settire_pressure_1(temp.tire_pressure_1_1);
      settire_pressure_2(temp.tire_pressure_1_2);
      settire_pressure_3(temp.tire_pressure_2_1);
      settire_pressure_4(temp.tire_pressure_2_2);
    }
  }, [engineSensorData]);

  useEffect(() => {
    if (rul > 12) {
      setStrokeColor("#98B642");
      setColor("#98B642");
    } else if (rul > 4) {
      setStrokeColor("#FFD44A");
      setColor("#FFD44A");
    } else {
      setStrokeColor("#FF7777");
      setColor("#FF7777");
    }
  }, [rul]);

  return (
    <div className="prediction_topbar">
      <div className="engine_life">
        <div className="engine_life_head">Engine Remaining Useful Life</div>
        {/* <div className='engine_recall_no'>Reg no. {vehicleno} has been marked for recall.</div> */}
        <div className="engine_life_weeks">
          {rul === 0 ? (
            <div style={{ marginTop: "0vh" }}>
              <Loader
                type="spinner-default"
                bgColor={"grey"}
                color={"grey"}
                size={100}
              />
            </div>
          ) : (
            <CircularProgressbar
              value={40}
              minValue={0}
              maxValue={60}
              text={
                <tspan>
                  {/* <tspan x="0" dy=".6em">tspan line 1</tspan> */}
                  <tspan x="50" dy="-14">
                    {rul}
                  </tspan>
                  <tspan x="50" dy="25">
                    {" "}
                    Weeks
                  </tspan>
                </tspan>
              }
              // background={true}
              styles={{
                // Customize the root svg element
                root: {},
                // Customize the path, i.e. the "completed progress"
                path: {
                  // Path color
                  stroke: `${StrokeColor}`,
                  // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                  strokeLinecap: "butt",
                  // Customize transition animation
                  transition: "stroke-dashoffset 0.5s ease 0s",
                  // Rotate the path
                  transform: "rotate(0.25turn)",
                  transformOrigin: "center center",
                  strokeWidth: 5,
                },
                // Customize the circle behind the path, i.e. the "total progress"
                trail: {
                  // Trail color
                  stroke: "rgba(170, 203, 108, 0.33)",
                  // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                  strokeLinecap: "butt",
                  opacity: 1,
                  // Rotate the trail
                  transform: "rotate(0.25turn)",
                  transformOrigin: "center center",
                  strokeWidth: 5,
                },
                // Customize the text
                text: {
                  // Text color
                  fill: `${color}`,
                  // Text size
                  fontSize: "2vh",
                  fontWeight: "600",
                },
              }}
            />
          )}
        </div>
      </div>

      <div className="live_data">
        <div className="live_data_header">
          <div className="live_sensor_title">Engine Sensor</div>
          <div className="scroll_arrows">
            <div className="left_scroll_arrow" onClick={() => scroll(-40)}>
              <img src="https://img.icons8.com/external-dreamstale-lineal-dreamstale/10/null/external-left-arrow-arrows-dreamstale-lineal-dreamstale.png" />
            </div>
            <div className="right_scroll_arrow" onClick={() => scroll(40)}>
              <img src="https://img.icons8.com/external-dreamstale-lineal-dreamstale/10/null/external-right-arrow-arrows-dreamstale-lineal-dreamstale-2.png" />
            </div>
          </div>
        </div>
        <div className="live_sensor_data">
          <div class="engine_container" ref={left}>
            <div className="card">
              <div className="card_header">Intake Air Temperature</div>
              <div className="card_detail intake_air_temp">
                {intake_air_temp.toFixed(1)}
              </div>
              <div className="card_unit iarc">Celsius</div>
            </div>
            <div className="card">
              <div className="card_header">Coolant Temperature </div>
              <div
                className="card_detail intake_air_temp"
                style={{ marginTop: "4vh" }}
              >
                {coolant_temp.toFixed(1)}
              </div>
              <div className="card_unit iarc">Celsius</div>
            </div>
            <div className="card3">
              <div className="card_header">Intake Air Flow Speed</div>
              <div
                className="card_detail air_flow_speed"
                style={{ marginTop: "4vh" }}
              >
                {intake_air_flow_speed.toFixed(1)}
              </div>
              <div className="card_unit afsu">g/s</div>
            </div>
            <div className="card">
              <div className="card_header">Throttle Position</div>
              <div
                className="card_detail throttle_pos"
                style={{ marginTop: "4vh" }}
              >
                {throttle_pos.toFixed(2)}
              </div>
              <div className="card_unit tpu">int</div>
            </div>
            <div className="card">
              <div className="card_header flcah">
                Front Left Chassis Accelerometer
              </div>
              <div className="card_detail accelerometer_chasis">
                {accel1.toFixed(1)}
              </div>
              <div className="card_unit fcu">
                m/s<sup>2</sup>
              </div>
            </div>
            <div className="card">
              <div className="card_header">
                Front Right Chassis Accelerometer
              </div>
              <div className="card_detail accelerometer_chasis">
                {accel2.toFixed(1)}
              </div>
              <div className="card_unit fcu">
                m/s<sup>2</sup>
              </div>
            </div>
            <div className="card">
              <div className="card_header">Back Left Chassis Accelerometer</div>
              <div className="card_detail accelerometer_chasis">
                {accel3.toFixed(1)}
              </div>
              <div className="card_unit fcu">
                m/s<sup>2</sup>
              </div>
            </div>
            <div className="card">
              <div className="card_header">
                Back Right Chassis Accelerometer
              </div>
              <div className="card_detail accelerometer_chasis">
                {accel4.toFixed(1)}
              </div>
              <div className="card_unit fcu">
                m/s<sup>2</sup>
              </div>
            </div>
            <div className="card">
              <div className="card_header">Front Left Tire Pressure</div>
              <div className="card_detail tire_pressure">
                {tire_pressure_1.toFixed(1)}
              </div>
              <div className="card_unit tpu">psi</div>
            </div>
            <div className="card">
              <div className="card_header">Front Right Tire Pressure</div>
              <div className="card_detail tire_pressure">
                {tire_pressure_2.toFixed(1)}
              </div>
              <div className="card_unit tpu">psi</div>
            </div>
            <div className="card">
              <div className="card_header">Back Left Tire Pressure</div>
              <div className="card_detail tire_pressure">
                {tire_pressure_3.toFixed(1)}
              </div>
              <div className="card_unit tpu">psi</div>
            </div>
            <div className="card">
              <div className="card_header">Back Right Tire Pressure</div>
              <div className="card_detail tire_pressure">
                {tire_pressure_4.toFixed(1)}
              </div>
              <div className="card_unit tpu">psi</div>
            </div>
            <div className="card">
              <div className="card_header">Battery Percentage</div>
              <div
                className="card_detail battery_percentage_show"
                style={{ marginTop: "4vh" }}
              >
                {battery_percentage.toFixed(1)}
              </div>
              <div className="card_unit bpu">%</div>
            </div>
            <div className="card">
              <div className="card_header">Battery</div>
              <div
                className="card_detail battery_volts_unit"
                style={{ marginTop: "4vh" }}
              >
                {battery_voltage.toFixed(1)}
              </div>
              <div className="card_unit">Volts</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Prediction;
