import "./index.css";
import Chart from "react-apexcharts";
import Surge from "./Surge";
import { useEffect, useState } from "react";
import axios from "axios";
import { DashboardFleetData } from "../Headers/DashboardFleetData";
import { Operations } from "./Operations";
import { baseSocketUrl } from "../../constants";
import { baseUrl } from "../../constants";

var ccid = null,
  ssid = null,
  ccitid = null,
  ccmodel = null;
const Home = ({ navSelected, val, dateFilter }) => {
  // console.log(val)
  const [totalVehicle, setTotalVehicle] = useState(0);
  const [onlineVehicle, setOnlineVehicle] = useState(0);
  const [offlineVehicle, setOfflineVehicle] = useState(0);
  const [vehicleAttention, setVehicleAttention] = useState(0);
  const [OnTrip, setOnTripSeries] = useState([1, 3, 4, 2, 3, 4]);
  const [avilableSeries, setAvilableSeries] = useState([4, 2, 1, 3, 2, 4]);
  const [oprtnLabel, setoprtnLabel] = useState(["0", "0", "10", "d", "e", "f"]);
  const [softwareSeries, setSoftwareSeries] = useState([0, 0]);
  const [softwareLabel, setSoftwareLabel] = useState(["0", "0"]);
  const [healthSeries, setHealthSeries] = useState([5, 2, 1]);
  const [healthLabel, setHealthLabel] = useState([85, 10, 5]);
  const [avilableTrip, setAvilableTrip] = useState(0);
  const [needUpdate, setNeedUpdate] = useState(0);
  const [serviceDue, setServiceDue] = useState(0);
  const [surgeData, setSurgeData] = useState([1, 2, 10, 20, 5, 6, 7, 8, 9, 2]);
  const [surgeLabel, setSurgeLabel] = useState([
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "j",
  ]);
  const [countryid, setCountryid] = useState(null);

  const [exceedLabel, setExceedLabel] = useState(["a", "b", "c", "d", "e"]);
  const [speedExceed, setSpeedExceed] = useState([10, 12, 50, 40, 66]);
  const [rpmExceed, setRPMExceed] = useState([4, 15, 12, 20, 50]);
  const [tempExceed, setTempExceed] = useState([20, 6, 10, 6, 34]);
  const [batteryExceed, setBatteryExceed] = useState([5, 7, 40, 34, 12]);
  var intervalHandler;

  const [socketHomeData, setSocketHomeData] = useState(null);
  const [socketHomeDeviceData, setSocketHomeDeviceData] = useState(null);
  const [socketServiceTimeData, setSocketServiceTimeData] = useState(null);
  const [scoketThresholdData, setSocketThresholdData] = useState(null);

  const getJwtToken = () => {
    let token = localStorage.getItem("token") || undefined;
    return token === undefined ? "" : `${JSON.parse(token)}`;
  };

  const [basicData, setBasicData] = useState(null);

  const url = baseUrl + "/basicDetails/vehiclehistory/data/";

  // useEffect(() => {
  //   var cid = null;
  //   var sid = null;
  //   var citid = null;
  //   var cmodel = null;
  //   var historicDate = null;
  //   var currentDate = Date()

  //   if(val===''||val===undefined){
  //     cmodel=null
  //     ccmodel=null
  //   }else{
  //     cmodel=val
  //     ccmodel=cmodel
  //   }

  //   if(navSelected !== undefined && navSelected !== null) {
  //     // console.log(navSelected)
  //     if(navSelected.type === 'country') {
  //       cid = !navSelected.show ? navSelected.id : null;
  //     }
  //     else if(navSelected.type == 'state') {
  //       if(!navSelected.show) {
  //         cid = navSelected.prevcountryid;
  //         sid = navSelected.id;
  //       }
  //       else {
  //         cid = navSelected.prevcountryid;
  //       }
  //     }
  //     else {
  //       if(!navSelected.show) {
  //         cid = navSelected.prevcountryid;
  //         sid = navSelected.prevstateid;
  //         citid = navSelected.id;
  //       }
  //       else {
  //         cid = navSelected.prevcountryid;
  //         sid = navSelected.prevstateid;
  //       }
  //     }

  //     ccid = cid;
  //     ssid = sid;
  //     ccitid = citid;

  //     const param = {

  //       "country_id" : cid,
  //       "state _id" : sid,
  //       "city_id" : citid,
  //       "current_datetime" : currentDate,
  //       "historical_datetime": null,
  //       "vehiclemodel": cmodel,
  //     }

  //     clearInterval(intervalHandler)
  //     setTimeout(() => {intervalHandler = setInterval(() => {
  //       const getData = async() => {
  //         await axios.post(url, param,{ headers: {Authorization : `Bearer ${getJwtToken()}`} }).then(result => {
  //           if(cid === ccid && sid == ssid && ccitid === citid && cmodel === ccmodel)  setBasicData(result.data)
  //         })
  //       }
  //       getData();
  //     }, 3600000);},3600000)
  //     clearInterval(intervalHandler)

  //   }
  //   else {
  //     const param = {

  //       "country_id" : null,
  //       "state _id" : null,
  //       "city_id" : null,
  //       "current_datetime" : currentDate,
  //       "vehiclemodel": cmodel,
  //       "historical_datetime": null,
  //     }

  //     clearInterval(intervalHandler)
  //     setTimeout(() => {intervalHandler = setInterval(() => {
  //       const getData = async() => {
  //         await axios.post(url, param,{ headers: {Authorization : `Bearer ${getJwtToken()}`} }).then(result => {
  //           if(cid === ccid && sid == ssid && ccitid === citid && cmodel === ccmodel)setBasicData(result.data)
  //         })
  //       }
  //       getData();
  //     }, 3600000);},3600000)
  //     clearInterval(intervalHandler)
  //   }

  // }, [navSelected, val])

  //api call to get static data
  useEffect(() => {
    // console.log(navSelected)
    // console.log(dateFilter)
    var cid = null;
    var sid = null;
    var citid = null;
    var cmodel = null;
    var historicDate = null;
    var currentDate = Date();

    if (navSelected !== undefined && navSelected !== null) {
      // console.log(navSelected)
      if (navSelected.type === "country") {
        cid = !navSelected.show ? navSelected.id : null;
      } else if (navSelected.type == "state") {
        if (!navSelected.show) {
          cid = navSelected.prevcountryid;
          sid = navSelected.id;
        } else {
          cid = navSelected.prevcountryid;
        }
      } else {
        if (!navSelected.show) {
          cid = navSelected.prevcountryid;
          sid = navSelected.prevstateid;
          citid = navSelected.id;
        } else {
          cid = navSelected.prevcountryid;
          sid = navSelected.prevstateid;
        }
      }

      // ------------------------------------------------- Api code for fetching data ---------------------------------------------------------

      const param = {
        country_id: cid,
        state_id: sid,
        city_id: citid,
        current_datetime: currentDate,
        historical_datetime: null,
        vehiclemodel: null,
      };

      const getData = async () => {
        await axios
          .post(url, param, {
            headers: { Authorization: `Bearer ${getJwtToken()}` },
          })
          .then((result) => {
            // console.log(result);

            setBasicData(result.data);
          });
      };
      getData();

      // ------------------------------------------------- Socket code for fetching data ---------------------------------------------------------

      //give vehiclemodel here for socket req
      ccid = cid;
      ssid = sid;
      ccitid = citid;

      const paramSocket = {
        country_id: cid,
        state_id: sid,
        city_id: citid,
        vehiclemodel: null,
      };

      const paramSocket2 = {
        country_id: cid,
        state_id: sid,
        city_id: citid,
      };

      const getDatafromSocket = () => {
        var socket = new WebSocket(baseSocketUrl + "/upgrade/");
        // console.log(socket)
        socket.onopen = () => {
          console.log("connection success");
          socket.send(JSON.stringify(paramSocket));
        };
        socket.onmessage = function (event) {
          // console.log(event.data)
          const distData = JSON.parse(event.data);
          // console.log(distData)
          if (ccid === cid && ssid === sid && ccitid === citid)
            setSocketHomeData(distData);
        };
      };

      const getDatafromSocket3 = () => {
        var socket = new WebSocket(baseSocketUrl + "/service_idle_graph/");
        // console.log(socket)
        socket.onopen = () => {
          console.log("connection success");
          socket.send(JSON.stringify(paramSocket2));
        };
        socket.onmessage = function (event) {
          // console.log(event.data)
          const distData = JSON.parse(event.data);
          // console.log(distData)
          if (ccid === cid && ssid === sid && ccitid === citid)
            setSocketServiceTimeData(distData);
        };
      };

      getDatafromSocket3();

      const getDatafromSocket4 = () => {
        var socket = new WebSocket(baseSocketUrl + "/threshold_graph/");
        // console.log(socket)
        socket.onopen = () => {
          console.log("connection success");
          socket.send(JSON.stringify(paramSocket2));
        };
        socket.onmessage = function (event) {
          // console.log(event.data)
          const distData = JSON.parse(event.data);
          // console.log(distData)
          if (ccid === cid && ssid === sid && ccitid === citid)
            setSocketThresholdData(distData);
        };
      };

      getDatafromSocket4();

      getDatafromSocket();

      const getDatafromSocket2 = () => {
        var socket = new WebSocket(baseSocketUrl + "/devicestate/");
        // console.log(socket)
        socket.onopen = () => {
          console.log("connection success");
          socket.send(JSON.stringify(paramSocket));
        };
        socket.onmessage = function (event) {
          // console.log(event.data)
          const distData = JSON.parse(event.data);
          // console.log(distData)
          if (ccid === cid && ssid === sid && ccitid === citid)
            setSocketHomeDeviceData(distData);
        };
      };

      getDatafromSocket2();
    } else {
      // ------------------------------------------------- Api code for fetching data ---------------------------------------------------------
      const param = {
        country_id: cid,
        state_id: sid,
        city_id: citid,
        current_datetime: currentDate,
        vehiclemodel: null,
        historical_datetime: null,
      };

      const getData = async () => {
        await axios
          .post(url, param, {
            headers: { Authorization: `Bearer ${getJwtToken()}` },
          })
          .then((result) => {
            // console.log(result);

            setBasicData(result.data);
          });
      };

      getData();

      //   // ------------------------------------------------- Socket code for fetching data ---------------------------------------------------------

      //   //give vehiclemodel here for socket if req
      const paramSocket = {
        country_id: cid,
        state_id: sid,
        city_id: citid,
        vehiclemodel: null,
      };

      const paramSocket2 = {
        country_id: cid,
        state_id: sid,
        city_id: citid,
      };

      const getDatafromSocket = () => {
        var socket = new WebSocket(baseSocketUrl + "/upgrade/");
        // console.log(socket)
        socket.onopen = () => {
          console.log("connection success");
          socket.send(JSON.stringify(paramSocket));
        };
        socket.onmessage = function (event) {
          // console.log(event.data)
          const distData = JSON.parse(event.data);
          // console.log(distData, cmodel)
          if (ccid === cid && ssid === sid && ccitid === citid)
            setSocketHomeData(distData);
        };
        // socket.close();
        // socket.onclose(() => {
        //   socket.close();
        // })
      };

      getDatafromSocket();

      const getDatafromSocket3 = () => {
        var socket = new WebSocket(baseSocketUrl + "/service_idle_graph/");
        // console.log(socket)
        socket.onopen = () => {
          console.log("connection success");
          socket.send(JSON.stringify(paramSocket2));
        };
        socket.onmessage = function (event) {
          // console.log(event.data)
          const distData = JSON.parse(event.data);
          // console.log(distData)
          if (ccid === cid && ssid === sid && ccitid === citid)
            setSocketServiceTimeData(distData);
        };
      };

      getDatafromSocket3();

      const getDatafromSocket4 = () => {
        var socket = new WebSocket(baseSocketUrl + "/threshold_graph/");
        // console.log(socket)
        socket.onopen = () => {
          console.log("connection success");
          socket.send(JSON.stringify(paramSocket2));
        };
        socket.onmessage = function (event) {
          // console.log(event.data)
          const distData = JSON.parse(event.data);
          // console.log(distData)
          if (ccid === cid && ssid === sid && ccitid === citid)
            setSocketThresholdData(distData);
        };
      };

      getDatafromSocket4();

      const getDatafromSocket2 = () => {
        var socket = new WebSocket(baseSocketUrl + "/devicestate/");
        // console.log(socket)
        socket.onopen = () => {
          console.log("connection success");
          socket.send(JSON.stringify(paramSocket));
        };
        socket.onmessage = function (event) {
          // console.log(event.data)
          const distData = JSON.parse(event.data);
          // console.log(distData)
          if (ccid === cid && ssid === sid && ccitid === citid)
            setSocketHomeDeviceData(distData);
        };
      };

      getDatafromSocket2();
    }
  }, [navSelected, val, dateFilter]);

  useEffect(() => {
    if (basicData !== null && basicData.result) {
      setTotalVehicle(basicData.result.total_vehicles);
      setOnlineVehicle(basicData.result.online_vehicles);
      setOfflineVehicle(
        basicData.result.total_vehicles - basicData.result.online_vehicles
      );
      setVehicleAttention(basicData.result.vehicles_need_attention);
      setOnTripSeries(basicData.result.operations.service_time);
      setoprtnLabel(basicData.result.operations.time);
      setAvilableSeries(basicData.result.operations.idle_time);
      setSoftwareSeries(basicData.result.software_version[0]);
      console.log(basicData.result.software_version[0]);
      setSoftwareLabel(basicData.result.software_version[1]);
      setHealthLabel(basicData.result.vehicle_health[1]);
      setHealthSeries(basicData.result.vehicle_health[0]);
      // setAvilableTrip(basicData.result.operations[0][1])
      setNeedUpdate(basicData.result.software_version[0][1]);
      setServiceDue(basicData.result.vehicle_health[0][2]);
      // setSurgeData(basicData.result.surge[0])
      // setSurgeLabel(basicData.result.surge[1])
      setSpeedExceed(basicData.result.notification.speed);
      setRPMExceed(basicData.result.notification.rpm);
      setTempExceed(basicData.result.notification.temperature);
      setBatteryExceed(basicData.result.notification.battery);
      setExceedLabel(basicData.result.notification.time);
    }
  }, [basicData]);

  useEffect(() => {
    if (socketHomeData !== null) {
      // const on_trip = socketHomeData.on_trip === undefined ? 0 : socketHomeData.on_trip;
      // const avilable = socketHomeData.available === undefined ? 0 : socketHomeData.available
      // var temparr = avilableSeries;
      // var temparr2 = OnTrip;
      // temparr[0] = avilable;
      // temparr2[0] = on_trip;
      // setAvilableSeries(temparr);
      // setOnTripSeries(temparr2)
      // setAvilableTrip(avilable)

      const needsupgrade =
        socketHomeData.needs_upgrade === undefined
          ? 0
          : socketHomeData.needs_upgrade;

      setNeedUpdate(needsupgrade);
      const rlv =
        socketHomeData.running_latest_version === undefined
          ? 0
          : socketHomeData.running_latest_version;

      // console.log(needsupgrade)

      // setOperationSeries([on_trip, avilable])
      // setoprtnLabel([(on_trip/(on_trip+avilable)*100).toFixed(1), (avilable/(on_trip+avilable)*100).toFixed(1)]);

      // setSoftwareSeries([rlv, needsupgrade]);
      // console.log(rlv, needsupgrade)
      // setSoftwareLabel([(rlv/(rlv+needsupgrade)*100).toFixed(1),(needsupgrade/(rlv+needsupgrade)*100).toFixed(1)])
    }
  }, [socketHomeData]);

  useEffect(() => {
    if (socketServiceTimeData !== null) {
      // console.log(socketServiceTimeData)
      const idt = socketServiceTimeData.idle_time;
      const sert = socketServiceTimeData.service_time;
      var temparr = avilableSeries;
      var temparr2 = OnTrip;
      temparr[temparr.length - 1] = idt;
      temparr2[temparr2.length - 1] = sert;
      setAvilableSeries(temparr);
      setOnTripSeries(temparr2);
    }
  }, [socketServiceTimeData]);

  useEffect(() => {
    if (scoketThresholdData !== null) {
      console.log(scoketThresholdData);
      var temparr = tempExceed;
      var temparr2 = speedExceed;
      var temparr3 = batteryExceed;
      var temparr4 = rpmExceed;
      temparr[temparr.length - 1] = scoketThresholdData.temperature;
      temparr2[temparr2.length - 1] = scoketThresholdData.speed;
      temparr3[temparr3.length - 1] = scoketThresholdData.battery;
      temparr4[temparr4.length - 1] = scoketThresholdData.rpm;
      setTempExceed(temparr);
      setSpeedExceed(temparr2);
      setBatteryExceed(temparr3);
      setRPMExceed(temparr4);
      // setAvilableSeries(temparr);
      // setOnTripSeries(temparr2)
    }
  }, [scoketThresholdData]);

  useEffect(() => {
    if (socketHomeDeviceData !== null) {
      setOnlineVehicle(socketHomeDeviceData.Online);
      setOfflineVehicle(socketHomeDeviceData.Offline);
    }
  }, [socketHomeDeviceData]);

  return (
    <div className="main_dashboard_component">
      <div style={{ marginLeft: "3%", marginRight: "3%" }}>
        <div className="main_dashboard_fleet_vehicle">
          <div className="fleet_vehicle_detail_card">
            <div className="fleet_vehicle_detail_head">Total Vehicles</div>
            <div className="fleet_vehicle_detail_value">{totalVehicle}</div>
          </div>
          <div style={{ marginRight: "3%" }}></div>
          <div className="fleet_vehicle_detail_card">
            <div className="fleet_vehicle_detail_head">Online Vehicles</div>
            <div className="fleet_vehicle_online_detail_value">
              {onlineVehicle}
            </div>
          </div>
          <div style={{ marginRight: "3%" }}></div>
          <div className="fleet_vehicle_detail_card">
            <div className="fleet_vehicle_detail_head">Offline Vehicles</div>
            <div className="fleet_vehicle_offline_detail_value">
              {offlineVehicle}
            </div>
          </div>
          <div style={{ marginRight: "3%" }}></div>
          <div className="fleet_vehicle_detail_card">
            <div className="fleet_vehicle_detail_head">
              Vehicles need attention
            </div>
            <div className="fleet_vehicle_attention_detail_value">
              {vehicleAttention}
            </div>
          </div>
        </div>

        <div className="main_dashboard_fleet_vehicle_lower">
          <div className="operations_card">
            <div className="operation_card_detail_header">
              <div className="operation_card_detail_header_title">
                Operations
              </div>
              <div className="information_icon">
                <svg
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_369_17468)">
                    <path
                      d="M8 14.5C11.3137 14.5 14 11.8137 14 8.5C14 5.18629 11.3137 2.5 8 2.5C4.68629 2.5 2 5.18629 2 8.5C2 11.8137 4.68629 14.5 8 14.5Z"
                      stroke="#4A4D57"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M7.5 8H8V11.5H8.5"
                      stroke="#4A4D57"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M7.875 6.5C8.28921 6.5 8.625 6.16421 8.625 5.75C8.625 5.33579 8.28921 5 7.875 5C7.46079 5 7.125 5.33579 7.125 5.75C7.125 6.16421 7.46079 6.5 7.875 6.5Z"
                      fill="#4A4D57"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_369_17468">
                      <rect
                        width="16"
                        height="16"
                        fill="white"
                        transform="translate(0 0.5)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </div>

              {/* <div className='live_dot_card'> </div>
                <div className='live_status_text'>Live</div> */}
            </div>

            <div className="donut_chart_data">
              <Operations
                OnTrip={OnTrip}
                avilableSeries={avilableSeries}
                oprtnLabel={oprtnLabel}
              />
              {/* <div className='donut_chart_view'>
                
                </div>
                <div className='donut_chart_info_avilable'>
                  <div className='avilable_trip_head'>Available for trip</div>
                  <div className='avilable_trip_value'>{avilableTrip}</div>
                </div> */}
            </div>

            <div className="operation_card_detail_footer">
              <div className="on_trip_operation_color_details">
                <div className="on_trip_light_blue_dot"></div>
                <div>Average Service Time</div>
              </div>

              <div className="on_trip_operation_color_details">
                <div className="avilable_blue_dot"></div>
                <div>Average Idle Time</div>
              </div>
            </div>
          </div>

          <div style={{ marginRight: "3%" }}></div>
          {/*------------------------------------ Need to update -------------------------------------------------------- */}
          <div className="operations_card">
            <div className="operation_card_detail_header">
              <div className="operation_card_detail_header_title">
                Software versions
              </div>
              <div className="information_icon">
                <svg
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_369_17468)">
                    <path
                      d="M8 14.5C11.3137 14.5 14 11.8137 14 8.5C14 5.18629 11.3137 2.5 8 2.5C4.68629 2.5 2 5.18629 2 8.5C2 11.8137 4.68629 14.5 8 14.5Z"
                      stroke="#4A4D57"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M7.5 8H8V11.5H8.5"
                      stroke="#4A4D57"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M7.875 6.5C8.28921 6.5 8.625 6.16421 8.625 5.75C8.625 5.33579 8.28921 5 7.875 5C7.46079 5 7.125 5.33579 7.125 5.75C7.125 6.16421 7.46079 6.5 7.875 6.5Z"
                      fill="#4A4D57"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_369_17468">
                      <rect
                        width="16"
                        height="16"
                        fill="white"
                        transform="translate(0 0.5)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </div>

              {/* <div className='live_dot_card'> </div>
                <div className='live_status_text'>Live</div> */}
            </div>

            <div className="donut_chart_data">
              <div className="donut_chart_view">
                {console.log(softwareSeries)}
                {softwareSeries[0] === 0 && softwareSeries[1] === 0 ? (
                  <Chart
                    type="donut"
                    width={200}
                    height={200}
                    series={[0, 1]}
                    options={{
                      labels: ["0%", "0%"],
                      tooltip: {
                        enabled: false,
                        fillSeriesColor: false,
                        theme: false,
                      },
                      fill: {
                        colors: [
                          "rgba(232, 225, 225, 0.4)",
                          "rgba(232, 225, 225, 0.4)",
                        ],
                      },
                      stroke: {
                        width: 0,
                      },
                      plotOptions: {
                        pie: {
                          expandOnClick: false,
                          donut: {
                            expandOnClick: false,
                            labels: {
                              show: true,
                              name: {
                                show: true,
                                fontSize: "14px",
                                fontWeight: 600,
                                color: "#828799",
                                fontFamily: "Poppins",
                              },
                              value: {
                                show: true,
                                fontSize: "24px",
                                fontWeight: 600,
                                color: "#83B148",
                                fontFamily: "Poppins",
                              },
                              total: {
                                show: true,
                                showAlways: true,
                                label: "100%",
                                fontSize: "14px",
                                label: softwareLabel[0] + "%",
                                fontWeight: 600,
                                color: "#828799",
                                fontFamily: "Poppins",
                                formatter: function (w) {
                                  return softwareSeries[0];
                                },
                              },
                            },
                          },
                        },
                      },
                      states: {
                        hover: {
                          filter: {
                            type: "none",
                          },
                        },
                        active: {
                          filter: {
                            type: "none" /* none, lighten, darken */,
                          },
                        },
                      },
                      dataLabels: {
                        enabled: false,
                        // style: {
                        //   colors: ['#F44336', '#E91E63', '#9C27B0']
                        // }
                      },
                      legend: {
                        show: false,
                      },
                    }}
                  />
                ) : (
                  <Chart
                    type="donut"
                    width={200}
                    height={200}
                    series={softwareSeries}
                    options={{
                      labels: softwareLabel,
                      tooltip: {
                        enabled: true,
                        fillSeriesColor: false,
                        theme: "dark",
                        marker: false,
                        custom: function ({
                          series,
                          seriesIndex,
                          dataPointIndex,
                          w,
                        }) {
                          // console.log(seriesIndex)
                          var val;
                          if (seriesIndex === 0)
                            val = "Updated to latest Version";
                          else val = "Needs Upgrade";
                          return (
                            '<div class="arrow_box">' +
                            "<span>" +
                            val +
                            " : " +
                            series[seriesIndex] +
                            "</span>" +
                            "</div>"
                          );
                        },
                      },
                      fill: {
                        colors: ["#83B148", "#F75D5D"],
                      },
                      stroke: {
                        width: 0,
                      },
                      plotOptions: {
                        pie: {
                          expandOnClick: false,
                          donut: {
                            expandOnClick: false,
                            labels: {
                              show: true,
                              name: {
                                show: true,
                                fontSize: "14px",
                                fontWeight: 600,
                                color: "#828799",
                                fontFamily: "Poppins",
                              },
                              value: {
                                show: true,
                                fontSize: "24px",
                                fontWeight: 600,
                                color: "#83B148",
                                fontFamily: "Poppins",
                              },
                              total: {
                                show: true,
                                showAlways: true,
                                label: "100%",
                                fontSize: "14px",
                                label: softwareLabel[0] + "%",
                                fontWeight: 600,
                                color: "#828799",
                                fontFamily: "Poppins",
                                formatter: function (w) {
                                  // console.log(w)
                                  return w.config.series[0];
                                },
                              },
                            },
                          },
                        },
                      },
                      states: {
                        hover: {
                          filter: {
                            type: "none",
                          },
                        },
                        active: {
                          filter: {
                            type: "none" /* none, lighten, darken */,
                          },
                        },
                      },
                      dataLabels: {
                        enabled: false,
                        // style: {
                        //   colors: ['#F44336', '#E91E63', '#9C27B0']
                        // }
                      },
                      legend: {
                        show: false,
                      },
                    }}
                  />
                )}
              </div>
              <div className="donut_chart_info_avilable">
                <div className="avilable_trip_head">Need to update</div>
                <div className="avilable_trip_value">{needUpdate}</div>
              </div>
            </div>

            <div className="operation_card_detail_footer">
              <div className="on_trip_operation_color_details">
                <div className="on_trip_green_dot"></div>
                <div>Updated to latest version</div>
              </div>

              <div className="on_trip_operation_color_details">
                <div className="on_trip_red_dot"></div>
                <div>Running on older s/w version</div>
              </div>
            </div>
          </div>

          <div className="operations_card">
            <div className="operation_card_detail_header">
              <div className="operation_card_detail_header_title">
                Threshold Violations
              </div>
              <div className="information_icon">
                <svg
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_369_17468)">
                    <path
                      d="M8 14.5C11.3137 14.5 14 11.8137 14 8.5C14 5.18629 11.3137 2.5 8 2.5C4.68629 2.5 2 5.18629 2 8.5C2 11.8137 4.68629 14.5 8 14.5Z"
                      stroke="#4A4D57"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M7.5 8H8V11.5H8.5"
                      stroke="#4A4D57"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M7.875 6.5C8.28921 6.5 8.625 6.16421 8.625 5.75C8.625 5.33579 8.28921 5 7.875 5C7.46079 5 7.125 5.33579 7.125 5.75C7.125 6.16421 7.46079 6.5 7.875 6.5Z"
                      fill="#4A4D57"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_369_17468">
                      <rect
                        width="16"
                        height="16"
                        fill="white"
                        transform="translate(0 0.5)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </div>

              {/* <div className='live_dot_card'> </div>
                <div className='live_status_text'>Live</div> */}
            </div>

            <Surge
              exceedLabel={exceedLabel}
              speedExceed={speedExceed}
              tempExceed={tempExceed}
              batteryExceed={batteryExceed}
              rpmExceed={rpmExceed}
            />

            <div className="operation_card_detail_footer">
              <div className="on_trip_operation_color_details">
                <div className="speedreddot"></div>
                <div>Speed</div>
              </div>

              <div className="on_trip_operation_color_details">
                <div className="on_trip_medium_blue_dot"></div>
                <div>Temperature</div>
              </div>

              <div className="on_trip_operation_color_details">
                <div className="on_trip_high_blue_dot"></div>
                <div>RPM</div>
              </div>

              <div className="on_trip_operation_color_details">
                <div className="batteryColor"></div>
                <div>Battery</div>
              </div>
            </div>
          </div>

          <div style={{ marginRight: "3%" }}></div>
          <div className="operations_card">
            <div className="operation_card_detail_header">
              <div className="operation_card_detail_header_title">
                Vehicle Health & Maintenance
              </div>
              <div className="information_icon">
                <svg
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_369_17468)">
                    <path
                      d="M8 14.5C11.3137 14.5 14 11.8137 14 8.5C14 5.18629 11.3137 2.5 8 2.5C4.68629 2.5 2 5.18629 2 8.5C2 11.8137 4.68629 14.5 8 14.5Z"
                      stroke="#4A4D57"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M7.5 8H8V11.5H8.5"
                      stroke="#4A4D57"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M7.875 6.5C8.28921 6.5 8.625 6.16421 8.625 5.75C8.625 5.33579 8.28921 5 7.875 5C7.46079 5 7.125 5.33579 7.125 5.75C7.125 6.16421 7.46079 6.5 7.875 6.5Z"
                      fill="#4A4D57"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_369_17468">
                      <rect
                        width="16"
                        height="16"
                        fill="white"
                        transform="translate(0 0.5)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              {/* 
                <div className='live_dot_card'> </div>
                <div className='live_status_text'>Live</div> */}
            </div>

            <div className="donut_chart_data">
              <div className="donut_chart_view">
                <Chart
                  type="donut"
                  width={200}
                  height={200}
                  series={healthLabel}
                  options={{
                    labels: healthSeries,
                    tooltip: {
                      enabled: true,
                      fillSeriesColor: false,
                      theme: "dark",
                      marker: false,
                      custom: function ({
                        series,
                        seriesIndex,
                        dataPointIndex,
                        w,
                      }) {
                        // console.log(seriesIndex)
                        var val;
                        if (seriesIndex === 0) val = "Good";
                        else if (seriesIndex === 1) val = "Moderate";
                        else val = "Critical";
                        return (
                          '<div class="arrow_box">' +
                          "<span>" +
                          val +
                          " : " +
                          w.globals.seriesNames[seriesIndex] +
                          "</span>" +
                          "</div>"
                        );
                      },
                    },
                    fill: {
                      colors: ["#83B148", "#FFBF5E", "#F75D5D"],
                    },
                    stroke: {
                      width: 0,
                    },
                    plotOptions: {
                      pie: {
                        expandOnClick: false,
                        donut: {
                          expandOnClick: false,
                          labels: {
                            show: true,
                            name: {
                              show: true,
                              fontSize: "14px",
                              fontWeight: 600,
                              color: "#828799",
                              fontFamily: "Poppins",
                            },
                            value: {
                              show: true,
                              fontSize: "24px",
                              fontWeight: 600,
                              color: "#83B148",
                              fontFamily: "Poppins",
                            },
                            total: {
                              show: true,
                              showAlways: true,
                              label: "100%",
                              fontSize: "14px",
                              label: healthLabel[0] + healthLabel[1] + "%",
                              fontWeight: 600,
                              color: "#828799",
                              fontFamily: "Poppins",
                              formatter: function (w) {
                                return healthSeries[0] + healthSeries[1];
                              },
                            },
                          },
                        },
                      },
                    },
                    states: {
                      hover: {
                        filter: {
                          type: "none",
                        },
                      },
                      active: {
                        filter: {
                          type: "none" /* none, lighten, darken */,
                        },
                      },
                    },
                    dataLabels: {
                      enabled: false,
                      // style: {
                      //   colors: ['#F44336', '#E91E63', '#9C27B0']
                      // }
                    },
                    legend: {
                      show: false,
                    },
                  }}
                />
              </div>
              <div className="donut_chart_info_avilable">
                <div className="avilable_trip_head">Service due vehicles</div>
                <div className="avilable_trip_value">{serviceDue}</div>
              </div>
            </div>

            <div className="operation_card_detail_footer">
              <div className="on_trip_operation_color_details">
                <div className="good_green_dot"></div>
                <div>Good({" >"}12 Weeks)</div>
              </div>

              <div className="on_trip_operation_color_details">
                <div className="moderate_orange_dot"></div>
                <div>Moderate(5-12 Weeks)</div>
              </div>

              <div className="on_trip_operation_color_details">
                <div className="bad_red_dot"></div>
                <div>Critical({" <"}5 Weeks)</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
