import React, { useState } from "react";
import { Alert, Typography, Snackbar } from "@mui/material";

export const useToast = () => {
  const [notifyToastOpen, setNotifyToast] = useState(false);
  const [notifyToastMessage, setNotifyToastMessage] = useState("");
  const [toastType, setToastType] = useState("");

  const screenHeight = window.innerHeight;
  const toastPosition = Math.round(screenHeight / 7);

  const handleToastOpen = (msg, type) => {
    setNotifyToast(true);
    setNotifyToastMessage(msg);
    setToastType(type);
  };

  const handleToastClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotifyToast(false);
  };

  const renderToast = () => {
    return (
      <Snackbar
        open={notifyToastOpen}
        autoHideDuration={3000}
        onClose={handleToastClose}
        style={{
          top: `${toastPosition}px`,
          left: "50%",
          transform: "translate(-50%, -50%)",
          borderRadius: "5px",
          height: "40px",
        }}
      >
        <Alert severity={toastType}>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: "medium",
              fontFamily: "DM Sans, sans-serif",
            }}
          >
            {notifyToastOpen ? notifyToastMessage : undefined}
          </Typography>
        </Alert>
      </Snackbar>
    );
  };

  return {
    handleToastOpen,
    renderToast,
  };
};
