// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modelBoardHeaderParent{
    width: 100%;
    display: flex;
    background-color: "#FFFFFF";
}
.listItemModelHeader{
    /* padding: 0px; */
    margin: 0px;
    height: 48px;
    font-size: 14px;
    justify-content: center;
}
.listItemBox:nth-child(1){
    width: 190px;
}
.listItemBox:nth-child(2){
    width: 150px;
}
.listItemBox:nth-child(3){
    width: 225px;
}
.listItemModelHeader:nth-child(1){
    /* margin:auto; */
    width: 225px;
}
.listItemModelHeader:nth-child(2){
    width: 100px;
}
.listItemModelHeader:nth-child(3){
    width: 600px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/ModelBoard/ModelHeader.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,2BAA2B;AAC/B;AACA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,eAAe;IACf,uBAAuB;AAC3B;AACA;IACI,YAAY;AAChB;AACA;IACI,YAAY;AAChB;AACA;IACI,YAAY;AAChB;AACA;IACI,iBAAiB;IACjB,YAAY;AAChB;AACA;IACI,YAAY;AAChB;AACA;IACI,YAAY;AAChB","sourcesContent":[".modelBoardHeaderParent{\r\n    width: 100%;\r\n    display: flex;\r\n    background-color: \"#FFFFFF\";\r\n}\r\n.listItemModelHeader{\r\n    /* padding: 0px; */\r\n    margin: 0px;\r\n    height: 48px;\r\n    font-size: 14px;\r\n    justify-content: center;\r\n}\r\n.listItemBox:nth-child(1){\r\n    width: 190px;\r\n}\r\n.listItemBox:nth-child(2){\r\n    width: 150px;\r\n}\r\n.listItemBox:nth-child(3){\r\n    width: 225px;\r\n}\r\n.listItemModelHeader:nth-child(1){\r\n    /* margin:auto; */\r\n    width: 225px;\r\n}\r\n.listItemModelHeader:nth-child(2){\r\n    width: 100px;\r\n}\r\n.listItemModelHeader:nth-child(3){\r\n    width: 600px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
