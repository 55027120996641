import React, { useEffect } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import Analytics from './Analytics'
import ModelHeader from './ModelHeader.js'
import VehicleDashboard from './VehicleDashboard'
import Predictive from './Predictive'


function ModelBoard() {
    // /dashboard/model/:modelID/vehi
  const currPath = useLocation().pathname;
  useEffect(() => {
    console.log(currPath);
  }, [])
  
  return (
    <div style={{display:"flex", flexDirection:"column",width:"calc(100vw - 241px - 60px)"}}>
        <ModelHeader/>
        <Routes>
            <Route path = "/vehicleDashboard" element = {<VehicleDashboard/>}/>
            <Route path = "/analytics" element = {<Analytics/>}/>
            <Route path = "/predictive" element = {<Predictive/>}/>
        </Routes>
    </div>
  )
}

export default ModelBoard