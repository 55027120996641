import React, { useState, useEffect } from "react";
import {
    Alert,
    Button,
    Typography,
} from "@mui/material";



function NotifyJiraButton(props) {

    const [notifyToastOpen, setNotifyToast] = useState(false);
    const [notifyToastMessage, setNotifyToastMessage] = useState("");
    const [toastType, setToastType] = useState("");

    useEffect(() => {
        if (notifyToastOpen) {
          const timer = setTimeout(() => {
            setNotifyToast(false);
          }, 5000);   
          
          return () => clearTimeout(timer);
        }
      }, [notifyToastOpen]);

  
    const handleToastOpen = (name, type) => {
        setNotifyToast(true);
        setNotifyToastMessage(name);
        setToastType(type);
    };
    
    //handling open close dialog first-one    
    const notifyjira = () => {
        handleToastOpen('Logged HW/SW Details in to JIRA and notified Dev Team', "success")        
    };

    return (
        <div>
            {notifyToastOpen && (
                <div className='update_alert'>
                <Alert severity={toastType}>
                    <Typography className='alertMessage'>{notifyToastMessage}</Typography>
                </Alert>
            </div>
            )}                
           
            <Button
                variant="contained"
                onClick={notifyjira}
                disabled={props.totalSelected < 1 ? true : false}
                className="upgradeButton"
                disableElevation
                sx={{
                    width: "160px",
                    height: "36px",
                    borderRadius: "0px",
                    bgcolor: "#3174A4",
                    color: "black",
                    fontWeight: 400,
                    textTransform: "none",
                    "&:hover": { backgroundColor: "#3174A4" },
                }}
            >
                <Typography
                    sx={{
                        fontWeight: 400,
                        fontSize: "14px",
                        color: props.totalSelected < 1 ? "#8E939E" : "#FFFFFF",
                    }}
                >
                    Notify JIRA
                </Typography>
            </Button>
        </div>
    );
}

export default NotifyJiraButton;