import React,{useState,useEffect} from 'react'
import './index.css'
import {Line} from 'react-chartjs-2'
import Chart from 'chart.js/auto';
import axios from 'axios';
import annotationPlugin from 'chartjs-plugin-annotation';

Chart.register(annotationPlugin);

const Surge = ({exceedLabel, speedExceed, tempExceed, batteryExceed, rpmExceed}) => {

  const [label, setLabel] = useState(['x', 'y']);
  const [speed, setSpeed] = useState([0, 0])
  const [battery, setBattery] = useState([0, 0])
  const [rpm, setRPM] = useState([0, 0])
  const [temp, setTemp] = useState([0, 0])
  
  useEffect(() => {
    setLabel(exceedLabel);
    setTemp(tempExceed)
    setBattery(batteryExceed);
    setRPM(rpmExceed)
    setSpeed(speedExceed);
  
    // var bckg = [];
    // for(var i = 0; i<surgeData.length;  i++) {

    //   console.log(surgeData[i])
    //   if(surgeData[i]>12) bckg.push("#3174A4")
    //   else if(surgeData[i]>5) bckg.push("#48A2DB")
    //   else {
    //     console.log("ho")
    //     bckg.push("#75B4E1")
    //   }
    
    // }
    // setBackColor(bckg)
  },[speedExceed, tempExceed, batteryExceed, rpmExceed, exceedLabel])
  
  
  const data2 = {
    labels: label,
    datasets: [
      {
        label: 'Speed Warning',
        data: speed,
        borderColor: '#800800',
        backgroundColor: '#800800',
        pointRadius: 2,
      },
      {
        label: 'Temperature Warning',
        data: temp,
        borderColor: '#BA3029',
        backgroundColor: '#BA3029',
        pointRadius: 2,
      },
      {
        label: 'RPM Warning',
        data: rpm,
        borderColor: '#D65D59',
        backgroundColor: '#D65D59',
        pointRadius: 2,
      },
      {
        label: 'Battery Warning',
        data: battery,
        borderColor: '#F89191',
        backgroundColor: '#F89191',
        pointRadius: 2,
      },
    ],
    // datasets: [{
    //   data: surgeDat,
    //   backgroundColor: backColor,
    //   borderColor: backColor,
    //   borderRadius: 40,
    //   barPercentage: 0.6,
    //   categoryPercentage: 0.7,
    // }]
  }
      const tempreatureOptions = {
        plugins: {
          datalabels: false,
          legend: false,
          tooltip: {
            callbacks: {
                    label: function(tooltipItems, data) { 
                      // console.log(tooltipItems);
                      // console.log(data)
                        return tooltipItems.dataset.label + ' = ' + tooltipItems.formattedValue + '%';
                    }
                }
          }
        },
        // tooltips: {
        //   enabled: true,
        //   callbacks: {
        //       label: function(tooltipItems, data) { 
        //           return tooltipItems.yLabel + '%';
        //       }
        //   }
        // },
        scales: {
          x: {
            grid: {
              display: false
            },
            ticks: {
              fonstSize: 10
            }
          },
          y: {
            ticks: {
              callback: function (value) {
                return value  + '%'; // convert it to percentage
              },
            },
            title:{
                display:true,
                text:'Threshold Violations',
                font:{
                    family: 'Poppins',
                    size:11
                },
                padding: {top: 0, left: 0, right: 0, bottom: 20}
            },
            grid: {
                display: false
            },
            beginAtZero:true,
          },
          
        }
      }

    return (
        <div style={{width: '40vw', height: '25vh', marginLeft:'4vw', marginTop:'5px'}}>
            <Line data={data2} options={tempreatureOptions}/>
            
        </div>
    )
}

export default Surge;