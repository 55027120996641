import React from "react";
import Table from "../Table/Table";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

const TableView = ({ scan, isCompare = false }) => {
  const [peripheralView, setPeripheralView] = React.useState(false);
  const [peripheralData, setPeripheralData] = React.useState({});
  const [hardwareData, setHardwareData] = React.useState({});
  const [ecuCompareView, setEcuCompareView] = React.useState(false);
  const [ecuNameCount, setEcuNameCount] = React.useState("");
  const [ecuCompareData, setEcuCompareData] = React.useState({});
  const [peripheralCompareView, setPeripheralCompareView] =
    React.useState(false);
  const [peripheral, setPeripheral] = React.useState("");
  const [peripheralCompareData, setPeripheralCompareData] = React.useState({});

  const togglePeripheralCompareView = ({ name, compareData }) => {
    setPeripheralCompareView((prevState) => !prevState);
    setPeripheral(name);
    setPeripheralCompareData(compareData);
  };
  const toggleEcuView = ({ nameCount, compareData }) => {
    setEcuCompareView((prevState) => !prevState);
    setEcuNameCount(nameCount);
    setEcuCompareData(compareData);
  };
  const togglePeripheralView = ({ peripherals, hardware }) => {
    setPeripheralView((prevState) => !prevState);
    setPeripheralData(peripherals);
    setHardwareData(hardware);
  };

  let missingECU = scan?.ECU?.filter((d) =>
    Object.keys(d)[0].includes("Missing ECU")
  );

  let missingPeripheral = scan?.PERIPHERAL?.filter((d) =>
    Object.keys(d)[0].includes("Missing Peripheral")
  );
  return (
    <>
      {!peripheralView && !ecuCompareView && !peripheralCompareView && (
        <>
          <Typography sx={styles.heading}>Gateway Details</Typography>
          <Table
            data={scan?.GATEWAY}
            type={isCompare ? "GATEWAY_COMPARE" : "GATEWAY"}
          />
          <Typography sx={styles.heading}>ECU Details</Typography>
          <Table
            data={scan?.ECU?.filter(
              (d) => !Object.keys(d)[0].includes("Missing ECU")
            )}
            type={isCompare ? "ECU_COMPARE" : "ECU"}
            onClick={isCompare ? toggleEcuView : togglePeripheralView}
          />
          {isCompare && missingECU?.length > 0 && (
            <Typography sx={styles.heading}>Missing ECU Details</Typography>
          )}
          {isCompare && missingECU?.length > 0 && (
            <Table data={missingECU} type={isCompare ? "ECU_MISSING" : "ECU"} />
          )}

          {isCompare && (
            <Typography sx={styles.heading}>Peripheral Details</Typography>
          )}
          {isCompare && (
            <Table
              data={scan?.PERIPHERAL?.filter(
                (d) => !Object.keys(d)[0].includes("Missing Peripheral")
              )}
              type={"PERIPHERAL_COMPARE"}
              onClick={
                isCompare ? togglePeripheralCompareView : togglePeripheralView
              }
            />
          )}
          {isCompare && missingPeripheral?.length > 0 && (
            <Typography sx={styles.heading}>
              Missing Peripheral Details
            </Typography>
          )}
          {isCompare && missingPeripheral?.length > 0 && (
            <Table
              data={missingPeripheral}
              type={"PERIPHERAL_MISSING"}
              onClick={
                isCompare ? togglePeripheralCompareView : togglePeripheralView
              }
            />
          )}
        </>
      )}
      {peripheralView && peripheralData && hardwareData && (
        <>
          <Stack direction="row">
            <KeyboardBackspaceIcon
              onClick={() => setPeripheralView((prevState) => !prevState)}
              sx={{ color: "#aeacac", cursor: "pointer" }}
            />
            <Typography
              sx={{ ...styles.heading, marginLeft: "8px", cursor: "pointer" }}
              onClick={() => setPeripheralView((prevState) => !prevState)}
            >
              {`Peripheral Data - ${hardwareData.ECU_NAME} - ${hardwareData.vin}`}
            </Typography>
          </Stack>
          <Table data={peripheralData} type="PERIPHERAL" />
        </>
      )}
      {peripheralCompareView && peripheral && peripheralCompareData && (
        <>
          <Stack direction="row">
            <KeyboardBackspaceIcon
              onClick={() =>
                setPeripheralCompareView((prevState) => !prevState)
              }
              sx={{ color: "#aeacac", cursor: "pointer" }}
            />
            <Typography
              sx={{ ...styles.heading, marginLeft: "8px", cursor: "pointer" }}
              onClick={() =>
                setPeripheralCompareView((prevState) => !prevState)
              }
            >
              {`${peripheral}`}
            </Typography>
          </Stack>
          <Table data={peripheralCompareData} type="PERIPHERAL_DEEP_COMPARE" />
        </>
      )}
      {ecuCompareView && ecuNameCount && ecuCompareData && (
        <>
          <Stack direction="row">
            <KeyboardBackspaceIcon
              onClick={() => setEcuCompareView((prevState) => !prevState)}
              sx={{ color: "#aeacac", cursor: "pointer" }}
            />
            <Typography
              sx={{ ...styles.heading, marginLeft: "8px", cursor: "pointer" }}
              onClick={() => setEcuCompareView((prevState) => !prevState)}
            >
              {`${ecuNameCount}`}
            </Typography>
          </Stack>
          <Table data={ecuCompareData} type="ECU_DEEP_COMPARE" />
        </>
      )}
    </>
  );
};

export default TableView;

const styles = {
  heading: {
    fontSize: "1rem",
    fontWeight: "bold",
    marginBottom: "24px",
    color: "#aeacac",
  },
  label: {
    fontSize: "0.875rem",
  },
};
