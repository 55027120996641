// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.map_view_area {
    margin-top: 3%;
    width: 100%;
    height: 100%;
}
.map_view_main_component {
    overflow-x: hidden;
    height: calc(100vh - 40px - 80px);
    width: calc(100vw - 241px - 60px);
    background-color: #F2F2F2;
    
}
/* .map_view_main_component {
    scrollbar-width: none;
   -ms-overflow-style: none;
 }
 
 .map_view_main_component::-webkit-scrollbar {
   display: none;
 } */
.vehicle_list {
    margin-left: 3vw;
    background-color: white;
    width: 27%;
    height: 82vh;
    box-shadow: 20px 4px 24px rgba(0, 0, 0, 0.02);
    border-radius: 14px;
}

.map_vehicle_box {
    background: rgba(255, 255, 255, 0.88);
    border-radius: 7px;
    padding: 5px;
    position: absolute;
    margin-left: 7vw;
    width: 10vw;
    font-size: 0.8vmax;
    
}
.map_vehicle_name {
    font-size: 0.9vmax;
    font-weight: 600;
}

.map_vehicle_status {
    display: flex;
}
.map_vehicle_regno, .map_vehicle_online {
    color: #76787A;
}
.map_vehicle_online {
    margin-left: .5vw;
}`, "",{"version":3,"sources":["webpack://./src/Components/Mapview/index.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,WAAW;IACX,YAAY;AAChB;AACA;IACI,kBAAkB;IAClB,iCAAiC;IACjC,iCAAiC;IACjC,yBAAyB;;AAE7B;AACA;;;;;;;IAOI;AACJ;IACI,gBAAgB;IAChB,uBAAuB;IACvB,UAAU;IACV,YAAY;IACZ,6CAA6C;IAC7C,mBAAmB;AACvB;;AAEA;IACI,qCAAqC;IACrC,kBAAkB;IAClB,YAAY;IACZ,kBAAkB;IAClB,gBAAgB;IAChB,WAAW;IACX,kBAAkB;;AAEtB;AACA;IACI,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,aAAa;AACjB;AACA;IACI,cAAc;AAClB;AACA;IACI,iBAAiB;AACrB","sourcesContent":[".map_view_area {\r\n    margin-top: 3%;\r\n    width: 100%;\r\n    height: 100%;\r\n}\r\n.map_view_main_component {\r\n    overflow-x: hidden;\r\n    height: calc(100vh - 40px - 80px);\r\n    width: calc(100vw - 241px - 60px);\r\n    background-color: #F2F2F2;\r\n    \r\n}\r\n/* .map_view_main_component {\r\n    scrollbar-width: none;\r\n   -ms-overflow-style: none;\r\n }\r\n \r\n .map_view_main_component::-webkit-scrollbar {\r\n   display: none;\r\n } */\r\n.vehicle_list {\r\n    margin-left: 3vw;\r\n    background-color: white;\r\n    width: 27%;\r\n    height: 82vh;\r\n    box-shadow: 20px 4px 24px rgba(0, 0, 0, 0.02);\r\n    border-radius: 14px;\r\n}\r\n\r\n.map_vehicle_box {\r\n    background: rgba(255, 255, 255, 0.88);\r\n    border-radius: 7px;\r\n    padding: 5px;\r\n    position: absolute;\r\n    margin-left: 7vw;\r\n    width: 10vw;\r\n    font-size: 0.8vmax;\r\n    \r\n}\r\n.map_vehicle_name {\r\n    font-size: 0.9vmax;\r\n    font-weight: 600;\r\n}\r\n\r\n.map_vehicle_status {\r\n    display: flex;\r\n}\r\n.map_vehicle_regno, .map_vehicle_online {\r\n    color: #76787A;\r\n}\r\n.map_vehicle_online {\r\n    margin-left: .5vw;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
