import { AppBar, Avatar, Box, CssBaseline, Drawer, List, ListItem, ListItemButton, ListItemIcon, Menu, MenuItem, Stack, Toolbar } from '@mui/material'
import React, { useState, useEffect } from 'react'
import axios, { Axios } from 'axios';
import './MainHeader.css'
import Deloitte from "../../Assets/deloitte2.png"
import AvatarImage from "../../Assets/AvatarImage.png"
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useLocation } from 'react-router-dom'
import {baseUrl,baseSocketUrl} from '../../constants'

function MainHeader() {


  const [notifications, setNotifications] = useState([]);
  const [notificationsPrevious, setNotificationsPrevious] = useState([]);
  const [notificationsToday, setNotificationsToday] = useState([]);
  const [activeDayToday, setActiveDayToday] = useState(true);
  const [open, setOpen] = useState(false);
  const [showLogo, setShowLogo] = useState(0);

  const location = useLocation();
  const [renderInSDV,setRenderInSDV]=useState(false);

  useEffect(() => {
    var socket = new WebSocket(baseSocketUrl+'/notify/');
    console.log(socket)
    socket.onopen = () => {
        console.log("connection success notification")
    }
    socket.onmessage = function(event) {
    //   console.log("normal"+event.data);
    //   console.log(notifications+" notifications")
      if(notifications[notifications.length-1]!==event.data){
        setNotifications((prev) => [...prev, event.data]);
      }
     
    }

    if(location.pathname == '/mapview') {
        setShowLogo(1);

    }
}, []);

const url = baseUrl+"/get/notificationData/";
const body = {isToday:false}
 useEffect(() => {
     const fetchDeviceList = async() => {
         await axios.post(url, body,{headers : {Authorization : `Bearer ${getJwtToken()}`}}).then((res) => {
        const temp = res.data;
        setNotificationsPrevious(temp)
        })
        // .catch(error => { localStorage.removeItem("token"); 
        // localStorage.removeItem("username");
        // navigate("/login"); console.log("error"); });
        }
     fetchDeviceList();      
 }, [])


 useEffect(()=>{
    if(notifications.length>25){
        setNotifications((oldArray) => oldArray.filter((_,i)=>i!==0));
      }
 },[notifications])

 const url2 = baseUrl+"/get/notificationData/";
 const body2 = {isToday:true}
  useEffect(() => {
      const fetchDeviceList = async() => {
          await axios.post(url2, body2,{headers : {Authorization : `Bearer ${getJwtToken()}`}}).then((res) => {
         const temp = res.data;
         setNotificationsToday(temp)
         })
        //  .catch(error => { localStorage.removeItem("token"); 
        //  localStorage.removeItem("username");navigate("/login"); console.log("error"); });
         }
      fetchDeviceList();      
  }, [])

  const displayNotification = (n) => {
    var date1 = moment();
    var date2 = moment(n["time"]);
    var diffInMinutes = date1.diff(date2, 'minutes');
    var diffInHours = date1.diff(date2, 'hours');
    var diffInDays = date1.diff(date2, 'day');
    return (
        <>
        <div className="notification_row" style={{paddingLeft:"20px"}}>
            {/* <div className="new_notification_circle">
            </div> */}
            {/* <div className="car_notification_image"><img src={n["url"]} width="60px"></img></div> */}
            <div className="notification_box">
                <div>{n["registrationno"]}</div>
                <div><span className="notification">{n["message"]}</span></div>
            </div>
            {activeDayToday?<div className="notification_time">
                {/* {diffInMinutes} min ago */}
                {diffInMinutes<60 ? diffInMinutes + " min ago ": diffInHours+ " hours ago"}
            </div>:<div className="notification_time">
            {diffInHours<24 ? diffInHours + " hours ago ": diffInDays+ " days ago"}
            </div>}
            
        </div>
        <div className="horizonta_line_notification"></div>
      
      </>
    );
  };

  const displayNotificationLive = (n) => {
    let data = JSON.parse(n)
    // let data = n
    console.log(data)
    var date1 = moment();
    var date2 = moment(data.time);
    var diffInMinutes = date1.diff(date2, 'minutes');
    var diffInHours = date1.diff(date2, 'hours');
    return (
        <>
        <div className="notification_row">
            <div className="new_notification_circle"></div>
            {/* <div className="car_notification_image"><img src={data.url} width="60px"></img></div> */}
            <div className="notification_box">
                <div>{data.registrationno}</div>
                <div><span className="notification">{data.message}</span></div>
            </div>
            <div className="notification_time">
                {(diffInMinutes-330)<60? diffInMinutes-330 + " min ago": diffInHours+ "hour ago"}
                {/* {diffInMinutes-330} min ago */}
            </div>
        </div>
        <div className="horizonta_line_notification"></div>
      </>
    );
  };

  const [styleNotifications, setStyleNotifications] = useState('notifications-tab');
      const [styleNotificationsContainer, setStyleNotificationsContainer] = useState('notifications');
      const [notificationAll, setNotificationsAll] = useState(true);
    const viewNotifications=()=>{
        
        if(notificationAll){
            setStyleNotifications('notifications-tab notifications-tab-extend');
            setStyleNotificationsContainer('notifications notifications-extend');
        }else{
            setStyleNotifications('notifications-tab');
            setStyleNotificationsContainer('notifications');
        }
        
        setNotificationsAll(!notificationAll)
    }
    
 
  const navigate = useNavigate();

  const getJwtToken = () => { let token = localStorage.getItem("token") || undefined; return token === undefined ? "" : `${JSON.parse(token)}`; };

  useEffect(() => {
    getUsername();
  })
  const [username, setUsername] = useState("")
  const getUsername = () => {
      let token = localStorage.getItem('username') || undefined;
      // token = token.substring(1,2).toUpperCase()+token.substring(2, token.length-1).toLowerCase();
      token = token.substring(1, token.length-1);
      // console.log(token)
      setUsername(token === undefined ? "" : token);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
      setAnchorEl(null);
  };

  let urlLogout = baseUrl+`/api/user/logout/`;
  const logout = async() => {
    await axios.post(urlLogout, null,{ headers: {Authorization : `Bearer ${getJwtToken()}`} })
    .then(result => {
        console.log(result);
        console.log("logout successfully");
        localStorage.clear()
        navigate("/login");
    }).catch(error => {
        console.log("cannot logout");
    })
  }
  window.addEventListener('message', (event) => {
    
 
    // setRenderInSDV(true);
    // setTimeout(() => 
    //     setRenderInSDV(false),[5000],);
    // Log or use isVisible and additionalData as needed
    // console.log('isVisible:', isVisible);
    // console.log('additionalData:', additionalData);
  });
  return (    
    <div>
        {renderInSDV ===true ?
(<></>)
:(
    <div className = 'topBar'>
      <Toolbar>
        {/* <img src = {Deloitte} style = {{ height: "25px", width:"139px",left: "1px", top:"15px" }} alt="deloitte logo"/> */}
      </Toolbar>
          
      <div className='navProfileSide'>
        <div>
           {/* bell icon */}
           <div className='bell_icon icons' >
                      <div className="icon" onClick={() => setOpen(!open)}>
                          
                          <svg width="17" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M16.8 13.449V9.62358C16.7983 7.91915 16.1949 6.27033 15.0967 4.9691C13.9986 3.66788 12.4765 2.79823 10.8 2.51416V1.20295C10.8 0.883906 10.6736 0.577931 10.4485 0.352335C10.2235 0.126739 9.91826 0 9.6 0C9.28174 0 8.97651 0.126739 8.75147 0.352335C8.52643 0.577931 8.4 0.883906 8.4 1.20295V2.51416C6.72353 2.79823 5.20143 3.66788 4.10326 4.9691C3.0051 6.27033 2.40172 7.91915 2.4 9.62358V13.449C1.69974 13.6971 1.0932 14.1564 0.663526 14.7637C0.233854 15.371 0.00208741 16.0967 0 16.8413V19.2472C0 19.5662 0.126428 19.8722 0.351472 20.0978C0.576516 20.3234 0.88174 20.4501 1.2 20.4501H4.968C5.24434 21.4694 5.8476 22.3693 6.68471 23.0109C7.52183 23.6524 8.54627 24 9.6 24C10.6537 24 11.6782 23.6524 12.5153 23.0109C13.3524 22.3693 13.9557 21.4694 14.232 20.4501H18C18.3183 20.4501 18.6235 20.3234 18.8485 20.0978C19.0736 19.8722 19.2 19.5662 19.2 19.2472V16.8413C19.1979 16.0967 18.9661 15.371 18.5365 14.7637C18.1068 14.1564 17.5003 13.6971 16.8 13.449ZM4.8 9.62358C4.8 8.34741 5.30571 7.12351 6.20589 6.22113C7.10606 5.31874 8.32696 4.81179 9.6 4.81179C10.873 4.81179 12.0939 5.31874 12.9941 6.22113C13.8943 7.12351 14.4 8.34741 14.4 9.62358V13.2324H4.8V9.62358ZM9.6 21.6531C9.18117 21.6505 8.77029 21.5381 8.4082 21.3271C8.04611 21.1161 7.74541 20.8137 7.536 20.4501H11.664C11.4546 20.8137 11.1539 21.1161 10.7918 21.3271C10.4297 21.5381 10.0188 21.6505 9.6 21.6531ZM16.8 18.0442H2.4V16.8413C2.4 16.5222 2.52643 16.2162 2.75147 15.9906C2.97652 15.7651 3.28174 15.6383 3.6 15.6383H15.6C15.9183 15.6383 16.2235 15.7651 16.4485 15.9906C16.6736 16.2162 16.8 16.5222 16.8 16.8413V18.0442Z" fill="#242731"/>
                              {notifications.length >0 &&
                              <circle cx="15.5" cy="6.5" r="5.5" fill="#FF7172" stroke="white" stroke-width="2"/>
                              }
                          </svg>

                      </div>
                  </div>

                 
                  {open && (
                      <div className={styleNotificationsContainer}>
                          <div className="notification_header">Notifications <span className="close_notification" onClick={() => {setOpen(!open);setNotifications([]);}}>x</span></div>
                          <div className="day_select_tab"> 
                              <span className={activeDayToday?"not_active_day":"active_day"} onClick={()=> setActiveDayToday(false)}>Previous</span>
                              <span className={activeDayToday?"active_day":"not_active_day"} onClick={()=> setActiveDayToday(true)}>Today <span className="notification_length">{notifications.length}</span></span>
                          </div>
                          <hr className="border_day_select"></hr>
                          {activeDayToday?
                              <div className={styleNotifications}>
                              {notifications.slice(0).reverse().map((n,index) => displayNotificationLive(n))}
                              <div className="today_older_notifications">Older Notifications...</div>
                              {notificationsToday.map((n) => displayNotification(n))}
                              </div>:
                              <div className={styleNotifications}>
                              {notificationsPrevious.map((n) => displayNotification(n))}
                              </div>
                          }       
                          <button className="nButton" onClick={viewNotifications}>
                              {notificationAll?"View All Notifications":"View Fewer Notifications"}
                          </button>
                      </div>
                  )}
        </div>
        {/* <div>
          <Avatar alt="Avatar" src={AvatarImage}  className='profile_pic' sx = {{width:"35px", height:"35px"}}/>
        </div> */}
        {/* <div className="admin_header_name">{username}</div> */}
        {/* <div className="admin_header_name">John Doe</div> */}
        <div className="admin_header_dropdown" onClick={handleClick}>
            <svg width="15" height="9" viewBox="0 0 15 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.23351 0L0.980225 1.37275L7.99012 9L15 1.36504L13.7467 0L7.99012 6.26992L2.23351 0Z" fill="black"/>
            </svg>
        </div>
        <Menu
            id="demo-positioned-menu"
            aria-labelledby="demo-positioned-button"
            anchorEl={anchorEl}
            open={openMenu}
            onClose={handleClose}
            sx={{
                top:"50px",
            }}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
        >
            <MenuItem onClick={logout}>Logout</MenuItem>
        </Menu>
      </div>
    </div>
)
            }
      
      
    </div>
    
  )
}

export default MainHeader