import * as React from "react";
import TableContainer from "@mui/material/TableContainer";

import { renderTable } from "./TableTemplate";

const Table = ({ data, type, onClick }) => {
  if(!data){
    return(<div style={{ marginBottom: '32px', fontSize: '0.875rem', marginLeft: '8px'}}> No Data </div>)
  }
  return (
    <>
    <TableContainer sx={{ border: "1px solid rgba(224, 224, 224, 1)", marginBottom: '32px', maxHeight: '380px' }}>
        {renderTable(data, type, onClick)}
    </TableContainer>
    </>
  );
};

export default Table;
