import { Box, List, ListItem, ListItemButton,listItemClasses,  ListItemIcon } from '@mui/material'
import React, { useState } from 'react'
import axios from "axios";
import { NavLink, useLocation } from 'react-router-dom'
import { ReactComponent as  HomeIcon} from "../../Assets/HomeIcon.svg"
import { ReactComponent as  HomeIconActive} from "../../Assets/HomeIconActive.svg"
import { ReactComponent as UpgradeButton} from "../../Assets/UpgradeButton.svg"
import { ReactComponent as UpgradeButtonActive} from "../../Assets/UpgradeButtonActive.svg"
import { ReactComponent as Fota} from "../../Assets/fota.svg"
import { ReactComponent as FotaActive} from "../../Assets/fota-active.svg"
import { ReactComponent as NavigateIcon } from '../../Assets/NavigateIcon.svg'
import { ReactComponent as Esync } from '../../Assets/esync.svg';
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import  {ReactComponent as ReportLogo} from '../../Assets/esync.svg';
import ReportGenerationLogo from "../../Assets/report_generation.png";
import ReportGenerationLogoSelected from "../../Assets/report_generation_selected.png";
import Ota from "../../Assets/ota_icon.png";
import OtaSelected from "../../Assets/ota_icon_selected.png";

function MainSideBar() {
    const pathname = useLocation().pathname.split("/");
    // console.log("pathName:",pathname)
    const [iconId, setIconId] = useState("home");
    const handleIconClick = (id) => ()=> {
        setIconId(id);
    };

    const openNavPage = () => {
        fetch("https://app.launchdarkly.com/internal/account/login", {
          method: "post",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: "gkamalkishortawar@deloitte.com",
            password: "Deloitte@123F",
          }),
            credentials: "same-origin", 
        })
          .then((response) => {
            return response.json();
          })
          .then((json) => {
            if (json.success) {
              console.log(json.redirect);
            }
          });

        // Fetch("https://app.launchdarkly.com/internal/account/login", {
        //     email:"gkamalkishortawar@deloitte.com",
        //     password:"Deloitte@123F"
        // },{
        //     credentials: "same-origin"
        // }).then((result)=>{
        //     console.log(result);
        //     window.open('https://launchdarkly.com','_self')
        // })
        // window.open('')
    }
    return (
        <Box
            sx = {{
                bgcolor: "#000000",
                // minHeight: "100vh",
                width: "60px",
                [`& .active, & .${listItemClasses.root}:active`]: {
                    color:"black",
                    "& svg": {
                        color:"red",
                    },
                    "& svg.path" : {stroke:"#93BA45"},
                    "& p": {
                        fill:"black",
                        fontWeight: 400,
                    },
            }}}
        >
            <List
                sx = {{left:-12,[`& .active, & .${listItemClasses.root}:active`]: {
                    "& svg": {
                        top: "193px",
                        fill:'#00000',
                    }
                }}}
            >
                <ListItem
                    className = "listItemHome"
                    key = {1}
                    component = {NavLink}
                    to = {"/dashboard/home"}
                    onClick = {handleIconClick(1)}
                >
                    <ListItemButton>
                        <ListItemIcon>
                            {(pathname.at(2) === "home") ? <HomeIconActive/> : <HomeIcon/>}
                        </ListItemIcon>
                    </ListItemButton>
                </ListItem>
                <Box className = "showTextHome">
                    Home
                </Box>
                < ListItem
                    className = "listItemReport"
                    key = {1}
                    component = {NavLink}
                    to = {"/dashboard/report"}
                    
                >
                    <ListItemButton >
                   
                        <ListItemIcon 
                       style={{ marginLeft: '-5px' }}
                        > 
                
                        <button style={{width:'30px', height:'30px',border:'none', backgroundSize: 'cover',
                       
                        background: `url(${pathname.at(2)==="report"? ReportGenerationLogoSelected:ReportGenerationLogo})`}}/> 
                             {/* {(pathname.at(2) === "report") ? <ReportGenerationLogoSelected/> : <ReportGenerationLogo/>} */}
                       </ListItemIcon>
                    </ListItemButton>
                </ListItem> 
            
                <Box className = "showTextReport">
                  Report
                </Box>
                {/* // Hiding the PES OTA 
                    <ListItem
                    className = "listItemSoftwareUpgrade"
                    key = {1}
                    component = {NavLink}
                    to = {"/dashboard/softwareUpgrade"}
                    onClick = {handleIconClick(2)}
                >
                    <ListItemButton>
                        <ListItemIcon>
                            {(pathname.at(2) === "softwareUpgrade") ? <UpgradeButtonActive/> : <UpgradeButton/>}
                        </ListItemIcon>
                    </ListItemButton>
                </ListItem>
                <Box className = "showTextSoftwareUpgrade">
                    Software Upgrade
                </Box> */}
                <ListItem
                    className = "listItemVehicleScan"
                    key = {1}
                    component = {NavLink}
                    to = {"/dashboard/vehicle-scan"}
                    // onClick = {handleIconClick(2)}
                >
                    <ListItemButton>
                        <ListItemIcon>
                            {(pathname.at(2) === "vehicle-scan") ? <TroubleshootIcon sx={{color: '#93BA45 !important'}}/> : 
                                <TroubleshootIcon sx={{color: '#A6A6A9 !important'}}/>}
                        </ListItemIcon>
                    </ListItemButton>
                </ListItem>
                <Box className = "showTextVehicleScan">
                    Vehicle Scan
                </Box>
                <ListItem
                    className = "listItemFeatureConfigurations"
                    key = {1}
                    component = {NavLink}
                    // to = {"/dashboard/softwareUpgrade"}
                    // onClick = {()=>{window.open('https://app.launchdarkly.com/default/production/features','_self')}}
                    onClick = {()=>{window.open('https://app.launchdarkly.com/default/production/features')}}
                >
                    <ListItemButton>
                        <ListItemIcon>
                            <NavigateIcon/>
                        </ListItemIcon>
                    </ListItemButton>
                </ListItem>
                <Box className = "showTextFeatureConfigurations">
                    Feature configurations
                </Box>
                <ListItem
                    className = "listItemFota"
                    key = {1}
                    component = {NavLink}
                    to = {"/dashboard/ota"}
                    //onClick = {handleIconClick(2)}
                >
                    <ListItemButton style={{width:'48px'}}>
                        <ListItemIcon
                        style={{ marginLeft: '-5px' }}
                        // style={{ width:"32px"}}
                        >
                            {/* {(pathname.at(2) === "ota") ? <FotaActive/> : <Fota/>} */}
                            {/* <Esync/> */}
                            <button style={{width:'31px', height:'30px',border:'none', 
                   background:`url(${pathname.at(2)==="ota" ? OtaSelected:Ota })`}}/> 
                       
                        </ListItemIcon>
                    </ListItemButton>
                </ListItem>
                <Box className = "showTextFota">
                    Esync OTA
                </Box>
                <ListItem
                className = "listItemOta4"
                key = {1}
                component = {NavLink}
                // to = {"/dashboard/excelfore"}
                onClick = {()=>{window.open('https://deloitte-esync.excelfore.com/sotauiv4/#/dashboard')}}
                >
              <ListItemButton>
                   
                   <ListItemIcon 
                //   style={{ marginLeft: '-5px' }}
                   > 
            <Esync/>
                   {/* <button style={{width:'30px', height:'30px',border:'none', backgroundSize: 'contain',
                   background:`url(${pathname.at(2)==="excelfore" ? Ota4:Ota4})`}}/>  */}
                       
                  </ListItemIcon>
               </ListItemButton>
           </ListItem> 
       
           <Box className = "showTextOta4">
           Excelfore
           </Box>
            
            </List>
        </Box>
    )
}

export default MainSideBar