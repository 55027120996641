import React, { useState, useEffect } from "react";
// import { baseSocketUrl, baseUrl, IntervalAPI } from "../../constants";
import ScanButton from "../SoftwareUpgrade/ScanButton";
import CircularProgress from "@mui/material/CircularProgress";
// import "./vscan_details.css";


import "./Excelfore.css";

let ccid = null,
  ssid = null,
  ccitid = null,
  ccmodel = null;

function ExcelforeInitialPage({navSelected}) {

    console.log("nav selected in mCT for logs generation",navSelected)




return(
    <div className="remote_device_page">
        
      {/* {renderToast()} */}
      
      
      <div style={{ marginTop: "10px" ,overflow:'auto',overflowY:'auto',overflowX:'auto'}}>
          
          <iframe
         
         src={"https://excelfore-global.okta.com/"}
         
            style={{ width: "100%", height: "800px", border: "none" }}
            // title="MCTVehicleScanning"
          />
        
       
      </div>
     
      
    </div>
);
}
export default ExcelforeInitialPage;