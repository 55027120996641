import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import LoginPage from './Pages/LoginPage';
import PrivateRoute from './PrivateRoute';


function App() {
  return (
    <BrowserRouter>
      <div className='Add-div'>
        <Routes>
          <Route path = "/dashboard/*" element = {<PrivateRoute/>}></Route>
          <Route path = "/login" element = {<LoginPage/>}></Route>
          <Route path = "/" element = {<LoginPage/>}></Route>
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
