export const baseUrl =
  // "https://Telematics-Backend-895771443.us-east-1.elb.amazonaws.com";
  'https://sdvexperience-backend.deloitte.com';

export const baseSocketUrl =
  // "ws://Telematics-Websocket-2b5a2ab3d382533b.elb.us-east-1.amazonaws.com";
  "wss://sdvexperience-backend.deloitte.com";

export const FotaUrl =
  "https://hviedjw0hb.execute-api.us-east-1.amazonaws.com/production/ota-deployment";

export const MLUrl =
  // "http://Telematics-ML-Model-1151476303.us-east-1.elb.amazonaws.com";
  "https://sdvexperience-backend.deloitte.com";

export const IntervalAPI =
  "https://hviedjw0hb.execute-api.us-east-1.amazonaws.com/production/ota-status";

export const EsyncSupported="esync";
export const PesSupported="pes";
