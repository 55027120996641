import React, { useState, useEffect } from "react";
import {
  Alert,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  IconButton,
  Stack,
  Typography,
  DialogContentText,
  Box,
  FormControl,
  RadioGroup,
} from "@mui/material";
import { ReactComponent as CrossIcon } from "../../Assets/CrossIcon.svg";
import dayjs from "dayjs";
import axios from "axios";

function UpgradeDialog(props) {
  const { onClose, open, allDeviceNameList, triggerApi } = props;
  const [loading, setLoading] = useState(false);
  const [upgradeSheduleList, setList] = useState(new Map());
  const [showAll, setShowAll] = useState(true);

  const [firstOpen, setFirstOpen] = useState(true);
  const [scanResult, setScanResult] = useState();

  //setting up current date
  const current = new Date();

  var minimumTimeSchdeuleTime = new Date(current.getTime() + 61 * 60 * 1000); //60 minute ahead;
  var finalTime = `${minimumTimeSchdeuleTime.getFullYear()}-${
    minimumTimeSchdeuleTime.getMonth() + 1
  }-${minimumTimeSchdeuleTime.getDate()}T${minimumTimeSchdeuleTime.getHours()}:${minimumTimeSchdeuleTime.getMinutes()}`;

  const [time, setTime] = React.useState(dayjs(finalTime));
  const [minSettedTime, setMinSettedTime] = React.useState(dayjs(finalTime));

  //next 6 month date
  const maxLimitDate = new Date();
  const [maxLimit, setMaxLimitTime] = useState("");
  useEffect(() => {
    // maxLimitDate.setMonth(maxLimitDate.getMonth()+6);
    maxLimitDate.setDate(maxLimitDate.getDate() + 5);
    setMaxLimitTime(
      `${maxLimitDate.getFullYear()}-${
        maxLimitDate.getMonth() + 1
      }-${maxLimitDate.getDate()}`
    );
    // console.log("next 6 month date: " + maxLimit);
  });

  //dialog variables
  const [dialogCount, setDialogCount] = useState(1);

  //Alert Message variables
  const [notifyToastOpen, setNotifyToast] = useState(false);
  const [notifyToastMessage, setNotifyToastMessage] = useState("");
  const [toastType, setToastType] = useState("");

  //Dialog functions
  const handleClose = (event, reason) => {
    if (reason && reason == "backdropClick") return;
    onClose(true);
    // setLoading(false);
    // setTimeout(() => {
    //   refreshPage();
    // }, 2000);
    // setTimeout(()=>{setDialogCount(1)}, 1000);
  };
  const crossClose = (event, reason) => {
    if (reason && reason == "backdropClick") return;
    onClose(true);
    setLoading(false);
    setTimeout(() => {
      setDialogCount(1);
      setScanResult();
    }, 500);
    setShowAll(true);
    // setScheduleUpgrade(false);
    // setUpgradeAllDevice(false);
    setFirstOpen(true);
    triggerApi()
  };

  //refresh current page
  function refreshPage() {
    window.location.reload(false);
  }

  //Upgading all selected devices <API CALL>
  const upgradeAll = async () => {
    const vinArray = allDeviceNameList.map((device) => device[2]);
    setLoading(true);
    await axios
      .post(
        "https://hviedjw0hb.execute-api.us-east-1.amazonaws.com/production/initiatescanning",
        {
          vin: vinArray,
        }
      )
      .then((result) => {
        setLoading(false);
        setScanResult(result.data.message);
        setDialogCount(5);
      })
      .catch((error) => {
        setLoading(false);
        console.log("Initiate Scan error", error);
      });
  };

  let greenButtonStyle = {
    borderRadius: "0px",
    width: "220px",
    height: "36px",
    fontWeight: 400,
    fontSize: "14px",
    color: "white",
    textTransform: "none",
    bgcolor: "#3174A4",
    "&:hover": { backgroundColor: "#3174A4" },
  };
  let whiteButtonStyle = {
    borderRadius: "0px",
    width: "220px",
    height: "36px",
    fontWeight: 400,
    fontSize: "14px",
    color: "black",
    textTransform: "none",
    borderColor: "white",
    bgcolor: "#F6F6F6",
    padding: "0px",
    "&:hover": { borderColor: "white" },
  };

  const deviceDetail = (vin) => {
    return allDeviceNameList.filter((device) => device[2] === vin)[0];
  };
  return (
    <div>
      {/* <Snackbar
                open={notifyToastOpen}
                autoHideDuration={4000}
                onClose={handleToastClose}
                TransitionComponent={SlideTransition}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
                <Alert severity={toastType}>
                    <Typography className='alertMessage'>{notifyToastOpen ? notifyToastMessage:undefined}</Typography>
                </Alert>
            </Snackbar> */}
      <div className="update_alert">
        <Alert severity={toastType}>
          <Typography className="alertMessage">
            {notifyToastOpen ? notifyToastMessage : undefined}
          </Typography>
        </Alert>
      </div>
      <Dialog
        onClose={handleClose}
        open={open}
        aria-labelledby="confirm-multi-OS-update"
        aria-describedby="confirm-multi-OS-choice"
        disableEscapeKeyDown
        PaperProps={{
          sx: {
            width: "500px",
            borderRadius: "0px",
            height: dialogCount === 1 ? "540px" : undefined,
          },
        }}
      >
        {loading ? (
          <Stack
            direction="column"
            sx={{
              display: "flex",
              alignItems: "center",
              textAlign: "center",
              position: "center",
              justifyContent: "center",
              pt: "43px",
              pb: "43px",
            }}
          >
            <CircularProgress color="primary" />
            <Typography className="Initialising">
              Initializing<span className="loader__dot">.</span>
              <span className="loader__dot">.</span>
              <span className="loader__dot">.</span>
            </Typography>
          </Stack>
        ) : (
          <>
            <DialogTitle
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                height: "10px",
                flexFLow: "row nowrap",
                bgcolor: "#000",
              }}
            >
              <Typography
                variant="h4"
                sx={{
                  fontStyle: "normal",
                  fontWeight: 700,
                  fontSize: "16px",
                  lineHeight: "21px",
                  color: "#FFF",
                }}
              >
                {dialogCount === 1
                  ? "Vehicle Scan"
                  : dialogCount === 2 || dialogCount === 4 || dialogCount === 5
                  ? "Vehicle Scan Confirmation"
                  : dialogCount === 3
                  ? "Schedule Upgrade"
                  : undefined}
              </Typography>
              <IconButton onClick={crossClose}>
                <CrossIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent sx={{ alignContent: "left", overflow: "hidden" }}>
              <DialogContentText
                sx={{
                  align: "justify",
                  fontSize: "14px",
                  pt: "10px",
                  justifyContent: "left",
                  textAlign: "left",
                }}
              >
                {dialogCount === 1 ? (
                  `Following vehicles / models are selected for scan.`
                ) : dialogCount === 2 ? (
                  `Are you sure you want to scan the selected vehicles now?`
                ) : dialogCount === 5 ? (
                  <ul>
                    {Object.entries(scanResult).map((scan) => (
                      <li>{`${deviceDetail(scan[0])?.[0]} (${
                        deviceDetail(scan[0])?.[1]
                      }): ${scan[1]}`}</li>
                    ))}
                  </ul>
                ) : (
                  <></>
                )}
              </DialogContentText>
              {dialogCount === 1 ? (
                <div>
                  <Box
                    sx={{
                      border: 1,
                      height: "200px",
                      mt: "20px",
                      borderColor: "#E9ECEF",
                      overflow: "auto",
                      padding: "5px",
                    }}
                  >
                    {allDeviceNameList.length < 1 ? (
                      <div className="emptyContent">Empty...</div>
                    ) : (
                      allDeviceNameList.map((item, index) => {
                        console.log(item[0], " ", item[1]);
                        return (
                          <div
                            style={{
                              marginLeft: "12px",
                              mt: "5px",
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            {<div className="Listdot"></div>}
                            {`${item[0]} `}({item[1]})
                          </div>
                        );
                      })
                    )}
                  </Box>
                  <div className="radioButton">
                    <FormControl>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue={showAll}
                        name="radio-buttons-group"
                      >
                        {/* <FormControlLabel value="Scan Now" control={<Radio />} onClick = {()=>{handleShowAll(false)}} label="Scan Now" /> */}
                        {/* <FormControlLabel value="Schedule Upgrade" control={<Radio />} onClick = {()=>{handleShowAll(true)}} label="Schedule Upgrade" /> */}
                      </RadioGroup>
                    </FormControl>
                  </div>
                  <div
                    style={{
                      color: "red",
                      fontSize: "12px",
                      marginBottom: "4px",
                      height: "17px",
                      display: !showAll ? "block" : "none",
                    }}
                  ></div>
                  <div
                    style={{
                      color: firstOpen ? "#404145" : "red",
                      fontSize: "12px",
                      marginBottom: "4px",
                      display: firstOpen ? "none" : showAll ? "block" : "none",
                      paddingLeft: "25px",
                    }}
                  >
                    *Note: Min 1 hr from current time
                  </div>
                </div>
              ) : (
                <></>
              )}
            </DialogContent>
            <DialogActions style={{ justifyContent: "center" }}>
              {dialogCount === 1 ? (
                <Stack direction="row" spacing={1}>
                  <Button
                    variant="outlined"
                    onClick={handleClose}
                    className="upgradeButton"
                    disableElevation
                    sx={whiteButtonStyle}
                  >
                    Cancel
                  </Button>
                  <Button
                    // disabled = {(firstOpen)?true:(!showAll  && upgradeDeviceNameList.length < 1 )? true : (showAll && allDeviceNameList.length < 1)? true : false}
                    variant="contained"
                    // onClick = {()=>{setDialogCount(2)}}alertCheck
                    onClick={() => {
                      setDialogCount(2);
                    }}
                    sx={greenButtonStyle}
                  >
                    Continue
                  </Button>
                </Stack>
              ) : dialogCount === 2 ? (
                <Stack direction="row" spacing={1}>
                  <Button
                    onClick={handleClose}
                    variant="outlined"
                    sx={whiteButtonStyle}
                  >
                    No
                  </Button>
                  <Button
                    onClick={upgradeAll}
                    variant="contained"
                    sx={greenButtonStyle}
                  >
                    Yes
                  </Button>
                </Stack>
              ) : undefined}
            </DialogActions>
          </>
        )}
      </Dialog>
    </div>
  );
}

function ScanButton(props) {
  const [open, setOpen] = useState(false);
  //handling open close dialog first-one
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = (value) => {
    setOpen(false);
  };
  return (
    <div>
      <Button
        variant="contained"
        onClick={handleClickOpen}
        disabled={props.totalSelected < 1 ? true : false}
        className="upgradeButton"
        disableElevation
        sx={{
          width: "160px",
          height: "36px",
          borderRadius: "0px",
          bgcolor: "#3174A4",
          color: "black",
          fontWeight: 400,
          textTransform: "none",
          "&:hover": { backgroundColor: "#3174A4" },
        }}
      >
        <Typography
          sx={{
            fontWeight: 400,
            fontSize: "14px",
            color: props.totalSelected < 1 ? "#8E939E" : "#FFFFFF",
          }}
        >
          {props.mode === "scan" ? "Scan" : "Update PLM"}
        </Typography>
      </Button>
      <UpgradeDialog
        open={open}
        onClose={handleClose}
        totalSelected={props.totalSelected}
        allDeviceNameList={props.allDeviceNameList}
        upgradeDeviceNameList={props.upgradeDeviceNameList}
        disableBackdropClick
        triggerApi={props.triggerApi}
      />
    </div>
  );
}

export default ScanButton;
