import React from 'react'
import {Line} from 'react-chartjs-2'
import Chart from 'chart.js/auto';
import { useEffect } from 'react';
import { useState } from 'react';
import axios from 'axios'
import { useLocation } from 'react-router-dom';
import { baseUrl } from '../../../constants';

const Graph = () => {

const pathname = useLocation().pathname.split("/");
  const [timeline, setTimeline] = useState(["25 Nov", "2 Dec", "9 Dec", "16 Dec", "23 Dec", "30 Dec", "06 Jan", "13 Jan", "20 Jan", "27 Jan"])
  const [rul, setRUL] = useState([null,null,null,null,null,null,null,null,null,null])
  const engineTrendUrl = baseUrl+'/basicDetails/rul/range/';
  
  const viin = {vin: pathname.at(4), time: Date()}

  const getJwtToken = () => { 
    let token = localStorage.getItem("token") || undefined;
    return token === undefined ? "" : `${JSON.parse(token)}`;
 };

  useEffect(() => {
    axios.post(engineTrendUrl, viin, {headers : {Authorization : `Bearer ${getJwtToken()}`}}).then((res) => {
      const temp = res.data.result;
      const ruldata = temp.rul;
      for(var i = 0; i<10; i++) {
        if(ruldata[i] != null) {
          ruldata[i] = Math.floor(ruldata[i]/7);
        }
        else ruldata[i] = null
      }
      setRUL(ruldata);
      setTimeline(temp.time);
      console.log(res.data);
    })
  }, []);
  
  const data = {
    labels: timeline,
    datasets: [
      {
        data: rul,
        tension: 0.5,
        pointBorderWidth: 0,
        pointBackgroundColor: 'rgba(1, 1, 1, 0.1)',
        segment: {
          borderColor: context => {
            if(context.p1.parsed.y>12) return "#98B642"
            else if(context.p1.parsed.y>5) return "#FFD44A";
            else return "#FF7777"
            
          },
          backgroundColor: context => {
            if(context.p1.parsed.y>12) return "#98B642"
            else if(context.p1.parsed.y>5) return "#FFD44A";
            else return "#FF7777"
            
          },
          
        },
        fill: true,
      }
    ]
  }

  const Options = {
    plugins: {
      legend: false,
      datalabels: false,
    },
    scales: {
      x: {
        grid: {
          display: false
        }
      },
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 20,
          callback: (value) => value
        },
        grid: {
          borderDash: [40]
        }
      }
    }
  }
  return (
    <div className='engine_trend'>
        <div className='engine_trend_head'>Engine Life Trend</div>
        <div className='engine_trend_detail'>
            <div className='engine_trend_left'>
                <div>Remaining Engine Life</div>
                <div className='in_weeks'>(in weeks)</div>
            </div>

            <div className='engine_trend_right'>
                <div className='good_color_box'></div>
                <div className='trend_condition_text'>Good</div>
                <div className='mode_color_box'></div>
                <div className='trend_condition_text'>Moderate</div>
                <div className='crit_color_box'></div>
                <div className='trend_condition_text'>Critical</div>
            </div>
        </div>

        <div className='line_chart_live' style={{display: 'inline'}}>
          <Line 
            height={"79vh"}
            data={data}
            options={Options}/>
        </div>
    </div>
  )
}

export default Graph