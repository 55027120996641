import {
  Dialog as MuiDialog,
  DialogTitle,
  Typography,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  Button,
  Stack,
  CircularProgress,
} from "@mui/material";

import React from "react";
import { ReactComponent as CrossIcon } from "../../Assets/CrossIcon.svg";

const Dialog = ({
  dialogOpen,
  toggleDialog,
  title,
  content,
  actionsEnabled = false,
  loading = false,
  fullScreen = false,
  small = false,
  onSuccess,
}) => {
  const dialogBody = () => {
    return (
      <>
        <DialogContent sx={styles.dialogContent}>
          {content}
        </DialogContent>
        {actionsEnabled && (
          <DialogActions sx={styles.dialogActions}>
            <Stack
              direction="column"
              sx={{ width: "100%" }}
            >
              <DialogContentText sx={styles.dialogContentText}>
                Would you like to update?
              </DialogContentText>
              <Stack
                justifyContent="center"
                direction="row"
                spacing={1}
                sx={{ width: "100%" }}
              >
                <Button
                  onClick={toggleDialog}
                  variant="outlined"
                  sx={styles.whiteButtonStyle}
                >
                  No
                </Button>
                <Button
                  onClick={onSuccess}
                  variant="contained"
                  sx={styles.greenButtonStyle}
                >
                  Yes
                </Button>
              </Stack>
            </Stack>
          </DialogActions>
        )}
      </>
    );
  };
  return (
    <MuiDialog
      open={dialogOpen}
      PaperProps={small ? styles.paperPropsSmall : styles.paperProps}
      fullScreen={fullScreen}
      maxWidth={"md"}
    >
      <DialogTitle sx={styles.titleContainer}>
        <Typography variant="h4" sx={styles.title}>
          {title}
        </Typography>
        <IconButton onClick={toggleDialog}>
          <CrossIcon />
        </IconButton>
      </DialogTitle>
      {loading ? (
        <div style={small ? styles.loaderSmall : styles.loader}>
          <CircularProgress color="primary" />
        </div>
      ) : (
        dialogBody()
      )}
    </MuiDialog>
  );
};

export default Dialog;

const styles = {
  paperProps: {
    sx: {
      borderRadius: "0px",
      minWidth: "90vw",
      minHeight: "90vh",
    },
  },
  paperPropsSmall: {
    sx: {
      borderRadius: "0px",
      minWidth: "60vh",
      minHeight: "30vh",
    },
  },
  titleContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "10px",
    flexFLow: "row nowrap",
    bgcolor: "#000",
  },
  title: {
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "21px",
    color: "#FFF",
  },
  greenButtonStyle: {
    borderRadius: "0px",
    width: "220px",
    height: "36px",
    fontWeight: 400,
    fontSize: "14px",
    color: "white",
    textTransform: "none",
    bgcolor: "#3174A4",
    "&:hover": { backgroundColor: "#3174A4" },
  },
  whiteButtonStyle: {
    borderRadius: "0px",
    width: "220px",
    height: "36px",
    fontWeight: 400,
    fontSize: "14px",
    color: "black",
    textTransform: "none",
    borderColor: "white",
    bgcolor: "#F6F6F6",
    padding: "0px",
    "&:hover": { borderColor: "white" },
  },
  dialogContent: {
    // display: "flex",
    // flexDirection: "column",
    // justifyContent: "space-between",
    // alignContent: "left",
    marginTop: "24px",
    overflow: "hidden",
    overflowY: "auto",
  },
  dialogContentText: {
    fontSize: "16px",
    textAlign: "center",
    padding: '8px'
  },
  dialogActions: { padding: "0px 24px 12px 24px" },
  loader: {
    minHeight: "80vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  loaderSmall: {
    minHeight: "25vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};
