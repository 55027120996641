import { Box, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography} from '@mui/material';
import { borders } from '@mui/system';
import React, {useState, useEffect} from 'react'
import './ModelHeader.css'
import {NavLink, useLocation, useNavigate } from 'react-router-dom'
import { ReactComponent as  VehicleDashboard} from "../../Assets/VehicleDashboard.svg"
import { ReactComponent as VehicleDashboardIconActive } from '../../Assets/VehicleDashboardIconActive.svg';
import { ReactComponent as  AnalyticsIcon} from "../../Assets/AnalyticsIcon.svg"
import { ReactComponent as  AnalyticsIconActive} from "../../Assets/AnalyticsIconActive.svg"
import { ReactComponent as  PredictiveIcon} from "../../Assets/PredictiveIcon.svg"
import { ReactComponent as  PredictiveIconActive} from "../../Assets/PredictiveIconActive.svg"

function ModelHeader() {
  const pathname = useLocation().pathname.split("/");
  const navigate = useNavigate();
  useEffect(() => {
    console.log(pathname)
  }, [pathname])

  const [iconId, setIconId] = useState("vehicleDashboard");
  const handleIconClick = (id) => ()=> {
    setIconId(id);
  };

  const modelBarItems = [
    {
        path: `/dashboard/home/model/${pathname.at(4)}/vehicleDashboard`,
        pathEndName:"vehicleDashboard",
        name: "Vehicle Dashboard",
        icon: <VehicleDashboard/>,
        iconActive: <VehicleDashboardIconActive/>
    },
    {
        path: `/dashboard/home/model/${pathname.at(4)}/analytics`,
        pathEndName:"analytics",
        name: "Analytics",
        icon: <AnalyticsIcon/>,
        iconActive: <AnalyticsIconActive/>
    },
    {
        path: `/dashboard/home/model/${pathname.at(4)}/predictive`,
        pathEndName:"predictive",
        name: "Predictive Maintenance", 
        icon: <PredictiveIcon/>,
        iconActive: <PredictiveIconActive/>
    },
  ]
  
  return (
    <nav>
      <div className = 'modelBoardHeaderParent'>
        <List
          sx = {{
            left:"21px",
            display:"flex",
            flexDirection: "row",
            justifyContent:"flex-start",
            alignContent:"left",
            alignItems:"left",
            padding:"0px"
          }}
        >
          {
            modelBarItems.map((item, index)=>(
              <Box
                className = "listItemBox"
                sx = {(pathname.at(5) === item.pathEndName)
                      ?{
                        border:5,
                        borderTop:0,
                        borderLeft:0,
                        borderRight:0,
                        borderColor:"#3174A4",
                      }:
                      {}}
              >
                <ListItem
                className = "listItemModelHeader"
                key = {item.pathEndName}
                component = {NavLink}
                to = {item.path}
                onClick={handleIconClick(item.pathEndName)}
                > 
                  <ListItemButton disableRipple className='listItemButton'
                                  sx={
                                      {
                                          padding:"0px",margin:"0px",
                                          justifyContent:"flex-start",
                                          ':hover':{cursor:'pointer', backgroundColor:"transparent", color:"#3174A4"}
                                      }
                                  }
                  >
                    <div style={{marginRight:"10px"}}>
                        {(pathname.at(5) === item.pathEndName) ? item.iconActive : item.icon}
                    </div>
                    <ListItemText primary = {<Typography sx = {{fontSize:"14px", color:(pathname.at(5) === item.pathEndName)?"#3174A4":"black"}}>{item.name}</Typography>}/>
                  </ListItemButton>
                </ListItem>
              </Box>
          ))          
          }
        </List>
      </div>
    </nav>
  )
}

export default ModelHeader