import React from 'react'

export const DashboardFleetData = ({totalVehicle, onlineVehicle, offlineVehicle, vehicleAttention}) => {
  return (
    <div className='main_dashboard_fleet_vehicle'>
        <div className='fleet_vehicle_detail_card'>
          <div className='fleet_vehicle_detail_head'>Total Vehicles</div>
          <div className='fleet_vehicle_detail_value'>{totalVehicle}</div>
        </div>
        <div style={{marginRight: '3%'}}></div>
        <div className='fleet_vehicle_detail_card'>
          <div className='fleet_vehicle_detail_head'>Online Vehicles</div>
          <div className='fleet_vehicle_online_detail_value'>{onlineVehicle}</div>
        </div>
        <div style={{marginRight: '3%'}}></div>
        <div className='fleet_vehicle_detail_card'>
          <div className='fleet_vehicle_detail_head'>Offline Vehicles</div>
          <div className='fleet_vehicle_offline_detail_value'>{offlineVehicle}</div>
        </div>
        <div style={{marginRight: '3%'}}></div>
        <div className='fleet_vehicle_detail_card'>
          <div className='fleet_vehicle_detail_head'>Vehicles need attention</div>
          <div className='fleet_vehicle_attention_detail_value'>{vehicleAttention}</div>
        </div>
      </div>
  )
}
