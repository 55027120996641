import React from 'react'

const BouncyLoader = (props) => {
    return (
      <>
        <div className="bouncing-loader">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </>
    );
  };
  
  export default BouncyLoader;
