import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DashboardFleetData } from '../Headers/DashboardFleetData';
import './index.css'
import Map from './Map';
import {baseSocketUrl, baseUrl} from '../../constants';

var ccid = null, ssid = null, ccitid = null, ccmodel = null;

const Mapview = ({navSelected, val, dateFilter}) => {
    const [totalVehicle, setTotalVehicle] = useState(0);
    const [onlineVehicle, setOnlineVehicle] = useState(0);
    const [offlineVehicle, setOfflineVehicle] = useState(0);
    const [vehicleAttention, setVehicleAttention] = useState(0);

    const [basicData, setBasicData] = useState(null);

    const [socketHomeDeviceData, setSocketHomeDeviceData]=useState(null);

    const getJwtToken = () => {
      let token = localStorage.getItem("token") || undefined;
      return token === undefined ? "" : `${JSON.parse(token)}`;
  };


  const url = baseUrl+'/basicDetails/vehiclehistory/data/';

  //api call to get static data
  useEffect(() => {
      var cid = null;
      var sid = null;
      var citid = null;
      var cmodel = null;
      var historicDate = null;
      var currentDate = Date()
    
     


    if(navSelected !== undefined && navSelected !== null) {
      // console.log(navSelected)
      if(navSelected.type === 'country') {
        cid = !navSelected.show ? navSelected.id : null;
      }
      else if(navSelected.type == 'state') {
        if(!navSelected.show) {
          cid = navSelected.prevcountryid;
          sid = navSelected.id;
        }
        else {
          cid = navSelected.prevcountryid;
        }
      }
      else {
        if(!navSelected.show) {
          cid = navSelected.prevcountryid;
          sid = navSelected.prevstateid;
          citid = navSelected.id;
        }
        else {
          cid = navSelected.prevcountryid;
          sid = navSelected.prevstateid;
        }
      }

     

      // ------------------------------------------------- Api code for fetching data ---------------------------------------------------------

      const param = {
        "country_id" : parseInt(cid),
        "state_id" : parseInt(sid),
        "city_id" : parseInt(citid),
        "current_datetime" : currentDate,
        "historical_datetime": null,
        "vehiclemodel": null,
      }

      

      const getData = async() => {
        await axios.post(url, param,{ headers: {Authorization : `Bearer ${getJwtToken()}`} }).then(result => {
            // console.log(result);
            
            setBasicData(result.data)
        })
      }
      getData();

      // ------------------------------------------------- Socket code for fetching data ---------------------------------------------------------
      
      //give vehiclemodel here for socket req
      ccid = cid;
      ssid = sid;
      ccitid = citid;
      

      
        const paramSocket = {
          "country_id":cid,
          "state_id":sid,
          "city_id":citid,
          "vehiclemodel": null,
        }
    

      const getDatafromSocket2 = () => {
        var socket = new WebSocket(baseSocketUrl+'/devicestate/');
        // console.log(socket) 
        socket.onopen = () => {
            console.log("connection success")
            socket.send(JSON.stringify(paramSocket))
        }
        socket.onmessage = function(event) {
            
            // console.log(event.data)
            const distData = JSON.parse(event.data);
            // console.log(distData)
            if(ccid === cid && ssid === sid && ccitid === citid) setSocketHomeDeviceData(distData);
            
        }
    }

      getDatafromSocket2();
    }
    else {

      // ------------------------------------------------- Api code for fetching data ---------------------------------------------------------
      const param = {
        "country_id" : cid,
        "state_id" : sid,
        "city_id" : citid,
        "current_datetime" : currentDate,
        "vehiclemodel": null,
        "historical_datetime": null,
      }

      const getData = async() => {
        await axios.post(url, param,{ headers: {Authorization : `Bearer ${getJwtToken()}`} }).then(result => {
            // console.log(result);
            
            setBasicData(result.data)
        })
      }

      
      getData();


    //   // ------------------------------------------------- Socket code for fetching data ---------------------------------------------------------

    //   //give vehiclemodel here for socket if req
      const paramSocket = {
        "country_id":cid,
        "state_id":sid,
        "city_id":citid,
        "vehiclemodel": null,
      }

      


      const getDatafromSocket2 = () => {
        var socket = new WebSocket(baseSocketUrl+'/devicestate/');
        // console.log(socket) 
        socket.onopen = () => {
            console.log("connection success")
            socket.send(JSON.stringify(paramSocket))
        }
        socket.onmessage = function(event) {
            
            // console.log(event.data)
            const distData = JSON.parse(event.data);
            // console.log(distData)
            if(ccid === cid && ssid === sid && ccitid === citid) setSocketHomeDeviceData(distData);
            
        }
    }

      getDatafromSocket2();
    }

      
  }, [navSelected,val,dateFilter])
  console.log(basicData)
  useEffect(() => {
    if(basicData!==null) {
      
      setTotalVehicle(basicData.result.total_vehicles);
      setOnlineVehicle(basicData.result.online_vehicles);
      setOfflineVehicle(basicData.result.total_vehicles - basicData.result.online_vehicles);
      setVehicleAttention(basicData.result.vehicles_need_attention)
    }
}, [basicData])

useEffect(() => {
  if(socketHomeDeviceData !== null) {
    setOnlineVehicle(socketHomeDeviceData.Online)
    setOfflineVehicle(socketHomeDeviceData.Offline)
  }
  
},[socketHomeDeviceData])

    let navigate = useNavigate();
    // console.log(navSelected)
    useEffect(() => {
      verifyCurrentUser()
    })

    const urltemp = baseUrl+"/api/user/verify/";
    const verifyCurrentUser = async() => {
        await axios.post(urltemp, 
                        null,
                        { headers: {Authorization : `Bearer ${getJwtToken()}`} } 
        ).then(result => {
            // console.log(result);
            console.log("Correct User Loged-In")
            if(result.data.bool !== 1){
                // navigate("/login");
            }
        })
    }

    return (
        <div className='map_view_main_component'>
        <div style={{marginRight:"3%", marginLeft: "3%", height:'100%'}}>
            <div className='main_dashboard_fleet_vehicle'>
                <div className='fleet_vehicle_detail_card'>
                <div className='fleet_vehicle_detail_head'>Total Vehicles</div>
                <div className='fleet_vehicle_detail_value'>{totalVehicle}</div>
                </div>
                <div style={{marginRight: '3%'}}></div>
                <div className='fleet_vehicle_detail_card'>
                <div className='fleet_vehicle_detail_head'>Online Vehicles</div>
                <div className='fleet_vehicle_online_detail_value'>{onlineVehicle}</div>
                </div>
                <div style={{marginRight: '3%'}}></div>
                <div className='fleet_vehicle_detail_card'>
                <div className='fleet_vehicle_detail_head'>Offline Vehicles</div>
                <div className='fleet_vehicle_offline_detail_value'>{offlineVehicle}</div>
                </div>
                <div style={{marginRight: '3%'}}></div>
                <div className='fleet_vehicle_detail_card'>
                <div className='fleet_vehicle_detail_head'>Vehicles need attention</div>
                <div className='fleet_vehicle_attention_detail_value'>{vehicleAttention}</div>
                </div>
            </div>
            <Map navSelected={navSelected} val={val} dateFilter={dateFilter}/>
        </div>
        
                        

        </div>
    )
}

export default Mapview;