import React, { useState, useEffect } from "react";
import axios from "axios";
import DashBoard from './Pages/Dashboard';
import { NavLink, useLocation, useNavigate } from "react-router-dom";

function PrivateRoute() {
    let navigate = useNavigate();
    const check_for_token = () => {
        let token = localStorage.getItem("token");
        console.log("token:",token)
        return token != null;
    };
    const handleSubmit = async () => {
   
        // setLoading(true);
      
        await axios
          .post("https://sdvexperience-backend.deloitte.com/api/user/login/", {
            // email: email,
            // password: password,
            email: "sandeep.sharma@gmail.com",
            password: "sandeep123",
          })
          .then((result) => {
            localStorage.setItem("token", JSON.stringify(result.data.token.access));
            localStorage.setItem("username", JSON.stringify(result.data.username));
            var isAuthentication  = check_for_token();
            console.log("Auth:",isAuthentication)
            navigate("/dashboard/ota/Swift%20Drive%20X1/J7")
            // setLoading(false);
            // navigate("/dashboard/home");
          })
          .catch((error) => {
            // navigate("/dashboard/home")
            // setLoading(false);
            // handleToastOpen("Invalid credentials", "warning");
            console.log("Error");
          });
      };
    var isAuthenticated  = check_for_token();
    console.log("Auth:",isAuthenticated)

    if (isAuthenticated) {
        // If the user is authenticated, allow access to the Dashboard component
        return <DashBoard />;

    } else {
        // If the user is not authenticated, redirect to the login page
        // return <Navigate to="/login" replace />;
        // return <DashBoard />
        handleSubmit()
    }
   
}

export default PrivateRoute;