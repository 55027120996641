import React,{useState,useEffect} from 'react'
import './index.css'
import { Route, Routes } from 'react-router-dom'
import Home from '../Components/Home'
import MainHeader from '../Components/Headers/MainHeader'
import SubHeader from '../Components/Headers/SubHeader'
import Mapview from '../Components/Mapview'
import ModelBoard from '../Components/ModelBoard'
import SoftwareUpgrade from '../Components/SoftwareUpgrade'
import OtaPage from '../Components/SoftwareUpgrade/OtaPage'
import VehicleScan from '../Components/SoftwareUpgrade/VehicleScan'
import MainSideBar from '../Components/Headers/MainSideBar'
import FleetManagement from '../Components/Headers/FleetManagement'
import MapView from '../Components/Mapview'
import FotaPage from '../Components/Fota/FotaPage'
import Fota from '../Components/Fota'
import Report from '../Components/Report/ReportInitialPage'
import Excelfore from '../Components/Excelfore/ExcelforeInitialPage'
import axios from "axios";
import {  baseUrl} from "../constants";

function Dashboard() {
  const [navSelected, setNavSelected] = useState();
  const handleData = arr => {
    setNavSelected(arr);
  }
  const [val,setVal] = useState();
  const filterData = arr =>{
    setVal(arr)
  }
  const [dateFilter,setDateFilter] = useState();
  const filterDataDate = arr =>{
    console.log(arr)
    setDateFilter(arr)
  }
  // rendering current page
useEffect(() => {
  
  handleSubmit()
  
}, []);
const handleSubmit = async () => {
   
  // setLoading(true);


  await axios
    .post(baseUrl + "/api/user/login/", {
      // email: email,
      // password: password,
      email: "sandeep.sharma@gmail.com",
      password: "sandeep123",
    })
    .then((result) => {
      console.log(result);
      console.log("token: " + JSON.stringify(result.data.token.access));
      localStorage.setItem("token", JSON.stringify(result.data.token.access));
      localStorage.setItem("username", JSON.stringify(result.data.username));
      // setLoading(false);
      // navigate("/dashboard/home");
    })
    .catch((error) => {
      // navigate("/dashboard/home")
      // setLoading(false);
      // handleToastOpen("Invalid credentials", "warning");
      console.log("Error");
    });
};

  return (
    <div className='dashboard'>
      {/* <MainHeader/> */}
      
      <div className='mainData'>
        <MainSideBar/>
        <div className='main_area_render'> 
          <SubHeader filterData={filterData} filterDataDate={filterDataDate}/>
          <div style={{display:'flex', flexDirection:'row'}}>
            <FleetManagement handleData={handleData} val={val}/>
            <Routes>
              <Route path = "/home" element = {<Home navSelected={navSelected} val={val} dateFilter={dateFilter}/>}/>
              <Route exact path = "/softwareUpgrade/:model/:hardware" element = {<OtaPage navSelected={navSelected}/>}/>     
              <Route exact path = "/ota/:model/:hardware" element = {<FotaPage navSelected={navSelected}/>}/>                
              <Route exact path = "/vehicle-scan" element = {<VehicleScan navSelected={navSelected}/>}/>              
              <Route path = "/softwareUpgrade/*" element = {<SoftwareUpgrade navSelected={navSelected}/>}/>
              <Route path = "/ota/*" element = {<Fota navSelected={navSelected}/>}/>
              <Route path = "/home/mapview" element = {<Mapview navSelected={navSelected} val={val} dateFilter={dateFilter}/>}/>
              <Route path="/home/model/:modelId/*" element={<ModelBoard/>} />
              <Route path = "/report" element = {<Report navSelected={navSelected}/>}/>
              <Route path = "/excelfore" element = {<Excelfore navSelected={navSelected}/>}/>
            </Routes>
          </div>
        </div>
        
      </div>
    </div>
  )
}

export default Dashboard