// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  font-family: 'Poppins', sans-serif;
  overflow: hidden;
  height: 100vh;
}

.dashboard { 
  display: flex;
  flex-direction: column;
}

.active #predictive-white{
  /* fill:#fff; */
  stroke: "#93BA45";
  border-color: aqua;
}
.passwordInputinput::-ms-clear, input::-ms-reveal {
  display: none;
}

.passwordInputinput::-ms-clear, input::-ms-reveal {
  display: none;
}

.mainData{
  display: flex;
  flex-direction: row;
}

.bouncing-loader {
  top: 10px;
  display: flex;
  justify-content: center;
  margin: 10px auto;
}

.bouncing-loader > div {
  width: 10px;
  height: 10px;
  margin: 2px 2px;
  border-radius: 50%;
  background-color: #000000;
  opacity: 1;
  animation: bouncing-loader 0.6s infinite alternate;
}

@keyframes bouncing-loader {
  to {
    opacity: 0.1;
    transform: translateY(-10px);
  }
}

.bouncing-loader > div:nth-child(2) {
  animation-delay: 0.2s;
}

.bouncing-loader > div:nth-child(3) {
  animation-delay: 0.4s;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,kCAAkC;EAClC,gBAAgB;EAChB,aAAa;AACf;;AAEA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,kBAAkB;AACpB;AACA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,SAAS;EACT,aAAa;EACb,uBAAuB;EACvB,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,eAAe;EACf,kBAAkB;EAClB,yBAAyB;EACzB,UAAU;EACV,kDAAkD;AACpD;;AAEA;EACE;IACE,YAAY;IACZ,4BAA4B;EAC9B;AACF;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,qBAAqB;AACvB","sourcesContent":["body {\r\n  font-family: 'Poppins', sans-serif;\r\n  overflow: hidden;\r\n  height: 100vh;\r\n}\r\n\r\n.dashboard { \r\n  display: flex;\r\n  flex-direction: column;\r\n}\r\n\r\n.active #predictive-white{\r\n  /* fill:#fff; */\r\n  stroke: \"#93BA45\";\r\n  border-color: aqua;\r\n}\r\n.passwordInputinput::-ms-clear, input::-ms-reveal {\r\n  display: none;\r\n}\r\n\r\n.passwordInputinput::-ms-clear, input::-ms-reveal {\r\n  display: none;\r\n}\r\n\r\n.mainData{\r\n  display: flex;\r\n  flex-direction: row;\r\n}\r\n\r\n.bouncing-loader {\r\n  top: 10px;\r\n  display: flex;\r\n  justify-content: center;\r\n  margin: 10px auto;\r\n}\r\n\r\n.bouncing-loader > div {\r\n  width: 10px;\r\n  height: 10px;\r\n  margin: 2px 2px;\r\n  border-radius: 50%;\r\n  background-color: #000000;\r\n  opacity: 1;\r\n  animation: bouncing-loader 0.6s infinite alternate;\r\n}\r\n\r\n@keyframes bouncing-loader {\r\n  to {\r\n    opacity: 0.1;\r\n    transform: translateY(-10px);\r\n  }\r\n}\r\n\r\n.bouncing-loader > div:nth-child(2) {\r\n  animation-delay: 0.2s;\r\n}\r\n\r\n.bouncing-loader > div:nth-child(3) {\r\n  animation-delay: 0.4s;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
