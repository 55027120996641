import { OneKkSharp } from '@mui/icons-material';
import { ReactComponent as CrossIcon} from "../../Assets/CrossIcon.svg"
import { Alert, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Snackbar, Stack, Typography } from '@mui/material'
import Slide, { SlideProps } from "@mui/material/Slide";
import axios from 'axios';
import React, { useState, useEffect } from 'react'
import { FotaUrl } from '../../constants';

function CancelAllButtonDialog(props) {

    const { onClose, open,cancelDeviceNameList} = props;
    const [ loading, setLoading] = useState(false);
    const [ dialogCount, setDialogCount ] = useState(0);

    const [ notifyToastOpen, setNotifyToast] = useState(false);
    const [ notifyToastMessage, setNotifyToastMessage ] = useState("");

    // const [cancelList, setList] = useState(new Map());
    const [ deviceNameList, setDeviceList ] = useState(props.device)
    const [cancelListBody, setCancelList] = useState([]);
    const [ toastType, setToastType] = useState("");

    const url = "https://kvia15r7r3.execute-api.ap-northeast-1.amazonaws.com/Prod/ota-update"

    useEffect(() => {
        // console.log(deviceNameList);
    }, [deviceNameList])

    useEffect(() => {
        // console.log(cancelDeviceNameList);
        // console.log(cancelDeviceNameList.length)
    }, [cancelDeviceNameList])


    useEffect(() => {
        setDeviceList(props.device)
    }, [props.device])

    //toast functions
    //opening toast
    const handleToastOpen = (name, type) => {
        console.log(name);
        setNotifyToast(true);
        setNotifyToastMessage(name);
        setToastType(type);
    };

    //closing toast function
    const handleToastClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setNotifyToast(false);
    };

    //animation in toast
    function SlideTransition(props) {
        return <Slide {...props} direction="down" />;
    }

    //Dialog functions
    //Closing dialog
    const handleClose = (event, reason) => {
        if (reason && reason == "backdropClick") //disabling backdrop Click close functionality
            return;
        onClose(true);
        // setLoading(false);
        setTimeout(() => {refreshPage()}, 2000);
        // setTimeout(()=>{setDialogCount(1)}, 1000);
    };
    const crossClose = (event, reason) => {
        if(reason && reason == "backdropClick")
            return;
        onClose(true);
        setLoading(false);
        setTimeout(()=>{setDialogCount(1)}, 1000);
    }

    //refresh current page
    function refreshPage() {
        window.location.reload(false);
    }

    //Cancelling all the selected devices <API CALL>
    const cancelAll = async() => {
        console.log("cancelling all selected Ones ....");
        setLoading(true); //loading dialog
        console.log(deviceNameList);
        console.log([...deviceNameList.values()])

        // initialising request body device array of array
        for(const element of props.device){   
            cancelListBody.push(Object.keys(element)[0])
            console.log(Object.keys(element)[0])
        }
        console.log(cancelListBody);

        //API calling
        await axios.delete(FotaUrl, {data: {
            devices: cancelListBody
        }}).then(result => { //OK response comes 
            console.log(result)
            setLoading(false);
            handleToastOpen('Cancelation successful, Refreshing...', 'success')          
            handleClose();
        }).catch(error => { //ERROR comes
            setCancelList([])
            console.log("Error in create job...");
            handleToastOpen('Cancellation unsuccessful, Refreshing...', 'error')
            handleClose();
        })
    }

    let greenButtonStyle = {
        borderRadius:"0px", 
        width:"220px", 
        height: "36px",
        fontWeight: 400,
        fontSize: "14px",
        textTransform: "none",
        bgcolor: (cancelDeviceNameList.length < 1)?"#D3D3D3":"#3174A4",
        "&:hover":{backgroundColor: "#3174A4"}
    }
    let whiteButtonStyle = {
        borderRadius:"0px",
        width:"220px", 
        height: "36px",
        fontWeight: 400,
        fontSize: "14px",
        color: "black",
        textTransform: "none",
        borderColor:"white",
        bgcolor:"#FFFFFF",
        padding: "0px",
        "&:hover": {borderColor: "#FFFFFF"}
    }

    return (
        <div>
            {/* <Snackbar
                open={notifyToastOpen}
                autoHideDuration={4000}
                onClose={handleToastClose}
                TransitionComponent={SlideTransition}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
                <Alert severity={toastType}>
                    <Typography className='alertMessage'>{notifyToastOpen ? notifyToastMessage:undefined}</Typography>
                </Alert>
            </Snackbar> */}
            <div className='update_alert'>
                <Alert severity={toastType}>
                    <Typography className='alertMessage'>{notifyToastOpen ? notifyToastMessage:undefined}</Typography>
                </Alert>
            </div>
            <Dialog
                open = {open}
                onClose = {handleClose}
                disableEscapeKeyDown
                PaperProps={{
                    sx : {
                        width: "500px",
                        borderRadius: "0px",
                        // height: (dialogCount === 1)?"540px":undefined
                    }
                }}
            >
                {
                    loading ? 
                    (<Stack direction="column" sx = {{display: "flex",alignItems: "center",textAlign: "center",position: "center", justifyContent: "center", padding: "45px"}}>
                        <CircularProgress color='success'/>
                        <Typography className='Initialising'>Cancelling<span className="loader__dot">.</span><span className="loader__dot">.</span><span className="loader__dot">.</span></Typography>
                    </Stack>)
                    :
                    (<>
                        <DialogTitle
                            sx = {{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                height: "10px",
                                flexFLow: "row nowrap",
                                bgcolor: "#000",
                            }}
                        >
                            <Typography 
                                sx = {{
                                    fontStyle: "normal",
                                    fontWeight: 700,
                                    fontSize: "16px",
                                    lineHeight: "21px",
                                    color: "#FFF"
                                }}
                            >
                                Confirm
                            </Typography>
                            <IconButton onClick = {crossClose}><CrossIcon/></IconButton>
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText sx = {{align:"center",fontSize:"14px", pt:"10px", justifyContent:"center", textAlign:"center"}}>
                                {/* Cancelling back {props.count} {(props.count <= 1) ? "device" : "devices"} out of {props.totalSelected} selected {(props.totalSelected <= 1) ? "device" : "devices"}. Are you sure want to continue ? */}
                                Are you sure you want to cancel the scheduled upgrade for vehicles?
                            </DialogContentText>
                            <Box sx = {{border:1, height:"200px", mt:"20px", borderColor:"#E9ECEF", overflow:"auto", padding:"5px"}}>
                                {
                                    (cancelDeviceNameList.length !== 0) ?
                                        cancelDeviceNameList.map((item, index) => {
                                            console.log(item[0], " ", item[1]);
                                            return (<div style = {{marginLeft:"12px", mt:"5px", display:"flex", flexDirection:"row"}}>{<div className='Listdot'></div>}{`${item[0]} `}({item[1]})</div>)
                                        }):
                                        <Box className = 'emptyContent'>
                                            Empty...
                                        </Box>
                                }
                            </Box>
                        </DialogContent>
                        <DialogActions style = {{ justifyContent: "center" }} >
                            <Button variant='outlined' onClick={handleClose} sx = {whiteButtonStyle}>No</Button>
                            <Button disabled = {(cancelDeviceNameList.length < 1)?true:false} onClick={cancelAll} sx = {greenButtonStyle}>
                                <Typography
                                    sx = {{
                                        fontWeight: 400,
                                        fontSize:"14px",
                                        color:(cancelDeviceNameList.length === 0) ? "black" : "#FFFFFF"
                                    }}
                                >
                                    Yes
                                </Typography>
                            </Button>
                        </DialogActions>
                    </>)
                }
            </Dialog>
        </div>
    )
}

function FotaCancelAllButton(props) {
    const[isDisable, setIsDisable] = useState(props.isDisable);
    const [open, setOpen] = useState(false);
    
    //handling open close dialog first-one
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (value) => {
        setOpen(false);
    };  

    return (
        <div>
            <Button
                variant="contained" 
                onClick={handleClickOpen}
                disabled = {(props.totalSelected < 1) ? true : false}
                className = "upgradeButton"
                disableElevation
                sx = {{ 
                    width:"150px",
                    height:"36px",
                    borderRadius: "0px",
                    bgcolor: "#FFFFFF",
                    color: "black",
                    fontWeight: 400,
                    textTransform: "none",
                    "&:hover": {backgroundColor: "#FFFFFF", }
                }}
            >
                <Typography sx = {{
                    fontWeight: 400,
                    fontSize:"14px",
                    color:(props.totalSelected < 1) ? "#8E939E" : "black"
                }}>Cancel</Typography>
            </Button>
            <CancelAllButtonDialog
                open={open}
                onClose={handleClose}
                device_name = {props.device_name}
                device_state = {props.device_state}
                deviceList = {props.deviceList}
                count = {props.count}
                totalSelected = {props.totalSelected}
                device = {props.device}
                cancelDeviceNameList= {props.cancelDeviceNameList}
                disableBackdropClick
            />
        </div>    
    )
}

export default FotaCancelAllButton