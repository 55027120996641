import {
  Alert,
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  OutlinedInput,
  Slide,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";

//images
import LoginPageImage from "../../Assets/LoginBackgroundImage.png";
import Deloitte from "../../Assets/deloitte2.png";

import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import { Stack } from "@mui/system";
import axios from "axios";
import BouncyLoader from "./BouncyLoader";

import { baseUrl } from "../../constants";

function LoginPage() {
  let navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [notifyToastOpen, setNotifyToast] = useState(false);
  const [notifyToastMessage, setNotifyToastMessage] = useState("");
  const [toastType, setToastType] = useState("");

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const pathname = useLocation().pathname.split("/").pop() || "";
  // rendering current page
  useEffect(() => {
    // if (pathname === "") {
    //   navigate("/");
    // }
    
    // directly redirect to the home page
    handleSubmit()
    
  }, []);

  const getJwtToken = () => {
    let token = localStorage.getItem("token") || undefined;
    return token === undefined ? "" : `${JSON.parse(token)}`;
  };

  // useEffect(() => {
  //   if(getJwtToken() != ""){
  //       navigate("/dashboard/home")
  //   }
  // }, [])

  //input funtions
  const handleChange = (event) => {
    setPassword(event.target.value);
    // console.log(password);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    // console.log("email: " + email);
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  //Toast functions
  //Opening toast
  const handleToastOpen = (name, type) => {
    console.log(name);
    setNotifyToast(true);
    setNotifyToastMessage(name);
    setToastType(type);
  };
  //Closing toast
  const handleToastClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotifyToast(false);
  };
  //toast Animation
  function SlideTransition(props) {
    return <Slide {...props} direction="down" />;
  }

  let url = baseUrl + "/api/user/login/";
  const handleSubmit = async () => {
    console.log("clickedsss\n", `password: ${password}\n`, `->email: ${email}`);
    setLoading(true);

    // const emailregex =
    //   /[a-z0-9!#$%&'*+/=?^_‘{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_‘{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])/;
    // if (email === "" || !emailregex.test(email)) {
    //   handleToastOpen("Invalid Email", "warning");
    //   setLoading(false);
    //   return;
    // }
    // if (password === "") {
    //   handleToastOpen("Password is required", "warning");
    //   setLoading(false);
    //   return;
    // }

    await axios
      .post(baseUrl + "/api/user/login/", {
        // email: email,
        // password: password,
        email: "sandeep.sharma@gmail.com",
        password: "sandeep123",
      })
      .then((result) => {
        console.log(result);
        console.log("token: " + JSON.stringify(result.data.token.access));
        localStorage.setItem("token", JSON.stringify(result.data.token.access));
        localStorage.setItem("username", JSON.stringify(result.data.username));
        setLoading(false);
        navigate("/dashboard/home");
      })
      .catch((error) => {
        navigate("/dashboard/home")
        setLoading(false);
        handleToastOpen("Invalid credentials", "warning");
        console.log("Error");
      });
  };

  return (
    <div>
    
    
    </div>
  );
}

export default LoginPage;
