import React, { useState, useEffect } from "react";
import "./index.css";
import axios from "axios";
import Rotate from "./Rotate";
import { useLocation } from "react-router-dom";
import { baseSocketUrl, baseUrl } from "../../../constants";

var vinNumber,
  deviceonline = 0;

const VehicleIdentify = ({ handleData }) => {
  const [model, setModel] = useState([]);
  const [carDetails, setCarDetails] = useState(null);
  const [carModel, setCarModel] = useState("");
  const [carNumber, setCarNumber] = useState("TN 01 MT 4406");
  const [vehicleId, setVehicleId] = useState([]);
  const [distTravelled, setDistTravelled] = useState(0);
  const [insurance, setInsurance] = useState([]);
  const [insuranceDate, setInsuranceDate] = useState("");
  const [defaultModel, setDefaultModel] = useState("Select Model");
  const [vanNo, setVanNo] = useState("");
  const [newVin, setNewVin] = useState(null);
  const [carUrl, setCarUrl] = useState([]);
  const [idelTime, setIdelTime] = useState(0);
  const [serviceTime, setServiceTime] = useState(0);
  const [deviceStatus, setDeviceStatus] = useState(0);

  const location = useLocation();

  useEffect(() => {
    const res = location.pathname.slice(22);
    var val = "";
    for (var i = 0; i < res.length; i++) {
      if (res[i] === "/") break;
      val += res[i];
    }
    setVanNo(val);
    vinNumber = val;
    // console.log(val)
  });

  const getJwtToken = () => {
    let token = localStorage.getItem("token") || undefined;
    return token === undefined ? "" : `${JSON.parse(token)}`;
  };

  const url2 = baseUrl + "/get/vehicleData/";
  const user = {};

  useEffect(() => {
    const getDatafromSocket = () => {
      const viin = { vin: vanNo };
      vinNumber = vanNo;
      var socket = new WebSocket(baseSocketUrl);
      // console.log(socket)
      socket.onopen = () => {
        console.log("connection success");
        socket.send(JSON.stringify(viin));
      };
      socket.onmessage = function (event) {
        // console.log(event.data)
        const distData = JSON.parse(event.data);
        if (vinNumber === distData.vin) {
          //set distance

          if (vinNumber === "MALC181C2JM450208")
            setDistTravelled((distData.distancetravelled / 1000).toFixed(2));
          else setDistTravelled(distData.distancetravelled.toFixed(2));
          //sets dynamic ideltime and service time
          if (distData.idletime !== undefined) {
            const idt = distData.idletime;
            if (idt <= 60) setIdelTime(`${distData.idletime} sec`);
            else if (idt > 60 && idt <= 3600)
              setIdelTime(`${(idt / 60).toFixed(2)} min`);
            else if (idt <= 86400 && idt > 3600)
              setIdelTime(`${(idt / 3600).toFixed(2)} hour`);
            else if (idt <= 3153600 && idt > 86400)
              setIdelTime(`${(idt / 86400).toFixed(2)} day`);
            else setIdelTime(`${(idt / 3153600).toFixed(2)} year`);
          }
          if (distData.servicetime) {
            const sert = distData.servicetime;
            if (sert <= 60) setServiceTime(`${sert} sec`);
            else if (sert > 60 && sert <= 3600)
              setServiceTime(`${(sert / 60).toFixed(2)} min`);
            else if (sert <= 86400 && sert > 3600)
              setServiceTime(`${(sert / 3600).toFixed(2)} hour`);
            else if (sert <= 3153600 && sert > 86400)
              setServiceTime(`${(sert / 86400).toFixed(2)} day`);
            else setServiceTime(`${(sert / 3153600).toFixed(2)} year`);
          }

          //send data to previous component
          handleData(distData);
        }
      };
    };

    getDatafromSocket();
  }, [vanNo]);

  //live status view of cars
  const [carStatus, setCarStatus] = useState({});
  const [carStatusTemp, setCarStatusTemp] = useState({});
  useEffect(() => {
    const paramSocket = {
      country_id: null,
      state_id: null,
      city_id: null,
      vehiclemodel: null,
    };
    var onlineSocket = new WebSocket(baseSocketUrl + "/devicestate/");
    onlineSocket.onopen = () => {
      console.log("online status connected");
      onlineSocket.send(JSON.stringify(paramSocket));
    };
    onlineSocket.onmessage = function (event) {
      const datastatus = JSON.parse(event.data);
      if (vinNumber === vanNo) setDeviceStatus(datastatus[vinNumber]);
      // console.log(datastatus)
    };
  }, [vanNo]);

  // const fetchModelDetails = () => {
  //     if(defaultModel != "Select Model") {
  //         var van;
  //         for(var i = 0; i<carDetails.length; i++) {
  //             if(defaultModel === carDetails[i].vehicleModel) van = carDetails[i].vin
  //         }
  //         setVanNo(van);
  //         const temp = {
  //             vin: van,
  //             graph: 0,
  //             bolll: 0,
  //         }
  //         handleData(JSON.parse(JSON.stringify(temp)))

  //         if(vanNo!=""){
  //             const vinNo = {vin:vanNo}
  //             axios.post("http://Telematics-Backend-1314303004.us-east-1.elb.amazonaws.com/basicDetails/image/url/", vinNo, {headers : {Authorization : `Bearer ${getJwtToken()}`}}).then((res) => {
  //                 // console.log(res.data.url[0]);
  //                 setCarUrl(res.data.url);
  //             })
  //         }
  //     }
  // }

  // useEffect(() => {
  //     const fetchModel = async() => {
  //         await axios.post(url, user, {headers : {Authorization : `Bearer ${getJwtToken()}`}}).then((res) => {
  //             const temp = res.data.result;
  //             // console.log(temp)
  //             setCarDetails(temp);
  //             const mod = [];
  //             const insur = [];
  //             const caN= [];
  //             const vinss = [];
  //             for(var i=0; i<temp.length; i++) {
  //                 mod.push(temp[i].vehicleModel)
  //                 insur.push(temp[i].insuranceDate)
  //                 caN.push(temp[i].registration)
  //                 vinss.push(temp[i].vin)
  //             }
  //             setModel(mod)
  //             setInsurance(insur)
  //             setVehicleId(caN)
  //             if(localStorage.getItem('selectedModel') === undefined) {
  //                 localStorage.setItem('selectedModel', mod[0]);
  //                 setDefaultModel(mod[0]);
  //                 setCarNumber(caN[0])
  //                 localStorage.setItem('registrationno', caN[0]);
  //                 setInsuranceDate(insur[0]);
  //             }
  //             else {

  //                 setDefaultModel(localStorage.getItem('selectedModel'))
  //                 for(var i=0; i<temp.length; i++) {
  //                     if(temp[i].vehicleModel === localStorage.getItem('selectedModel')) {
  //                         setCarNumber(temp[i].registration)
  //                         localStorage.setItem('registrationno', temp[i].registration);
  //                     }

  //                 }
  //             }

  //         });
  //     }
  //     fetchModel();
  // }, [])

  const fetchModelInfo = async () => {
    if (vanNo !== "") {
      const vin = { vin: vanNo };
      console.log(vin);
      await axios
        .post(url2, vin, {
          headers: { Authorization: `Bearer ${getJwtToken()}` },
        })
        .then((res) => {
          const distData = res.data.result;
          setCarDetails(distData);
          handleData(distData);
          if (vinNumber === "MALC181C2JM450208")
            setDistTravelled((distData.distancetravelled / 1000).toFixed(2));
          else setDistTravelled(distData.distancetravelled.toFixed(2));
          const idt = distData.idleTime;
          const sert = distData.serviceTime;
          // console.log(idt + " " + sert);
          if (idt !== undefined) {
            if (idt <= 60) setIdelTime(`${idt} sec`);
            else if (idt > 60 && idt <= 3600)
              setIdelTime(`${(idt / 60).toFixed(2)} min`);
            else if (idt <= 86400 && idt > 3600)
              setIdelTime(`${(idt / 3600).toFixed(2)} hour`);
            else if (idt <= 3153600 && idt > 86400)
              setIdelTime(`${(idt / 86400).toFixed(2)} day`);
            else setIdelTime(`${(idt / 3153600).toFixed(2)} year`);
          }

          if (sert !== undefined) {
            if (sert <= 60) setServiceTime(`${sert} sec`);
            else if (sert > 60 && sert <= 3600)
              setServiceTime(`${(sert / 60).toFixed(2)} min`);
            else if (sert <= 86400 && sert > 3600)
              setServiceTime(`${(sert / 3600).toFixed(2)} hour`);
            else if (sert <= 3153600 && sert > 86400)
              setServiceTime(`${(sert / 86400).toFixed(2)} day`);
            else setServiceTime(`${(sert / 3153600).toFixed(2)} year`);
          }

          // setIdelTime(distData.idleTime)
          // setServiceTime(distData.serviceTime)
        });

      axios
        .post(baseUrl + "/basicDetails/image/url/new/", vin, {
          headers: { Authorization: `Bearer ${getJwtToken()}` },
        })
        .then((res) => {
          setCarUrl(res.data.url);
        });
    }
  };

  useEffect(() => {
    if (carDetails !== null) {
      setCarModel(carDetails.model_name);
      setCarNumber(carDetails.registeration_no);
    }
  }, [carDetails]);

  // const insuranceDatefunc = (item) => {
  //     var isu;
  //     for(var index = 0; index<carDetails.length; index++) {
  //         if(carDetails[index].vehicleModel == item) {
  //             isu = carDetails[index].insuranceDate;
  //             break;
  //         }
  //     }

  //     setInsuranceDate(isu);
  // }

  // const carNumberfunc = (item) => {
  //     var isu;
  //     var viin;
  //     for(var index = 0; index<carDetails.length; index++) {
  //         if(carDetails[index].vehicleModel == item) {
  //             isu = carDetails[index].registration;
  //             viin = carDetails[index].vin;
  //             break;
  //         }
  //     }

  //     setCarNumber(isu);
  // }

  // useEffect(() => {
  //     fetchModelDetails();
  // }, [defaultModel])

  useEffect(() => {
    fetchModelInfo();
    // console.log(vanNo)
    // console.log(vehicleId)
  }, [vanNo]);

  const [toggleModel, setToggleModel] = useState(false);
  return (
    <div className="vehicle_identification">
      <div
        className="mode_selector_main"
        onClick={() => {
          setToggleModel(!toggleModel);
        }}
      >
        <div className="vehicle_model">
          <div className="model_title">Model</div>
          <div className="vehicle_model_name_text">{carModel}</div>
        </div>
      </div>

      <div className="reg_number">Registration no:</div>

      <div className="vehicle_number">{carNumber}</div>

      <div className="online_status">
        {deviceStatus == 1 ? (
          <div className="device_status">
            <svg
              width="30"
              height="27"
              viewBox="0 0 45 31"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M34.1507 14.448C32.9509 8.38891 27.5958 3.81818 21.1733 3.81818C16.074 3.81818 11.6541 6.70681 9.43975 10.9253C4.13761 11.4977 0 15.9804 0 21.4318C0 27.2707 4.73752 32 10.5866 32H33.5244C38.3942 32 42.3466 28.0545 42.3466 23.1932C42.3466 18.5432 38.7206 14.7738 34.1507 14.448ZM33.5244 28.4772H10.5866C6.68723 28.4772 3.52888 25.3244 3.52888 21.4318C3.52888 17.5392 6.68723 14.3863 10.5866 14.3863H11.8394C12.9951 10.3264 16.7269 7.3409 21.1733 7.3409C26.5372 7.3409 30.8777 11.6739 30.8777 17.0284V17.9091H33.5244C36.4445 17.9091 38.8177 20.2781 38.8177 23.1932C38.8177 26.1082 36.4445 28.4772 33.5244 28.4772Z"
                fill="white"
              />
              <path
                d="M29.6263 12.3667L32.8388 15.4667L38.7284 8.75"
                stroke="#2E693D"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M44.6179 10.3333C44.6179 16.0403 39.8237 20.6667 33.9096 20.6667C27.9956 20.6667 23.2013 16.0403 23.2013 10.3333C23.2013 4.62639 27.9956 0 33.9096 0C39.8237 0 44.6179 4.62639 44.6179 10.3333ZM39.4803 8.40929C39.8444 7.99403 39.8029 7.36223 39.3877 6.99811C38.9724 6.63399 38.3406 6.67544 37.9765 7.09069L32.7784 13.0187L30.3207 10.6471C29.9233 10.2636 29.2902 10.2748 28.9067 10.6723C28.5232 11.0697 28.5345 11.7027 28.9319 12.0862L32.1444 15.1862C32.3412 15.3762 32.6072 15.4772 32.8805 15.4658C33.1537 15.4544 33.4104 15.3316 33.5907 15.1259L39.4803 8.40929Z"
                fill="white"
              />
            </svg>

            <div className="device_status_text">Online</div>
          </div>
        ) : (
          <div className="device_status">
            <svg
              width="30"
              height="27"
              viewBox="0 0 45 31"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M34.1507 14.448C32.9509 8.38891 27.5958 3.81818 21.1733 3.81818C16.074 3.81818 11.6541 6.70681 9.43975 10.9253C4.13761 11.4977 0 15.9804 0 21.4318C0 27.2707 4.73752 32 10.5866 32H33.5244C38.3942 32 42.3466 28.0545 42.3466 23.1932C42.3466 18.5432 38.7206 14.7738 34.1507 14.448ZM33.5244 28.4772H10.5866C6.68723 28.4772 3.52888 25.3244 3.52888 21.4318C3.52888 17.5392 6.68723 14.3863 10.5866 14.3863H11.8394C12.9951 10.3264 16.7269 7.3409 21.1733 7.3409C26.5372 7.3409 30.8777 11.6739 30.8777 17.0284V17.9091H33.5244C36.4445 17.9091 38.8177 20.2781 38.8177 23.1932C38.8177 26.1082 36.4445 28.4772 33.5244 28.4772Z"
                fill="white"
              />
              <path
                d="M34 0C27.9225 0 23 4.9225 23 11C23 17.0775 27.9225 22 34 22C40.0775 22 45 17.0775 45 11C45 4.9225 40.0775 0 34 0ZM39.5 14.9435L37.9435 16.5L34 12.5565L30.0565 16.5L28.5 14.9435L32.4435 11L28.5 7.0565L30.0565 5.5L34 9.4435L37.9435 5.5L39.5 7.0565L35.5565 11L39.5 14.9435Z"
                fill="white"
              />
              <path
                d="M30 5L28 7L32 11L28 15L30 17L34 13L38 17L40 15L36 11L40 7L38 5L34 9L30 5Z"
                fill="#97999B"
              />
            </svg>

            <div className="device_status_text">Offline</div>
          </div>
        )}
      </div>

      <div className="total_dist_head">Total Distance</div>
      <div className="dist_travelled">{distTravelled} km</div>

      {/* <div className="insurance_det">
                <div className="i_header">Insurance:</div>
                <div className="i_details">{insuranceDate}</div>
            </div> */}
      <div className="ideltime_det">
        <div className="i_header">Idle Time:</div>
        <div className="i_details">{idelTime}</div>
      </div>
      <div className="service_det">
        <div className="i_header">Service Time:</div>
        <div className="i_details">{serviceTime}</div>
      </div>

      {carUrl.length > 0 ? <Rotate carUrl={carUrl} vin={vanNo} /> : null}
    </div>
  );
};

export default VehicleIdentify;
