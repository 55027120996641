// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.initialReportPage{
    background: #F2F2F2;
    padding: 20px;
    width: calc(97.7vw - 240px - 60px);
    height: calc(100vh - 150px);
    overflow-y: scroll;
  }`, "",{"version":3,"sources":["webpack://./src/Components/Excelfore/Excelfore.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,aAAa;IACb,kCAAkC;IAClC,2BAA2B;IAC3B,kBAAkB;EACpB","sourcesContent":[".initialReportPage{\r\n    background: #F2F2F2;\r\n    padding: 20px;\r\n    width: calc(97.7vw - 240px - 60px);\r\n    height: calc(100vh - 150px);\r\n    overflow-y: scroll;\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
