import axios from 'axios'
import React, {useEffect, useState} from 'react'
import { useNavigate } from 'react-router-dom'
import VehicleIdentify from '../VehicleDashboard/VehicleIdentify'
import Graph from './Graph'
import Prediction from './Prediction'
import { baseUrl } from '../../../constants'

const Predictive = () => {
    const [vehicleData, setVehicleData] = useState([])
    const handleData = (arr) => {
      setVehicleData(arr);
    }

    let navigate = useNavigate();
    useEffect(() => {
      verifyCurrentUser()
    })

    const getJwtToken = () => {
        let token = localStorage.getItem("token") || undefined;
        return token === undefined ? "" : `${JSON.parse(token)}`;
    };

    const verifyCurrentUser = async() => {
        await axios.post((baseUrl+"/api/user/verify/"), 
                        null,
                        { headers: {Authorization : `Bearer ${getJwtToken()}`} } 
        ).then(result => {
            console.log(result);
            console.log("Correct User Loged-In")
            if(result.data.bool != 1){
                navigate("/login");
            }
        })
    }
  return (
    <div className='main_panel'>

            <div className='bottom_main_area'>
                <div className='bottom_main_left_area'>
                    <div className='dashboard_main_area'>
                        <VehicleIdentify handleData={handleData}/>
                        <div className='dashboard_right_side'>
                          <Prediction vehicleData={vehicleData}/>
                          <div>
                            <Graph/>
                          </div>
                        </div>
                    </div>
                </div>

            </div>
     
            

        </div>
  )
}

export default Predictive