import React, { useState, useEffect } from "react";
import "./index.css";
import Loader from "react-js-loader";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../../../constants";

const Rotate = () => {
  const pathname = useLocation().pathname.split("/");
  const [isLoading, setLoading] = useState(true);
  const [imageUrl, setImageUrl] = useState("");
  const [carIndex, setCarIndex] = useState(0);
  const [vin, setVin] = useState(null);
  const [carUrl, setCarUrl] = useState([]);

  const getJwtToken = () => {
    let token = localStorage.getItem("token") || undefined;
    return token === undefined ? "" : `${JSON.parse(token)}`;
  };

  useEffect(() => {
    setVin(pathname.at(4));
  }, [pathname]);

  useEffect(() => {
    setLoading(true);
    setCarIndex(0);
    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, 1800);

    return () => clearTimeout(timeoutId);
  }, [vin]);

  useEffect(() => {
    if (vin !== null) {
      const viin = { vin: vin };
      axios
        .post(baseUrl + "/basicDetails/image/url/new/", viin, {
          headers: { Authorization: `Bearer ${getJwtToken()}` },
        })
        .then((res) => {
          setCarUrl(res.data.url);
        });
    }
  }, [vin]);

  useEffect(() => {
    if (carUrl.length > 0) {
      const intervalId = setInterval(() => {
        setCarIndex((prevIndex) => (prevIndex + 1) % carUrl.length);
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [carUrl]);

  useEffect(() => {
    setImageUrl(carUrl[carIndex]);
  }, [carIndex, carUrl]);

  return (
    <div className="vehicle_rotate">
      {isLoading ? (
        <div style={{ marginTop: "35px" }}>
          <Loader
            type="spinner-default"
            bgColor={"#FFFFFF"}
            color={"#FFFFFF"}
            size={100}
          />
        </div>
      ) : (
        <div className="vehicle_group">
          {imageUrl && <img className="vehicle" alt="car" src={imageUrl} />}
        </div>
      )}
    </div>
  );
};

export default Rotate;
