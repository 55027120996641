import MuiTable from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";

function createData(name, calories, fat) {
  return { name, calories, fat };
}

const rows = [
  createData("Mileage", 25, 25),
  createData("Speed", 30, 40),
  createData("Fuel", 10, 9),
  createData("Windows", "Open", "Closed"),
];

export const renderTable = (data, type, onClick) => {
  switch (type) {
    case "GATEWAY":
      return (
        <MuiTable aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell style={styles.tableCell}>NAME</TableCell>
              <TableCell style={styles.tableCell}>VIN</TableCell>
              <TableCell style={styles.tableCell}>OS</TableCell>
              <TableCell style={styles.tableCell}>OS VERSION</TableCell>
              <TableCell style={styles.tableCell}>SERIAL NUMBER</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell style={styles.tableCellBody}>{data.NAME}</TableCell>
              <TableCell style={styles.tableCellBody}>{data.VIN}</TableCell>
              <TableCell style={styles.tableCellBody}>{data.OS}</TableCell>
              <TableCell style={styles.tableCellBody}>
                {data.OS_VERSION}
              </TableCell>
              <TableCell style={styles.tableCellBody}>
                {data.SERIAL_NUMBER}
              </TableCell>
            </TableRow>
          </TableBody>
        </MuiTable>
      );
    case "GATEWAY_COMPARE":
      let gatewayProperties = [
        "Gateway_Name",
        "Part_Number",
        "Software_Version",
        "Firmware_Version",
        "OS",
      ];
      return (
        <MuiTable aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell style={styles.tableCell}>Specification</TableCell>
              <TableCell style={styles.tableCell}>MCT</TableCell>
              <TableCell style={styles.tableCell}>PLM</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {gatewayProperties.map((property) => (
              <TableRow
                key={property}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell style={styles.tableCellBody}>
                  {property.replace(/_/g, " ")}
                </TableCell>
                <TableCell
                  style={
                    data[property][2]
                      ? styles.tableCellBodyGreen
                      : styles.tableCellBodyRed
                  }
                >
                  {data[property][0]}
                </TableCell>
                <TableCell
                  style={
                    data[property][2]
                      ? styles.tableCellBodyGreen
                      : styles.tableCellBodyRed
                  }
                >
                  {data[property][1]}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </MuiTable>
      );
    case "ECU":
      return (
        <MuiTable aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell style={styles.tableCell}>VIN</TableCell>
              <TableCell style={styles.tableCell}>ECU NAME</TableCell>
              <TableCell style={styles.tableCell}>ECU SW VERSION</TableCell>
              <TableCell style={styles.tableCell}>ECU OS</TableCell>
              <TableCell style={styles.tableCell}>ECU OS VERSION</TableCell>
              <TableCell style={styles.tableCell}>SERIAL NUMBER</TableCell>
              <TableCell style={styles.tableCell}>PERIPHERALS</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map(
              ({
                hardware_software: {
                  vin,
                  ECU_NAME,
                  ECU_SW_VERSION,
                  ECU_OS,
                  ECU_OS_VERSION,
                  SERIAL_NUMBER,
                },
                peripherals,
              }) => (
                <TableRow
                  key={vin}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell style={styles.tableCellBody}>{vin}</TableCell>
                  <TableCell style={styles.tableCellBody}>{ECU_NAME}</TableCell>
                  <TableCell style={styles.tableCellBody}>
                    {ECU_SW_VERSION}
                  </TableCell>
                  <TableCell style={styles.tableCellBody}>{ECU_OS}</TableCell>
                  <TableCell style={styles.tableCellBody}>
                    {ECU_OS_VERSION}
                  </TableCell>
                  <TableCell style={styles.tableCellBody}>
                    {SERIAL_NUMBER}
                  </TableCell>
                  <TableCell
                    style={styles.tableCellLink}
                    onClick={() =>
                      onClick({
                        peripherals,
                        hardware: {
                          vin,
                          ECU_NAME,
                        },
                      })
                    }
                  >
                    View
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </MuiTable>
      );
    case "ECU_COMPARE":
      return (
        <MuiTable aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell style={styles.tableCell}>ECU</TableCell>
              <TableCell style={styles.tableCell}>ECU NAME</TableCell>
              <TableCell style={styles.tableCell}>PART NUMBER</TableCell>
              <TableCell style={styles.tableCell}>OS VERSION</TableCell>
              <TableCell style={styles.tableCell}>FIRMWARE VERSION</TableCell>
              <TableCell style={styles.tableCell}>OS</TableCell>
              <TableCell style={styles.tableCell}>MORE INFO</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((obj) => {
              let objKey = Object.keys(obj)[0];
              const {
                Ecu_Name,
                Part_Number,
                Os_Version,
                Firmware_Version,
                OS,
              } = Object.values(obj)[0];
              return (
                <TableRow
                  key={objKey}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell style={styles.tableCellBody}>{objKey}</TableCell>
                  <TableCell
                    style={
                      Ecu_Name[2]
                        ? styles.tableCellBodyGreen
                        : styles.tableCellBodyRed
                    }
                  >
                    {Ecu_Name[0]}
                  </TableCell>
                  <TableCell
                    style={
                      Part_Number[2]
                        ? styles.tableCellBodyGreen
                        : styles.tableCellBodyRed
                    }
                  >
                    {Part_Number[0]}
                  </TableCell>
                  <TableCell
                    style={
                      Os_Version[2]
                        ? styles.tableCellBodyGreen
                        : styles.tableCellBodyRed
                    }
                  >
                    {Os_Version[0]}
                  </TableCell>
                  <TableCell
                    style={
                      Firmware_Version[2]
                        ? styles.tableCellBodyGreen
                        : styles.tableCellBodyRed
                    }
                  >
                    {Firmware_Version[0]}
                  </TableCell>
                  <TableCell
                    style={
                      OS[2]
                        ? styles.tableCellBodyGreen
                        : styles.tableCellBodyRed
                    }
                  >
                    {OS[0]}
                  </TableCell>
                  <TableCell
                    style={styles.tableCellLink}
                    onClick={() => {
                      onClick({
                        nameCount: objKey,
                        compareData: Object.values(obj)[0],
                      });
                    }}
                  >
                    <CompareArrowsIcon sx={{ color: "#5A83A0" }} />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </MuiTable>
      );
    case "ECU_MISSING":
      return (
        <MuiTable aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell style={styles.tableCell}>ECU</TableCell>
              <TableCell style={styles.tableCell}>ECU NAME</TableCell>
              <TableCell style={styles.tableCell}>PART NUMBER</TableCell>
              <TableCell style={styles.tableCell}>OS VERSION</TableCell>
              <TableCell style={styles.tableCell}>FIRMWARE VERSION</TableCell>
              <TableCell style={styles.tableCell}>OS</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((obj) => {
              let objKey = Object.keys(obj)[0];
              const {
                Ecu_Name,
                Part_Number,
                Os_Version,
                Firmware_Version,
                OS,
              } = Object.values(obj)[0];
              return (
                <TableRow
                  key={objKey}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell style={styles.tableCellBody}>{objKey}</TableCell>
                  <TableCell style={styles.tableCellBody}>
                    {Ecu_Name[0]}
                  </TableCell>
                  <TableCell style={styles.tableCellBody}>
                    {Part_Number[0]}
                  </TableCell>
                  <TableCell style={styles.tableCellBody}>
                    {Os_Version[0]}
                  </TableCell>
                  <TableCell style={styles.tableCellBody}>
                    {Firmware_Version[0]}
                  </TableCell>
                  <TableCell style={styles.tableCellBody}>
                    {OS[0]}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </MuiTable>
      );
    case "ECU_DEEP_COMPARE":
      return (
        <MuiTable aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell style={styles.tableCell}>SPECIFICATION</TableCell>
              <TableCell style={styles.tableCell}>MCT</TableCell>
              <TableCell style={styles.tableCell}>PLM</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(data).map((d) => (
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell style={styles.tableCellBody}>
                  {d[0].replace(/_/g, " ")}
                </TableCell>

                <TableCell
                  style={
                    d[1][2]
                      ? styles.tableCellBodyGreen
                      : styles.tableCellBodyRed
                  }
                >
                  {d[1][0]}
                </TableCell>
                <TableCell
                  style={
                    d[1][2]
                      ? styles.tableCellBodyGreen
                      : styles.tableCellBodyRed
                  }
                >
                  {d[1][1]}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </MuiTable>
      );
    case "PERIPHERAL_DEEP_COMPARE":
      return (
        <MuiTable aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell style={styles.tableCell}>SPECIFICATION</TableCell>
              <TableCell style={styles.tableCell}>MCT</TableCell>
              <TableCell style={styles.tableCell}>PLM</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(data).map((d) => (
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell style={styles.tableCellBody}>
                  {d[0].replace(/_/g, " ")}
                </TableCell>

                <TableCell
                  style={
                    d[1][2]
                      ? styles.tableCellBodyGreen
                      : styles.tableCellBodyRed
                  }
                >
                  {d[1][0]}
                </TableCell>
                <TableCell
                  style={
                    d[1][2]
                      ? styles.tableCellBodyGreen
                      : styles.tableCellBodyRed
                  }
                >
                  {d[1][1]}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </MuiTable>
      );
    case "PERIPHERAL":
      return (
        <MuiTable aria-label="simple table">
          <TableHead>
            <TableRow sx={styles.sticky}>
              <TableCell style={styles.tableCell}>ID_VENDOR</TableCell>
              <TableCell style={styles.tableCell}>ID_VENDOR_ID</TableCell>
              <TableCell style={styles.tableCell}>ID_MODEL</TableCell>
              <TableCell style={styles.tableCell}>ID_MODEL_ID</TableCell>
              <TableCell style={styles.tableCell}>ID_SERIAL</TableCell>
              <TableCell style={styles.tableCell}>ID_SERIAL_SHORT</TableCell>
              <TableCell style={styles.tableCell}>SUBSYSTEM</TableCell>
              <TableCell style={styles.tableCell}>DEVNAME</TableCell>
              <TableCell style={styles.tableCell}>DEVTYPE</TableCell>
              <TableCell style={styles.tableCell}>DEVNUM</TableCell>
              <TableCell style={styles.tableCell}>ID_PATH</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row) => (
              <TableRow
                key={row.ID_VENDOR}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell style={styles.tableCellBody}>
                  {row.ID_VENDOR}
                </TableCell>
                <TableCell style={styles.tableCellBody}>
                  {row.ID_VENDOR_ID}
                </TableCell>
                <TableCell style={styles.tableCellBody}>
                  {row.ID_MODEL}
                </TableCell>
                <TableCell style={styles.tableCellBody}>
                  {row.ID_MODEL_ID}
                </TableCell>
                <TableCell style={styles.tableCellBody}>
                  {row.ID_SERIAL}
                </TableCell>
                <TableCell style={styles.tableCellBody}>
                  {row.ID_SERIAL_SHORT}
                </TableCell>
                <TableCell style={styles.tableCellBody}>
                  {row.SUBSYSTEM}
                </TableCell>
                <TableCell style={styles.tableCellBody}>
                  {row.DEVNAME}
                </TableCell>
                <TableCell style={styles.tableCellBody}>
                  {row.DEVTYPE}
                </TableCell>
                <TableCell style={styles.tableCellBody}>{row.DEVNUM}</TableCell>
                <TableCell style={styles.tableCellBody}>
                  {row.ID_PATH}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </MuiTable>
      );
    case "PERIPHERAL_COMPARE":
      return (
        <MuiTable aria-label="simple table">
          <TableHead>
            <TableRow sx={styles.sticky}>
              <TableCell style={styles.tableCell}>PERIPHERAL</TableCell>
              <TableCell style={styles.tableCell}>PERIPHERAL NAME</TableCell>
              <TableCell style={styles.tableCell}>VENDOR ID</TableCell>
              <TableCell style={styles.tableCell}>DEVICE TYPE</TableCell>
              <TableCell style={styles.tableCell}>SUBSYSTEM</TableCell>
              <TableCell style={styles.tableCell}>MORE INFO</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row) => {
              let [key, value] = Object.entries(row)[0];
              const { Peripheral_Name, Vendor_Id, Device_Type, Subsystem } =
                value;
              return (
                <TableRow
                  key={key}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell style={styles.tableCellBody}>{key}</TableCell>
                  <TableCell
                    style={
                      Peripheral_Name[2]
                        ? styles.tableCellBodyGreen
                        : styles.tableCellBodyRed
                    }
                  >
                    {Peripheral_Name[0]}
                  </TableCell>
                  <TableCell
                    style={
                      Vendor_Id[2]
                        ? styles.tableCellBodyGreen
                        : styles.tableCellBodyRed
                    }
                  >
                    {Vendor_Id[0]}
                  </TableCell>
                  <TableCell
                    style={
                      Device_Type[2]
                        ? styles.tableCellBodyGreen
                        : styles.tableCellBodyRed
                    }
                  >
                    {Device_Type[0]}
                  </TableCell>
                  <TableCell
                    style={
                      Subsystem[2]
                        ? styles.tableCellBodyGreen
                        : styles.tableCellBodyRed
                    }
                  >
                    {Subsystem[0]}
                  </TableCell>
                  <TableCell
                    style={styles.tableCellLink}
                    onClick={() => {
                      onClick({
                        name: key,
                        compareData: value,
                      });
                    }}
                  >
                    <CompareArrowsIcon sx={{ color: "#5A83A0" }} />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </MuiTable>
      );

    case "PERIPHERAL_MISSING":
      return (
        <MuiTable aria-label="simple table">
          <TableHead>
            <TableRow sx={styles.sticky}>
              <TableCell style={styles.tableCell}>PERIPHERAL</TableCell>
              <TableCell style={styles.tableCell}>PERIPHERAL NAME</TableCell>
              <TableCell style={styles.tableCell}>VENDOR ID</TableCell>
              <TableCell style={styles.tableCell}>DEVICE TYPE</TableCell>
              <TableCell style={styles.tableCell}>SUBSYSTEM</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row) => {
              let [key, value] = Object.entries(row)[0];
              const { Peripheral_Name, Vendor_Id, Device_Type, Subsystem } =
                value;
              return (
                <TableRow
                  key={key}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell style={styles.tableCellBody}>{key}</TableCell>
                  <TableCell style={styles.tableCellBody}>
                    {Peripheral_Name[0]}
                  </TableCell>
                  <TableCell style={styles.tableCellBody}>
                    {Vendor_Id[0]}
                  </TableCell>
                  <TableCell style={styles.tableCellBody}>
                    {Device_Type[0]}
                  </TableCell>
                  <TableCell style={styles.tableCellBody}>
                    {Subsystem[0]}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </MuiTable>
      );

    default:
      break;
  }
};

const styles = {
  tableCell: { padding: "8px", fontWeight: "bold" },
  tableCellBody: { padding: "8px" },
  tableCellBodyGreen: { color: "green", padding: "8px" },
  tableCellBodyRed: { color: "red", padding: "8px" },

  tableCellLink: {
    padding: "8px",
    color: "#5A83A0",
    cursor: "pointer",
    fontWeight: "bold",
  },
  sticky: {
    background: "white",
    position: "sticky",
    top: 0,
  },
};
