import React, { useEffect, useRef, createRef} from 'react'
import './MainSideBar.css'
import { useState } from 'react'
import RenderState from './RenderState';
import RenderCountry from './RenderCountry';
import axios from 'axios';
import { baseUrl } from '../../constants';

function FleetManagement({handleData, val}) {
    
  const [fleetDetails, setFleetDetails] = useState([
    {
       "country_id": "IND",
       "country": "INDIA",
       "states": [
           {
               "state_id": "KA",
               "state": "Karnataka",
               "cities": [
                   {
                       "city_id": "BGL",
                       "city": "Bangalore",
                       "models": [
                           {
                               "model": "BMW",
                               "vin": "1A",
                               "registrationno": "KA 05 MT 4986"                            },
                           {
                               "model": "Mercedes",
                               "vin": "1B",
                               "registrationno": "KA 05 MT 4986"                            }
                       ]
                   },
                   {
                       "city_id": "MY",
                       "city": "Mysore",
                       "models": [
                           {
                               "model": "BMW",
                               "vin": "1C",
                               "registrationno": "KA 05 MT 4986"                            },
                           {
                               "model": "Mercedes",
                               "vin": "1D",
                               "registrationno": "KA 05 MT 4986"                            }
                       ]
                   }
               ]
           },
           {
               "state_id": "MH",
               "state": "Maharashtra",
               "cities": [
                   {
                       "city_id": "MB",
                       "city": "Mumbai",
                       "models": [
                           {
                               "model": "Porsche",
                               "vin": "1E",
                               "registrationno": "MH 05 MT 4986"                            },
                           {
                               "model": "Porsche",
                               "vin": "2B",
                               "registrationno": "MH 05 MT 4986"                            },
                           {
                               "model": "Audi",
                               "vin": "1F",
                               "registrationno": "MH 05 MT 4986"                            },
                           {
                               "model": "Mercedes",
                               "vin": "2A",
                               "registrationno": "MH 05 MT 4986"                            }
                       ]
                   },
                   {
                       "city_id": "PN",
                       "city": "Pune",
                       "models": [
                           {
                               "model": "Porsche",
                               "vin": "1G",
                               "registrationno": "MH 05 MT 4986"                            },
                           {
                               "model": "Audi",
                               "vin": "1H",
                               "registrationno": "MH 05 MT 4986"                            }
                       ]
                   }
               ]
           }
       ]
   },
   {
    "country_id": "PAK",
    "country": "INDIA",
    "states": [
        {
            "state_id": "KA",
            "state": "Karnataka",
            "cities": [
                {
                    "city_id": "BGL",
                    "city": "Bangalore",
                    "models": [
                        {
                            "model": "BMW",
                            "vin": "1A",
                            "registrationno": "KA 05 MT 4986"                            },
                        {
                            "model": "Mercedes",
                            "vin": "1B",
                            "registrationno": "KA 05 MT 4986"                            }
                    ]
                },
                {
                    "city_id": "MY",
                    "city": "Mysore",
                    "models": [
                        {
                            "model": "BMW",
                            "vin": "1C",
                            "registrationno": "KA 05 MT 4986"                            },
                        {
                            "model": "Mercedes",
                            "vin": "1D",
                            "registrationno": "KA 05 MT 4986"                            }
                    ]
                }
            ]
        },
        {
            "state_id": "MH",
            "state": "Maharashtra",
            "cities": [
                {
                    "city_id": "MB",
                    "city": "Mumbai",
                    "models": [
                        {
                            "model": "Porsche",
                            "vin": "1E",
                            "registrationno": "MH 05 MT 4986"                            },
                        {
                            "model": "Porsche",
                            "vin": "2B",
                            "registrationno": "MH 05 MT 4986"                            },
                        {
                            "model": "Audi",
                            "vin": "1F",
                            "registrationno": "MH 05 MT 4986"                            },
                        {
                            "model": "Mercedes",
                            "vin": "2A",
                            "registrationno": "MH 05 MT 4986"                            }
                    ]
                },
                {
                    "city_id": "PN",
                    "city": "Pune",
                    "models": [
                        {
                            "model": "Porsche",
                            "vin": "1G",
                            "registrationno": "MH 05 MT 4986"                            },
                        {
                            "model": "Audi",
                            "vin": "1H",
                            "registrationno": "MH 05 MT 4986"                            }
                    ]
                }
            ]
        }
    ]
}
 ]);

 const getJwtToken = () => {
    let token = localStorage.getItem("token") || undefined;
    return token === undefined ? "" : `${JSON.parse(token)}`;
};

    const url = baseUrl+"/get/navigationData/";
    
    useEffect(() => {
      const user = {"vehiclemodel":null}
      axios.post(url, user,{ headers: {Authorization : `Bearer ${getJwtToken()}`} }).then((res) => {
         // const temp = res.data.result;
         console.log(res.data)
         setFleetDetails(res.data)
      
         
     });    
    }, [val])

    const [cntry, setCntry] = useState(null);

    const selectedCountry = arr => {
        // console.log(arr);
        setCntry(arr);
    }
  


  return (
    <div className="fleet_manage">
      <div className='fleet_manage_title'>Regions</div>
      <div style={{marginTop: '1vh'}}>
        {
          fleetDetails.map((fleet, i) => {
            return (
              <RenderCountry fleet={fleet} handleData={handleData} selectedCountry={selectedCountry} cntry={cntry} val = {val}/>
            )
          })
        }
      </div> 
      
    </div>
  )
}

export default FleetManagement