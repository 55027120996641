import React,{useEffect, useRef, useState} from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import './MainSideBar.css'
import RenderState from './RenderState'

const RenderCountry = ({fleet, handleData, selectedCountry, cntry, val}) => {
    // console.log(cntry, fleet.country_id)

    const clicked = useRef();

    const [show, setShow] = useState(0);
    const [stat, setStat] = useState(null);

    const renderState = () => {
        if(!show) {
            selectedCountry(fleet.country_id)
        }
        setShow(!show);
        const data = {
            val: fleet.country,
            type: 'country',
            show: show,
            prev: '*',
            id: fleet.country_id,
            previd: -1
        }
        handleData(data)
        
    }

    const navigate = useNavigate();
    
    const pathname = useLocation().pathname.split("/");

    useEffect(() => {
        if(show == 1) {
            clicked.current.style.color ="#3174A4"
            clicked.current.style.fontWeight = "bold"
        }
        else {
            clicked.current.style.color ="black"
            clicked.current.style.fontWeight = "normal"
            if(pathname.at(3) === 'model') navigate('/dashboard/home/')
        }
    }, [show])

    useEffect(() => {
        if(cntry!==fleet.country_id) {
            setShow(0);
        }
    }, [cntry])

    //code to show 

    // useEffect(() => {
    //     if(val !== undefined) setShow(0);
    // },[val])

    const selectedState = arr => {
        setStat(arr);
    }
  return (
    <div>
        <div className='fleet_maange_state_level' onClick={() => renderState()} style={{cursor: "pointer"}}>
            <div className='fleet_manage_state_level_name' ref={clicked}>{fleet.country}</div>
            <div>
            {show?<svg width="9" height="10" viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_1553_1619)">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.49994 5.64088L7.08543 3.05539C7.47595 2.66486 8.02997 2.58572 8.32287 2.87861C8.61576 3.17151 8.53661 3.72552 8.14609 4.11605L5.31766 6.94448C5.07437 7.18777 4.76763 7.31021 4.5001 7.29713C4.2325 7.31031 3.92562 7.18787 3.68224 6.94449L0.85381 4.11606C0.463285 3.72554 0.384139 3.17152 0.677033 2.87863C0.969926 2.58573 1.52395 2.66488 1.91447 3.0554L4.49994 5.64088Z" fill="#3174A4"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_1553_1619">
                    <rect width="10" height="9" fill="white" transform="translate(9) rotate(90)"/>
                    </clipPath>
                    </defs>
                    </svg>:<svg width="6" height="8" viewBox="0 0 6 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M3.64088 4L1.05539 1.41451C0.664864 1.02399 0.585719 0.469966 0.878612 0.177073C1.17151 -0.115821 1.72552 -0.0366749 2.11605 0.353849L4.94448 3.18228C5.18777 3.42557 5.31021 3.73231 5.29713 3.99984C5.31031 4.26744 5.18787 4.57432 4.94449 4.8177L2.11606 7.64613C1.72554 8.03665 1.17152 8.1158 0.878626 7.82291C0.585733 7.53001 0.664878 6.97599 1.0554 6.58547L3.64088 4Z" fill="black" fill-opacity="0.85"/>
                </svg>
                    }
                
            </div>
        </div>
        <div>
            {
                fleet?.states.map((states) => {
                return (
                    show?<RenderState states={states} handleData={handleData} country={fleet.country} previd={fleet.country_id} selectedState={selectedState} stat={stat}/>:null
                )
                })
            }
        </div>

        
        </div> 
    )}

    export default RenderCountry;