import React ,{useEffect} from "react";
import Table from "../Table/Table";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import "./logTable.css"
import axios from "axios";
const LogsTable = ({ data,vin }) => {
  // const [vin, setPeripheralView] = React.useState("false");
  // const [ecu, setPeripheralData] = React.useState("");
  // const [application, setHardwareData] = React.useState("");
  // const [level, setEcuCompareView] = React.useState("");
  // const [message, setEcuNameCount] = React.useState("");
  // const [speed, setEcuCompareData] = React.useState("");
  // const [rpm, setPeripheralCompareView] = React.useState("");
  // const [timeStamp, setPeripheral] = React.useState("");
 
  const [logsData, setLogsData] = React.useState([data])
  
    console.log("Inside logs tbale",logsData)
    console.log("Inside logs tbale2",data)
    console.log("Vin Number",vin)
  
    const getLogs = async (vin) => {
     
      try {
        console.log("Vin",vin)
        const response = await axios.post(
          // baseUrl + "/get/scanning_feature/",
          "http://telematics-backend-895771443.us-east-1.elb.amazonaws.com/sdv/getDDLogs/",
          {
            vin,
          }
          // {
          //   headers: { Authorization: `Bearer ${getJwtToken()}` },
          // }
        );
        console.log("Calling through the interval",response);
        setLogsData(response.data);
      } catch (e) {
        console.log("getLogs Error", e);
      } finally {
        // setLogsLoading(false);
      }
    };

      // Refresh logs at a specific interval (e.g., every 60 seconds)
  useEffect(() => {
    const interval = setInterval(() => {
      // Call getLogs with a specific VIN (you might need to replace 'vin' with the actual VIN)
      // setLogsData([])
      getLogs(vin);
    }, 3000); // Refresh every 60 seconds

    return () => clearInterval(interval); // Clean up the interval on component unmount
  }, []); // Empty dependency array ensures this effect runs only once on mount

  return (
    <div>
     {/* {data.length > 0 ? ( */}
    
     {logsData.length !=0  ? (
         <table id="log">
      <thead>
        <tr>
            <th>Vin </th>
            <th>ECU name</th>
            <th>Application</th>
            {/* <th>Level</th> */}
            <th>Message</th>
            {/* <th>Speed</th>
            <th>RPM</th> */}
            <th>Time Stamp</th>
        </tr>
      </thead>
        <tbody>
      {[...data].reverse().
       filter(log => log["Message"] !== "ALERT_MESSAGE").map((log, index) => (
      //   <tr key={index}>
      //   {log.map((value, i) => (
      //     <td key={i}>{value}</td>
      //   ))}
      // </tr>
          <tr key={index}>
            <td>{log["Vin"]}</td>
            <td>{log["Ecu name"]}</td>
            <td>{log["Application"]}</td>
            {/* <td>{log["Level"]}</td> */}
            {/* <td>{log["Message"]}</td> */}
            <td style={{ color: log["Level"] === "Error" ? "red" : log["Level"] === "Warn" ? "yellow" : "inherit" }}>
      {log["Message"]}
    </td>
            {/* <td>{log["Speed"]}</td>
            <td>{log["Rpm"]}</td> */}
            <td>{log["Timestamp"]}</td>
          </tr>
          
        ))}

      </tbody>
      </table>
      ):(
        <p>No data available</p>
      )}
       </div>
     
  );
};

export default LogsTable;

const styles = {
  heading: {
    fontSize: "1rem",
    fontWeight: "bold",
    marginBottom: "24px",
    color: "#aeacac",
  },
  label: {
    fontSize: "0.875rem",
  },
};
