import React from 'react';
import {useState, useEffect} from 'react';
import './MainSideBar.css'
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import axios from 'axios';

import { useLocation } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';
import { TextField } from '@mui/material';

import { baseSocketUrl, baseUrl, IntervalAPI } from "../../constants"


function SubHeader({filterData,filterDataDate}) {
  const [model, setModel] = useState('');
  const [updateDate, setUpdateDate] = useState(null);
  const handleModel = (event) => {
    setModel(event.target.value);
    // filterData(model)
  };

  const [showLogo, setShowLogo] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();
  const [modelList, setModelList] = useState([]);
  const [currentDate,setCurrentDate] = useState("today");
  const handleDate = (event) => {
    setCurrentDate(event.target.value);
    if(event.target.value==="today"){
      setUpdateDate(null);
      filterDataDate(undefined);
    }
  };
  const [minDate,setMinDate] = useState(Date())
  const pathname = useLocation().pathname.split("/");

   // jwt token 
   const getJwtToken = () => { let token = localStorage.getItem("token") || undefined; return token === undefined ? "" : `${JSON.parse(token)}`; };
  
  

    //  car Model List 
    const url4 = baseUrl+"/get/vehiclemodels/";
    const body4 = {username: 'Jack'}
     useEffect(() => {
         const fetchModelList = async() => {
             await axios.get(url4, {headers : {Authorization : `Bearer ${getJwtToken()}`}}).then((res) => {
            const temp = res.data;
            setModelList(temp.vehicle_model_names);
            console.log(temp.vehicle_model_names);
            });
            }
         fetchModelList();      
     }, [])
  
  
  const subHeading = (pathname) => {
    switch (pathname) {
      case "softwareUpgrade":
        return "Software Upgrade"
      case "vehicle-scan":
        return "Vehicle Scan"
      case "ota":
        return "Esync OTA"
      default:
        return "Summary"
    }
  }
  return (
    <div className="subheader_dash">
      <div className='subheader_header_text'>{subHeading(pathname.at(2))}</div>
      {(pathname.at(2) === "home") ?<div className='subheader_right_row'>
        <div className='subheader_inputs' style={{width:"400px"}}>
        <div>View: </div>
        <div style={{
                        marginRight: showLogo === 1 ? '5.5vw': '0'
                    }}
                    className="toggle_homepage_mapview">
                     
                            <div 
                                onClick={() => {navigate("/dashboard/home")
                                setShowLogo(0);
                              }}
                                style={{
                                    backgroundColor: (pathname.at(2) === "home" && pathname.at(3) !== "mapview") ? '#3174A4': '',
                                    color:(pathname.at(2) === "home" && pathname.at(3) !== "mapview") ? 'white': ''
                                }}
                                className="dashboard_toggle">
                                <div className='subheader_logo'>
                                   {(pathname.at(2) === "home" && pathname.at(3) !== "mapview")?<svg width="14" height="14" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6.94487 1.5H1.50043V6.94445H6.94487V1.5Z" stroke="white" stroke-width="1.16667" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M15.4996 1.5H10.0551V6.94445H15.4996V1.5Z" stroke="white" stroke-width="1.16667" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M15.4996 10.0557H10.0551V15.5001H15.4996V10.0557Z" stroke="white" stroke-width="1.16667" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M6.94487 10.0557H1.50043V15.5001H6.94487V10.0557Z" stroke="white" stroke-width="1.16667" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                    :<svg width="14" height="14" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6.94493 1.5H1.50049V6.94445H6.94493V1.5Z" stroke="#828799" stroke-width="1.16667" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M15.4996 1.5H10.0552V6.94445H15.4996V1.5Z" stroke="#828799" stroke-width="1.16667" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M15.4996 10.0557H10.0552V15.5001H15.4996V10.0557Z" stroke="#828799" stroke-width="1.16667" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M6.94493 10.0557H1.50049V15.5001H6.94493V10.0557Z" stroke="#828799" stroke-width="1.16667" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>

                                  }

                                </div>
                                <div className="dashboardview_text">
                                    Dashboard
                                </div>
                            </div>
                            <div 
                                style={{
                                    backgroundColor: pathname.at(2) === "home" && pathname.at(3) === "mapview" ? '#3174A4': '',
                                    color:pathname.at(2) === "home" && pathname.at(3) === "mapview" ? 'white': ''
                                }}
                                className="mapview_toggle"
                                onClick={() => {navigate("/dashboard/home/mapview")
                                  setShowLogo(1);
                                }}>
                                <div className='subheader_logo'>
                                    {(pathname.at(2) === "home" && pathname.at(3) === "mapview")?<svg width="18" height="18" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <g clip-path="url(#clip0_369_18386)">
                                      <path d="M8 14.875L3 16.125V4.875L8 3.625" stroke="white" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                                      <path d="M13 17.375L8 14.875V3.625L13 6.125V17.375Z" stroke="white" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                                      <path d="M13 6.125L18 4.875V16.125L13 17.375" stroke="white" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                                      </g>
                                      <defs>
                                      <clipPath id="clip0_369_18386">
                                      <rect width="20" height="20" fill="white" transform="translate(0.5 0.5)"/>
                                      </clipPath>
                                      </defs>
                                      </svg>
                                    :<svg width="18" height="18" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <g clip-path="url(#clip0_474_16627)">
                                  <path d="M8 14.875L3 16.125V4.875L8 3.625" stroke="#828799" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                                  <path d="M13 17.375L8 14.875V3.625L13 6.125V17.375Z" stroke="#828799" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                                  <path d="M13 6.125L18 4.875V16.125L13 17.375" stroke="#828799" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                                  </g>
                                  <defs>
                                  <clipPath id="clip0_474_16627">
                                  <rect width="20" height="20" fill="white" transform="translate(0.5 0.5)" />
                                  </clipPath>
                                  </defs>
                                  </svg>


                                    }
                                </div>
                                <div className="mapview_text">
                                    Map view
                                </div>
                            </div>
                </div>

        </div>
        {/* <MenuItem value="Audi" onClick={()=>{filterData("Audi RS Q8")}}>Audi RS Q8</MenuItem> */}
        {
        // pathname.at(3) === 'model'?<div style={{display:"flex" }}>
        // <div style={{width:"399px"}}></div>
        // <div style={{width:"340px"}}></div>
        // </div>:<><div className='subheader_inputs'>
        //   <div>Model: </div><FormControl sx={{ minWidth: 199, minHeight:36 }}>
        //   <Select
        //       value={model}
        //       onChange={handleModel}
        //       displayEmpty={true}

        //       style={{ height: 40, fontFamily: 'Poppins',background:'white', borderRadius:"0"}}
        //     >
        //       {/* <MenuItem disabled="true" value="" >
        //         Select Modelz
        //       </MenuItem> */}
        //       <MenuItem  value='' onClick={()=>{filterData(null)}} >
        //         All Models
        //       </MenuItem>
        //       {modelList.map((item,index) => ( 
        //         <MenuItem value={item} onClick={()=>{filterData(item)}}>{item}</MenuItem>
        //       ))}
        //     </Select>
        //   </FormControl></div>
        // <div className='subheader_inputs'>
        //   <div>Date: </div>
        //   <div style={{display:"flex"}}>
        //   <FormControl sx={{ minWidth: 199, minHeight:36 }}>
        //   <Select
        //       value={currentDate}
        //       onChange={handleDate}
        //       displayEmpty
        //       style={{ height: 40, fontFamily: 'Poppins',background:'white',borderRadius:"0"}}
        //     >
        //       <MenuItem value="today">
        //         Today
        //       </MenuItem>
        //       <MenuItem  value="previous" >
        //         Previous
        //       </MenuItem>
        //     </Select>
        //   </FormControl>

        //   {currentDate==="previous"? <div style={{marginLeft:"20px",width:"140px"}}>
        //     <LocalizationProvider dateAdapter={AdapterDayjs}  >
        //       <MobileDateTimePicker 
        //         renderInput={(props) => <TextField size="small"  {...props} placeholder="MM-DD-YYYY" InputLabelProps={{shrink:true}}/>}
        //         className='update_date'
        //         label="Select Date"
        //         views={['day','hours']}
        //         value={updateDate}
        //         maxDate = {minDate}
        //         onChange={(newValue) => {
        //           filterDataDate(newValue["$d"].toString())
        //           // console.log(newValue["$d"].toString());
        //           setUpdateDate(newValue);
        //           // getTimePeriodData()
        //         }}
        //       />
        //   </LocalizationProvider></div>:
        //   <div style={{width:"160px"}}></div>
        // }
         
        //   </div>
        // </div></>
        }
      </div>:<div className='empty_subheader'></div>}
    </div>
  )
}

export default SubHeader