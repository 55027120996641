import React, { useState, useEffect } from "react";
import { baseSocketUrl, baseUrl, IntervalAPI ,EsyncSupported } from "../../constants";
import FotaUpgradeButton from "./FotaUpgradeButton";
import FotaCancelAllButton from "./FotaCancelAllButton";
import CircularProgress from "@mui/material/CircularProgress";
import "../SoftwareUpgrade/softwareUpgrade.css";
import axios from "axios";
import moment from "moment";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Pagination from "@mui/material/Pagination";
import { useLocation, useNavigate } from "react-router-dom";
// import CancelAllButton from './CancelAllButton'
// import NewUpgradeButton from './NewUpgradeButton';
// import loader from "../../Assets/loader.png";
import loader from "../../Assets/sandbox_vector.png";
import Chart from "react-apexcharts";
import { shadows } from "@mui/system";
import { Bar } from "react-chartjs-2";
import { Chart as ReactChart } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import FotaPublishComponentBtn from "./FotaPublishComponent";
ReactChart.register(ChartDataLabels);

var ccid = null,
  ssid = null,
  ccitid = null,
  ccmodel = null,
  ccHardware = null;

function FotaUpgrade({ navSelected }) {
  const pathnameList = useLocation().pathname.split("/");
  const modelTemp = pathnameList[3].replaceAll("%20", " ");
  const hardwareTemp = pathnameList[4].replaceAll("%20", " ");

  const [model, setModel] = useState((modelTemp === null || modelTemp === undefined) ? '' : localStorage.getItem('model')?  localStorage.getItem('model'):"Swift Drive X1");
  console.log("Fota page Model",model)
  
  const [modelList, setModelList] = useState([]);
  const [hardware, setHardware] = useState(hardwareTemp);
  
  console.log("Fota page Model 2",hardwareTemp)
  const [hardwareList, setHardwareList] = useState([]);
  const [pageApi, setPageApi] = useState(1);
  const [isLoaded, setIseLoaded] = useState(false);
  const [carList, setCarList] = useState([]);
  const [carStatus, setCarStatus] = useState({});
  const [carStatusTemp, setCarStatusTemp] = useState({});
  const [checkedState, setCheckedState] = useState(new Array(20).fill(false));
  const [checkedList, setcheckedList] = useState([]);
  const [newUpgardeList, setNewUpgradeList] = useState([]);
  const [upgardeList, setUpgardeList] = useState([]);
  const [cancelList, setCancelList] = useState([]);
  const [cancelDeviceList, setCancelDeviceList] = useState([]);
  const [checkedStateAll, setCheckedStateAll] = useState(false);
  const [scheduleDeviceList, setScheduleDeviceList] = useState([]);
  const [upgradeDeviceList, setUpgradeDeviceList] = useState([]);
  const [deviceUpgradeStats, setDeviceUpgradeStats] = useState([]);
  const [labelPie, setLabelPie] = useState([
    "Updated to latest version",
    "Needs software upgrade",
  ]);
  const [dataPie, setDataPie] = useState([0, 0]);
  const [colorsPie, setColorsPie] = useState(["#83B148", "#F75D5D", "#FFBF5E"]);
  const [totalLabel, setTotalLabel] = useState(0);
  const [totalVersionCount, setTotalVersionCount] = useState(1);
  // const [countryId,setCountyId]= useState(null);
  // const [stateId,setStateId]= useState(null);
  // const [cityId,setcityId]= useState(null);
 
  const config = {
    
    "VehicleModel": model != undefined ? model.replace(/ /g, "_"): "",
    "Hardware": hardware != undefined ? hardware.replace(" ", "_"):"",
    "Version": deviceUpgradeStats.latest_available_version ? deviceUpgradeStats.latest_available_version.replace("v", "") : null
    
  };
  // console.log("Fota:", config)
  const [uploadStatus, setUploadStatus] = useState(true);
  const [selection, setSelectList] = useState([]);
  const [statusList, setStatusList] = useState([]);

  const handleModel = (event) => {
    setModel(event.target.value);
    localStorage.setItem('model', event.target.value);
  };
  const handleHardware = (event) => {
    setHardware(event.target.value);
  };
  // rendering current page
useEffect(() => {
 handleSubmit()
  
}, []);
const handleSubmit = async () => {
   
  // setLoading(true);

  await axios
    .post(baseUrl + "/api/user/login/", {
      // email: email,
      // password: password,
      email: "sandeep.sharma@gmail.com",
      password: "sandeep123",
    })
    .then((result) => {
      localStorage.setItem("token", JSON.stringify(result.data.token.access));
      localStorage.setItem("username", JSON.stringify(result.data.username));
      // setLoading(false);
      // navigate("/dashboard/home");
    })
    .catch((error) => {
      // navigate("/dashboard/home")
      // setLoading(false);
      // handleToastOpen("Invalid credentials", "warning");
      console.log("Error");
    });
};

  let navigate = useNavigate();

  // jwt token
  const getJwtToken = () => {
    let token = localStorage.getItem("token") || undefined;
    return token === undefined ? handleSubmit() : `${JSON.parse(token)}`;
  };

  const fetchDeviceStatus = async () => {
    await axios
      .post(baseUrl + "/fota/getDeviceStatus/", statusList, {
        headers: { Authorization: `Bearer ${getJwtToken()}` },
      })
      .then((res) => {
        console.log("Esync Status:", res.data)
      }).catch((err) => {
        console.log("Error inside fetchDeviceStatus");
        console.log(err)
      });
  }

  useEffect(() => {
    console.log("test:",carList)
    var carListTemp = carList;
    var listObj = []
    carListTemp.map((carListItem, index) => {
      if (carStatusTemp !== undefined) {
        let obj = {
          "vin": carListItem["vin"],
          "campaign": carListItem["assigned_jobid"] == "" ? null : carListItem["assigned_jobid"],
          "currentVersion":carListItem["currentversion"],
          "latestVersion":config["Version"]
        }
        if (obj["campaign"] != null) {
          console.log("push")
          listObj.push(obj)
        }
      }
    })   
    
    setStatusList(listObj);  
    
  }, [carList]);

  useEffect(()=>{
    if (statusList.length > 0){
      const timer = setInterval(() => {      
          console.log("list:",statusList)
          fetchDeviceStatus();     
          }, 10 * 1000);
      return () => {
            clearInterval(timer);
          };
      }
  },[statusList]);

  //  car Model List
  const url4 = baseUrl + "/get/vehiclemodels/";
  const body4 = {};
  useEffect(() => {
    const fetchModelList = async () => {
      await axios
        .get(url4, {
          headers: { Authorization: `Bearer ${getJwtToken()}` },
        })
        .then((res) => {
          const temp = res.data;
          setModelList(temp.vehicle_model_names);
        });
    };
    fetchModelList();
  }, []);

  useEffect(() => {
    let version = deviceUpgradeStats.latest_available_version ? deviceUpgradeStats.latest_available_version.replace("v", "") : null;
    const fetchUploadStatus = async () => {
      await axios
        .post(baseUrl + "/fota/getComponentVersionStatus/", { "Version": version }, {
          headers: { Authorization: `Bearer ${getJwtToken()}` },
        })
        .then((res) => {
          setUploadStatus(res.data)
        });
    };
    if (version != null) {
      fetchUploadStatus();
    }
  }, [deviceUpgradeStats]);

  //  Hardware List
  const url5 = baseUrl + "/get/hardwaretypes/";
  const hardwareBody = { vehiclemodel: model };
  useEffect(() => {
    const fetchHardwareList = async () => {
      await axios
        .post(url5, hardwareBody, {
          headers: { Authorization: `Bearer ${getJwtToken()}` },
        })
        .then((result) => {
          let flag = false;
          let arr = [];
          result.data.gateways.map((item, index) => {
            if(item[2]==EsyncSupported){
            arr.push(item[0]);
            

            if (item[0] === hardware)
              flag = true;
            }
          })

          result.data.clients.map((item, index) => {
            if(item[2]==EsyncSupported){
            arr.push(item[0]);

            if (item[0] === hardware)
              flag = true;
            }
          })

          if (flag === false)
            setHardware(arr[0]);

          setHardwareList(arr);
        });
    };
    fetchHardwareList();
  }, [model]);

  // Device Update stats
  useEffect(() => {
    const updatedCheckedAllState = checkedState.map((item) => false);
    setCheckedState(updatedCheckedAllState);
    setcheckedList([]);
    setCancelList([]);
    setUpgardeList([]);
    setNewUpgradeList([]);
    setScheduleDeviceList([]);
    setUpgradeDeviceList([]);
    setCancelDeviceList([]);
    setCheckedStateAll(false);

    var cid = null;
    var sid = null;
    var citid = null;
    var cmodel = null;
    var cHardware = hardware;
    if (model === "" || model === undefined) {
      cmodel = null;
      ccmodel = null;
    } else {
      cmodel = model;
      ccmodel = model;
    }

    // country or city or state are selected

    if (navSelected !== undefined && navSelected !== null) {
      // console.log(navSelected)
      if (navSelected.type === "country") {
        cid = !navSelected.show ? navSelected.id : null;
      } else if (navSelected.type == "state") {
        if (!navSelected.show) {
          cid = navSelected.prevcountryid;
          sid = navSelected.id;
        } else {
          cid = navSelected.prevcountryid;
        }
      } else {
        if (!navSelected.show) {
          cid = navSelected.prevcountryid;
          sid = navSelected.prevstateid;
          citid = navSelected.id;
        } else {
          cid = navSelected.prevcountryid;
          sid = navSelected.prevstateid;
        }
      }
      // if(model===''){
      //   cmodel=null
      // }else{
      //   cmodel=model
      // }

     
      const param = {
        model: cmodel,
        country_id: cid,
        state_id: sid,
        city_id: citid,
        hardware: cHardware,
      };
      // const url2 = baseUrl + "/get/softwareUpdate/details/";
      const url2 = baseUrl + "/get/softwareUpdateDetails/";

      // graph side data api call (stats)
      const fetchDeviceStats = async () => {
        await axios
          .post(url2, param, {
            headers: { Authorization: `Bearer ${getJwtToken()}` },
          })
          .then((res) => {
            const temp2 = res.data.response;
            setDeviceUpgradeStats(temp2);
          })
          .catch((error) => {
            handleSubmit()
            console.log("Error inside fetchDeviceStats");
            console.log(error);
            setDeviceUpgradeStats({
              total_vehicles: 0,
              needs_upgrade: 0,
              running_latest_version: 0,
              latest_available_version: "-",
              release_date: "-",
              version_count: {},
            });
          });
      };
      // fetchDeviceStats();

      //  main list view
      // const url = baseUrl + "/get/softwareUpdate/listview/";
      const url = baseUrl + "/get/softwareUpdateListview/";
      const body = {
        pageApi: pageApi,
        model: cmodel,
        country_id: cid,
        state_id: sid,
        city_id: citid,
        hardware: cHardware
      };
      setIseLoaded(false);
      const fetchDeviceList = async () => {
        await axios
          .post(url, body, {
            headers: { Authorization: `Bearer ${getJwtToken()}` },
          })
          .then((res) => {
            setIseLoaded(true);
            const temp = res.data;
            // console.log(searchInput)
            setCarList(temp);
            //   if(res.data.bool != 1){
            //     navigate("/login");
            // }
          })
          .catch((error) => {
            handleSubmit()
          });
      };
      if(hardware != undefined){
        fetchDeviceStats();
        fetchDeviceList();
      }
      else{
        setDeviceUpgradeStats({
          total_vehicles: 0,
          needs_upgrade: 0,
          running_latest_version: 0,
          latest_available_version: "-",
          release_date: "-",
          version_count: {},
        });
      }

      const paramSocket = {
        country_id: cid,
        state_id: sid,
        city_id: citid,
        vehiclemodel: cmodel,
        hardware: cHardware
      };

      //  data from socket
      const getDatafromSocket = () => {
        var socket = new WebSocket(baseSocketUrl + "/devicestate/");
        socket.onopen = () => {
          console.log("connection success for Car Online Status");
          socket.send(JSON.stringify(paramSocket));
        };
        socket.onmessage = function (event) {
          setCarStatus(JSON.parse(event.data));
          if (carStatus !== carStatusTemp) {
            setCarStatusTemp(JSON.parse(event.data));
          }
        };
      };

      getDatafromSocket();

      ccid = cid;
      ssid = sid;
      ccitid = citid;
      ccHardware = cHardware;

      const paramSocketVersion = {
        country_id: cid,
        vehiclemodel: cmodel,
        country_id: cid,
        state_id: sid,
        city_id: citid,
        hardware: cHardware
      };

      //  data from socket
      const getDatafromSocketVersion = () => {
        var socket = new WebSocket(baseSocketUrl + "/upgrade/");
        socket.onopen = () => {
          console.log("connection success for Car stats");
          socket.send(JSON.stringify(paramSocketVersion));
        };
        socket.onmessage = function (event) {
          if (
            ccid === cid &&
            ssid === sid &&
            ccitid === citid &&
            ccmodel === cmodel &&
            ccHardware === cHardware
          ) {
            if (event.data !== "Connection Successful.") {
              setDeviceUpgradeStats(JSON.parse(event.data));
            }
          }
        };
      };

      getDatafromSocketVersion();
    } else {
      // if city country state nothing salected

      const param = {
        model: cmodel,
        country_id: cid,
        state_id: sid,
        city_id: citid,
        hardware: cHardware
      };
      // const url2 = baseUrl + "/get/softwareUpdate/details/";
      const url2 = baseUrl + "/get/softwareUpdateDetails/";

      const fetchDeviceStats = async () => {
        await axios
          .post(url2, param, {
            headers: { Authorization: `Bearer ${getJwtToken()}` },
          })
          .then((res) => {
            const temp2 = res.data.response;
            setDeviceUpgradeStats(temp2);
          })
          .catch((err)=>{
            console.log("Error inside fetchDeviceStats1")
            console.log("err");
            handleSubmit()
          });
      };
      // fetchDeviceStats();

      // const url = baseUrl + "/get/softwareUpdate/listview/";
      const url = baseUrl + "/get/softwareUpdateListview/"
      const body = {
        pageApi: pageApi,
        model: cmodel,
        country_id: cid,
        state_id: sid,
        city_id: citid,
        hardware: cHardware
      };
      setIseLoaded(false);
      const fetchDeviceList = async () => {
        await axios
          .post(url, body, {
            headers: { Authorization: `Bearer ${getJwtToken()}` },
          })
          .then((res) => {
            setIseLoaded(true);
            const temp = res.data;
            // console.log(searchInput)
            setCarList(temp);
            //   if(res.data.bool != 1){
            //     navigate("/login");
            // }
          })
          .catch((error) => {
            handleSubmit()
          });
      };
      if(hardware != undefined){
        fetchDeviceStats();
        fetchDeviceList();
      }
      else{
        setDeviceUpgradeStats({
          total_vehicles: 0,
          needs_upgrade: 0,
          running_latest_version: 0,
          latest_available_version: "-",
          release_date: "-",
          version_count: {},
        });
      }

      // socket data

      const paramSocket = {
        country_id: cid,
        hardware: cHardware
      };

      //  data from socket
      const getDatafromSocket = () => {
        var socket = new WebSocket(baseSocketUrl + "/devicestate/");
        socket.onopen = () => {
          console.log("connection success for Car Online Status");
          socket.send(JSON.stringify(paramSocket));
        };
        socket.onmessage = function (event) {
          setCarStatus(JSON.parse(event.data));
          if (carStatus !== carStatusTemp) {
            setCarStatusTemp(JSON.parse(event.data));
          }
        };
      };

      getDatafromSocket();

      ccmodel = model;
      ccHardware = hardware;
      const paramSocketVersion = {
        vehiclemodel: model,
        hardware: cHardware
      };

      console.log(ccmodel, model);
      //  data from socket
      const getDatafromSocketVersion = () => {
        var socket = new WebSocket(baseSocketUrl + "/upgrade/");
        socket.onopen = () => {
          console.log("connection success for Car stats else block");
          socket.send(JSON.stringify(paramSocketVersion));
        };
        socket.onmessage = function (event) {
          if (ccmodel === model && ccid === cid && ssid === sid && ccHardware === hardware) {
            if (event.data !== "Connection Successful.") {
              console.log("Upagrade:",event.data)
              setDeviceUpgradeStats(JSON.parse(event.data));
            }
          }
        };
      };

      getDatafromSocketVersion();
    }
  
  }, [navSelected, model, pageApi, hardware]);

  useEffect(() => {
    if (model === null || model === "") {
      // all models part
      if (deviceUpgradeStats.running_latest_version !== undefined) {
        setDataPie([
          deviceUpgradeStats.running_latest_version,
          deviceUpgradeStats.needs_upgrade,
        ]);
        setLabelPie(["Updated to latest version", "Needs software upgrade"]);
        setColorsPie(["#83B148", "#F75D5D"]);
        setTotalLabel(
          (
            (100 * deviceUpgradeStats.running_latest_version) /
            (deviceUpgradeStats.running_latest_version +
              deviceUpgradeStats.needs_upgrade)
          ).toFixed(2)
        );
      } else {
        setDataPie([0, 0]);
        setLabelPie(["Updated to latest version", "Needs software upgrade"]);
        setColorsPie(["#83B148", "#F75D5D"]);
        setTotalLabel(0);
      }
    } else {
      // setDataPie([])
      // setLabelPie([])
      var labelPieTemp = [];
      var dataPieTemp = [];
      var totalLabelTemp = [];
      var colorsPieTemp = ["#83B148", "#FFBF5E", "#F75D5D", "grey", "grey", "grey", "grey", "grey"];
      var total = 0;
      var firstItem = 0;
      var totalVersion = 0;
      var deviceUpgradeStatstemp = deviceUpgradeStats.version_count;

      if (deviceUpgradeStatstemp !== undefined) {
        Object.keys(deviceUpgradeStatstemp).map((item, index) => {
          if (item === deviceUpgradeStats.latest_available_version) {
            firstItem = deviceUpgradeStats.version_count[item];
          }
          totalVersion = totalVersion + 1;
          labelPieTemp.push(item);
          dataPieTemp.push(deviceUpgradeStats.version_count[item]);
          total = total + deviceUpgradeStats.version_count[item];
        });
        setTotalVersionCount(total);
        //  if(totalVersion==1){
        //   labelPieTemp.push("v1.1.0")
        //  }
      } else {
        dataPieTemp = [0, 1];
        labelPieTemp = ["Updated to latest version", "Needs software upgrade"];
        totalLabelTemp = 0;
        total = 1;
        firstItem = 0;
        colorsPieTemp = [
          "rgba(232, 225, 225, 0.4)",
          "rgba(232, 225, 225, 0.4)",
        ];
      }
      setTotalLabel(((100 * firstItem) / total).toFixed(2));
      setColorsPie(colorsPieTemp);
      setLabelPie(labelPieTemp);
      setDataPie(dataPieTemp);
    }
  }, [deviceUpgradeStats, navSelected, model, hardware]);

  // pie chart data

  //  Live Car Status
  //   useEffect(() => {
  //      var socket = new WebSocket(baseSocketUrl+'/devicestate/');
  //      socket.onopen = () => {
  //          console.log("connection success for Car Online Status")
  //      }
  //      socket.onmessage = function(event) {
  //          setCarStatus(JSON.parse(event.data))
  //          if(carStatus!==carStatusTemp){
  //              setCarStatusTemp(JSON.parse(event.data))
  //          }
  //      }
  //  }, [])

  // online offline status
  useEffect(() => {
    var carListTemp = carList;
    carListTemp.map((carListItem, index) => {
      if (carStatusTemp !== undefined) {
        Object.keys(carStatusTemp).map((vinItem) => {
          if (vinItem === carListItem["vin"]) {
            if (carStatusTemp[vinItem] === 1 && carListTemp[index]["devicestate"] === "Offline") {
              carListTemp[index]["devicestate"] = "Online";

            }
            else if (carStatusTemp[vinItem] === 0 && carListTemp[index]["devicestate"] === "Online") {
              carListTemp[index]["devicestate"] = "Offline";
              setCarList(carListTemp);
            }
          }
        });
      }
    });

  }, [carStatusTemp]);

  var intervalID = null;
  /* 
  useEffect(() => {
    //closeing current existing interval by thier ID
    clearInterval(intervalID);
    startInterval(intervalID);
    //starting new interval with new ID
    intervalID = setInterval(() => {
      startInterval(intervalID);
    }, 30 * 1000);

    return () => clearInterval(intervalID);
  }, [carList]);
  
  function startInterval(intervalID) {
    //request list
    let updateDeviceNameList = [];

    //initializing body
    carList.map((item, index) => {
      updateDeviceNameList.push(item["devicename"]);
    });

    //final body devices list

    //API calling
    // axios
    //   .post(IntervalAPI, {
    //     devices: updateDeviceNameList,
    //   })
    //   .then((result) => {
    //     console.log(result);
    //   }).catch(e => console.log("Error inside startInterval",e));
  }
  */

  // checkbox
  const handleOnChangeAllCheckbox = () => {
    if (checkedStateAll) {
      const updatedCheckedAllState = checkedState.map((item) => false);
      setCheckedState(updatedCheckedAllState);
      setcheckedList([]);
      setCancelList([]);
      setUpgardeList([]);
      setNewUpgradeList([]);
      setScheduleDeviceList([]);
      setUpgradeDeviceList([]);
      setCancelDeviceList([]);
    } else {
      const updatedCheckedAllState = checkedState.map((item, index) => {
        if (index >= (pageApi - 1) * 8 && index < pageApi * 8) {
          return true;
        } else {
          return false;
        }
      });
      setCheckedState(updatedCheckedAllState);
      let checkedListTemp = [];
      let selected = []
      let upgradeListTemp = [];
      let cancelListTemp = [];
      let newUpgradeListTemp = [];
      let scheduleDeviceListTemp = [];
      let upgradeDeviceListTemp = [];
      let cancelDeviceListTemp = [];
      carList.map((item, index) => {
        var obj = {};
        obj[item["devicename"]] = item["devicestate"].toLowerCase();
        selected.push({
          "vin":item["vin"],
          "currentVersion":item["currentVersion"]
        });
        checkedListTemp.push(obj);
        setcheckedList(checkedListTemp);

        var lis = [item["registrationno"], item["vehiclemodel"]];

        if (item["status"] === "Needs Upgrade") {
          upgradeListTemp.push(obj);
          scheduleDeviceListTemp.push(lis);
          if (item["devicestate"] === "Online") {
            newUpgradeListTemp.push(obj);
            upgradeDeviceListTemp.push(lis);
          }
        }
        if (
          item["status"] === "In Progress" ||
          item["status"] === "Upgrade Scheduled" ||
          item["status"] === "Queued"
        ) {
          var cancelObj = {};
          cancelObj[item["devicename"]] = item["assigned_jobid"];
          cancelListTemp.push(cancelObj);
          cancelDeviceListTemp.push(lis);
        }
      });
      setSelectList(selected);
      setCancelList(cancelListTemp);
      setUpgardeList(upgradeListTemp);
      setcheckedList(checkedListTemp);
      setNewUpgradeList(newUpgradeListTemp);
      setScheduleDeviceList(scheduleDeviceListTemp);
      setUpgradeDeviceList(upgradeDeviceListTemp);
      setCancelDeviceList(cancelDeviceListTemp);
    }
    setCheckedStateAll(!checkedStateAll);
  };

  const handleOnChangeCheckbox = (position, devicename, vin,currentversion) => {
    const positionTemp = position;
    position = position + (pageApi - 1) * 8;
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    );
    updatedCheckedState.map((item, index) => {
      if ((index === position) & updatedCheckedState[position]) {
        let selectedTemp = selection
        var obj = {};
        obj[carList[positionTemp]["devicename"]] =
          carList[positionTemp]["devicestate"].toLowerCase();
        var lis = [
          carList[positionTemp]["registrationno"],
          carList[positionTemp]["vehiclemodel"],
        ];
        selectedTemp.push({
          "vin":vin,
          "currentVersion":currentversion
        })
        setSelectList(selectedTemp);
        if (checkedList.length < 15) {
          let checkedListTemp = checkedList;
          checkedListTemp.push(obj);
          setcheckedList(checkedListTemp);
          if (carList[positionTemp]["status"] === "Needs Upgrade") {
            let upgradeListTemp = upgardeList;
            let newUpgradeListTemp = newUpgardeList;
            let scheduleDeviceListTemp = scheduleDeviceList;
            let upgradeDeviceListTemp = upgradeDeviceList;

            upgradeListTemp.push(obj);
            scheduleDeviceListTemp.push(lis);
            setScheduleDeviceList(scheduleDeviceListTemp);
            setUpgardeList(upgradeListTemp);
            if (carList[positionTemp]["devicestate"] === "Online") {
              newUpgradeListTemp.push(obj);
              setNewUpgradeList(newUpgradeListTemp);
              upgradeDeviceListTemp.push(lis);
              setUpgradeDeviceList(upgradeDeviceListTemp);
            }
          }
          if (
            carList[positionTemp]["status"] === "In Progress" ||
            carList[positionTemp]["status"] === "scheduled" ||
            carList[positionTemp]["status"] === "Queued"
          ) {
            var cancelObj = {};
            cancelObj[carList[positionTemp]["devicename"]] =
              carList[positionTemp]["assigned_jobid"];
            let cancelListTemp = cancelList;
            cancelListTemp.push(cancelObj);
            setCancelList(cancelListTemp);
            let cancelDeviceListTemp = cancelDeviceList;
            cancelDeviceListTemp.push(lis);
            setCancelDeviceList(cancelDeviceListTemp);
          }
        } else {
          alert("Select 15 or less items");
        }
      } else if (index === position) {
        const filtercheckedList = checkedList.filter(function (item) {
          if (Object.keys(item)[0] != devicename) {
            return item;
          }
        });
        const filterupgradeList = upgardeList.filter(function (item) {
          if (Object.keys(item)[0] != devicename) {
            return item;
          }
        });
        const filtercancelList = cancelList.filter(function (item) {
          if (Object.keys(item)[0] != devicename) {
            return item;
          }
        });
        const filterNewUpgradeList = newUpgardeList.filter(function (item) {
          if (Object.keys(item)[0] != devicename) {
            return item;
          }
        });

        const filterUpgradeDeviceList = upgradeDeviceList.filter(
          (item) => item[0] != vin
        );
        const filterScheduleDeviceList = scheduleDeviceList.filter(
          (item) => item[0] != vin
        );
        const filterCancelDeviceList = cancelDeviceList.filter(
          (item) => item[0] != vin
        );

        setCancelList(filtercancelList);
        setUpgardeList(filterupgradeList);
        setcheckedList(filtercheckedList);
        setSelectList(filtercheckedList);
        setNewUpgradeList(filterNewUpgradeList);
        setScheduleDeviceList(filterScheduleDeviceList);
        setUpgradeDeviceList(filterUpgradeDeviceList);
        setCancelDeviceList(filterCancelDeviceList);
      }
    });
    setCheckedState(updatedCheckedState);
    if (checkedList.length === carList.length) {
      setCheckedStateAll(true);
    } else {
      setCheckedStateAll(false);
    }
  };

  // Device Upgrade Status
  const [upgradeStatus, setUpgradeStatus] = useState({});

  useEffect(() => {
    var socket = new WebSocket(baseSocketUrl + "/fota/");
    socket.onopen = () => {
      console.log("connection success for upgrade status");
    };
    socket.onmessage = function (event) {
      setUpgradeStatus(JSON.parse(event.data));
    };
  }, []);

  useEffect(() => {
    console.log("Status update")
    var carListTemp = carList;
    carListTemp.map((carListItem, index) => {
      // console.log(carStatusTemp+"vin item")
      if (upgradeStatus !== undefined) {
        // console.log(vinItem+"vin item "+ carListItem[0]+" index " +index)
        if (upgradeStatus["vin"] === carListItem["vin"]) {
          carListTemp[index]["status"] = upgradeStatus["status"];
          carListTemp[index]["currentversion"] =
            upgradeStatus["currentversion"];
          carListTemp[index]["last_updated"] = upgradeStatus["last_updated"];
        }
      }
    });
    setCarList(carListTemp);
    // console.log(carListTemp)
  }, [upgradeStatus]);
  // To poll for status - comment this use effect if web socket is enabled 
  
  useEffect(()=>{
    const statuspoll=setInterval(()=>{
    const param = {
      model: model,
      country_id: ccid,
      state_id: ssid,
      city_id: ccitid,
      hardware: hardware,
    };
    // const url2 = baseUrl + "/get/softwareUpdate/details/";
    const url2 = baseUrl + "/get/softwareUpdateDetails/";

    // graph side data api call (stats)
    const fetchDeviceStats = async () => {
      await axios
        .post(url2, param, {
          headers: { Authorization: `Bearer ${getJwtToken()}` },
        })
        .then((res) => {
          const temp2 = res.data.response;
          setDeviceUpgradeStats(temp2);
        })
        .catch((error) => {
          handleSubmit()
          console.log("Error inside fetchDeviceStats");
          console.log(error);
          setDeviceUpgradeStats({
            total_vehicles: 0,
            needs_upgrade: 0,
            running_latest_version: 0,
            latest_available_version: "-",
            release_date: "-",
            version_count: {},
          });
        });
    };
    // fetchDeviceStats();

    //  main list view
    // const url = baseUrl + "/get/softwareUpdate/listview/";
    const url = baseUrl + "/get/softwareUpdateListview/"
    const body = {
      pageApi: pageApi,
      model: model,
      country_id: ccid,
      state_id: ssid,
      city_id: ccitid,
      hardware: hardware
    };
    const fetchDeviceList = async () => {
      await axios
        .post(url, body, {
          headers: { Authorization: `Bearer ${getJwtToken()}` },
        })
        .then((res) => {
          const temp = res.data;
          setCarList(temp);
        })
        .catch((error) => {
          handleSubmit()
        });
    };
    if(hardware != undefined){
      
      fetchDeviceStats();
      fetchDeviceList();
    }
    else{
      setDeviceUpgradeStats({
        total_vehicles: 0,
        needs_upgrade: 0,
        running_latest_version: 0,
        latest_available_version: "-",
        release_date: "-",
        version_count: {},
      });
    }
  },15 *1000)

  return () => clearInterval(statuspoll);
  },[model, hardware]);

  useEffect(() => {
    if (carList.length > 0) {
      if (checkedList.length === carList.length) {
        setCheckedStateAll(true);
      } else {
        setCheckedStateAll(false);
      }
    }
  }, [checkedList]);

  return (
    <div className="remote_device_page">
      <div className="model_row">
        <div className="model_selector">
          <div className="filter_selector">
            <div>Model: </div>
            <div>
              <FormControl sx={{ minWidth: 199, minHeight: 36 }}>
                <Select
                  value={model}
                  onChange={handleModel}
                  displayEmpty={true}
                  style={{
                    height: 36,
                    fontFamily: "Poppins",
                    background: "white",
                    borderRadius: "0",
                  }}>
                  {/* <MenuItem
                    value=""
                    onClick={() => {
                      setModel(null);
                    }}>
                    All Models
                  </MenuItem> */}
                  {modelList.map((item, index) => (
                    <MenuItem value={item}>{item}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>

          <div className="filter_selector">
            <div>Hardware: </div>
            <div>
              <FormControl sx={{ minWidth: 130, minHeight: 36 }}>
                <Select
                  value={hardware}
                  onChange={handleHardware}
                  displayEmpty={true}
                  style={{
                    height: 36,
                    fontFamily: "Poppins",
                    background: "white",
                    borderRadius: "0",
                  }}>
                  {/* <MenuItem
                    value=""
                    onClick={() => {
                      setHardware(null);
                    }}>
                    All Hardware
                  </MenuItem> */}
                  {hardwareList.map((item, index) => (
                    <MenuItem value={item}>{item}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
        </div>
        <div className="remote_device_buttons">
          <FotaPublishComponentBtn config={config} isDisable={uploadStatus} />
          <FotaUpgradeButton
            device={upgardeList}
            upgradeDevice={newUpgardeList}
            isDisable={false}
            count={upgardeList.length}
            totalSelected={checkedList.length}
            allDeviceNameList={scheduleDeviceList}
            upgradeDeviceNameList={upgradeDeviceList}
            config={config}
            selectedList={selection}
          />
          <FotaCancelAllButton
            device={cancelList}
            isDisable={false}
            count={cancelList.length}
            totalSelected={checkedList.length}
            allDeviceNameList={scheduleDeviceList}
            upgradeDeviceNameList={upgradeDeviceList}
            cancelDeviceNameList={cancelDeviceList}></FotaCancelAllButton>
        </div>
      </div>
      <div className="vehicle_stat">
        <div>
          {deviceUpgradeStats.total_vehicles === 0 && model === "" ? (
            <Chart
              type="donut"
              width={200}
              height={200}
              series={[0, 1]}
              options={{
                labels: ["0%", "0%"],
                tooltip: {
                  enabled: false,
                  fillSeriesColor: false,
                  theme: false,
                },

                colors: colorsPie,
                stroke: {
                  width: 0,
                },
                plotOptions: {
                  pie: {
                    expandOnClick: false,
                    donut: {
                      expandOnClick: false,
                      labels: {
                        show: true,
                        name: {
                          show: true,
                          fontSize: "14px",
                          fontWeight: 600,
                          color: "#828799",
                          fontFamily: "Poppins",
                        },
                        value: {
                          show: true,
                          fontSize: "24px",
                          fontWeight: 600,
                          color: "#83B148",
                          fontFamily: "Poppins",
                        },
                        total: {
                          show: true,
                          showAlways: true,
                          label: "100%",
                          fontSize: "14px",
                          label: "%",
                          fontWeight: 600,
                          color: "#828799",
                          fontFamily: "Poppins",
                          formatter: function (w) {
                            return 0;
                          },
                        },
                      },
                    },
                  },
                },
                states: {
                  hover: {
                    filter: {
                      type: "none",
                    },
                  },
                  active: {
                    filter: {
                      type: "none" /* none, lighten, darken */,
                    },
                  },
                },
                dataLabels: {
                  enabled: false,
                  // style: {
                  //   colors: ['#F44336', '#E91E63', '#9C27B0']
                  // }
                },
                legend: {
                  show: false,
                },
              }}
            />
          ) :
            model === "" ? (
              <Chart
                type="donut"
                width={440}
                height={440}
                series={dataPie}
                options={{
                  labels: labelPie,
                  tooltip: {
                    enabled: true,
                    fillSeriesColor: false,
                    theme: "dark",
                    marker: false,
                    custom: function ({
                      series,
                      seriesIndex,
                      dataPointIndex,
                      w,
                    }) {
                      // console.log(seriesIndex)
                      var val;
                      if (seriesIndex === 0) val = "Updated to latest Version";
                      else val = "Needs Upgrade";
                      return (
                        '<div class="arrow_box">' +
                        "<span>" +
                        val +
                        " : " +
                        series[seriesIndex] +
                        "</span>" +
                        "</div>"
                      );
                    },
                  },
                  colors: colorsPie,
                  stroke: {
                    width: 0,
                  },
                  plotOptions: {
                    pie: {
                      expandOnClick: false,
                      donut: {
                        expandOnClick: false,
                        labels: {
                          show: true,
                          name: {
                            show: true,
                            fontSize: "14px",
                            fontWeight: 600,
                            color: "#828799",
                            fontFamily: "Poppins",
                          },
                          value: {
                            show: true,
                            fontSize: "24px",
                            fontWeight: 600,
                            color: "#83B148",
                            fontFamily: "Poppins",
                          },
                          total: {
                            show: true,
                            showAlways: true,
                            label: "100%",
                            fontSize: "16px",
                            label: totalLabel + "%",
                            fontWeight: 600,
                            color: "#828799",
                            fontFamily: "Poppins",
                            formatter: function (w) {
                              return dataPie[0];
                            },
                          },
                        },
                      },
                    },
                  },
                  states: {
                    hover: {
                      filter: {
                        type: "none",
                      },
                    },
                    active: {
                      filter: {
                        type: "none" /* none, lighten, darken */,
                      },
                    },
                  },
                  dataLabels: {
                    enabled: false,
                    // style: {
                    //   colors: ['#F44336', '#E91E63', '#9C27B0']
                    // }
                  },
                  legend: {
                    show: true,
                    position: "right",
                    fontWeight: 500,
                    horizontalAlign: "right",
                    width: 180,
                    height: 200,
                    markers: {
                      width: 12,
                      height: 12,
                      strokeWidth: 0,
                      strokeColor: "#fff",
                      fillColors: undefined,
                      radius: 12,
                      customHTML: undefined,
                      onClick: undefined,
                      offsetX: 0,
                      offsetY: 0,
                    },
                    onItemHover: {
                      highlightDataSeries: false,
                    },
                  },
                }}
              />
            ) :
              deviceUpgradeStats !== "Connection Successful." &&
                deviceUpgradeStats.total_vehicles === 0 ? (
                  <>
                   <div style={{ marginLeft:'-60px',marginTop:'80px',width:'600px',
                      display:'flex',alignItems: 'center',justifyContent: 'center',overflow:'auto'}}>
                    {/* <div style={{ position:'absolute',marginLeft:'-65px',marginTop:'50px',width:'600px',
                      display:'flex',alignItems: 'center',justifyContent: 'left',overflow:'auto'}}>  */}
                    <div className="rectangle"></div>
                      <div className="label">Latest Version</div>
                      <div className="rectangle1"></div>
                      <div className="label">Relatively Older Version</div>
                      <div className="rectangle2"></div>
                      <div className="label">Very Old Version</div>
                      <div className="rectangle3"></div>
                      <div className="label">Outdated Version</div>
                    </div>
                <Bar
                  style={{ marginLeft: "-110px",marginTop:'-60px' }}
                  width={580}
                  height={300}
                  data={{
                    labels: ["v1.0.0", "v1.0.1"],
                    datasets: [
                      {
                        data: [0, 0],
                        backgroundColor: colorsPie,
                      },
                    ],
                  }}
                  options={{
                    layout: {
                      padding: 80,
                    },
                    scales: {
                      x: {
                        grid: {
                          display: false,
                        },
                        font: {
                          weight: "bold",
                          size: 14,
                        },
                      },
                      y: {
                        grid: {
                          display: false,
                        },
                        font: {
                          weight: "bold",
                          size: 14,
                        },
                      },
                    },
                    indexAxis: "y",
                    plugins: {
                      legend: {
                        display: false,
                      },
                      datalabels: {
                        // clip:false,
                        position: "outside",
                        display: true,
                        anchor: "end",
                        align: "right",

                        formatter: function (val, opts) {
                          return "0 (0.0%)";
                        },
                        font: {
                          weight: "bold",
                          size: 14,
                        },
                      },
                    },
                    plotOptions: {
                      bar: {
                        horizontal: true,
                        distributed: true,
                      },
                    },
                  }}
                />
                </>
                // <div>{console.log(deviceUpgradeStats)}</div>
              ) :
                (
                  <>
                  <div style={{ marginLeft:'-60px',marginTop:'80px',width:'600px',
                      display:'flex',alignItems: 'center',justifyContent: 'center',overflow:'auto'}}>
                    {/* <div style={{ position:'absolute',marginLeft:'-65px',marginTop:'50px',width:'600px',
                      display:'flex',alignItems: 'center',justifyContent: 'left',overflow:'auto'}}>  */}
                    <div className="rectangle"></div>
                      <div className="label">Latest Version</div>
                      <div className="rectangle1"></div>
                      <div className="label">Relatively Older Version</div>
                      <div className="rectangle2"></div>
                      <div className="label">Very Old Version</div>
                      <div className="rectangle3"></div>
                      <div className="label">Outdated Version</div>
                    </div>
                    <Bar
                      style={{ marginLeft: "-100px",marginTop:"-60px" }}
                      width={580}
                      height={300}
                      data={{
                        labels: labelPie,
                        datasets: [
                          {
                            data: dataPie,
                            backgroundColor: colorsPie,
                          },
                        ],
                      }}
                      options={{
                        layout: {
                          padding: 90,
                        },
                        scales: {
                          x: {
                            grid: {
                              display: false,
                            },
                            font: {
                              weight: "bold",
                              size: 14,
                            },
                          },
                          y: {
                            grid: {
                              display: false,
                            },
                            font: {
                              weight: "bold",
                              size: 14,
                            },
                          },
                        },
                        indexAxis: "y",
                        plugins: {
                          legend: {
                            display: false,
                          },
                          datalabels: {
                            // clip:false,
                            position: "outside",
                            display: true,
                            anchor: "end",
                            align: "right",

                            formatter: function (val, opts) {
                              return (
                                val +
                                " " +
                                "(" +
                                ((parseInt(val) * 100) / totalVersionCount).toFixed(
                                  1
                                ) +
                                "%)"
                              );
                            },
                            font: {
                              weight: "bold",
                              size: 14,
                            },
                          },
                        },
                        plotOptions: {
                          bar: {
                            horizontal: true,
                            distributed: true,
                          },
                        },
                      }}
                    />
                  </>
                )
          }
        </div>
        {/* <div className="seperator"></div> */}
        <div className="vehicle_stat_row">
          <div className="vehicle_stat_meta">
            No. of vehicles:{" "}
            <span className="vehicle_stat_data">
              {deviceUpgradeStats.running_latest_version +
                deviceUpgradeStats.needs_upgrade}
            </span>
          </div>
          <div className="vehicle_stat_meta">
            Vehicles need to update:{" "}
            <span className="vehicle_stat_data">
              {deviceUpgradeStats.needs_upgrade}
            </span>
          </div>
          {model === "" ? (
            <>
              <div className="vehicle_stat_meta">
                Latest software version:{" "}
                <span
                  className="vehicle_stat_data"
                  style={{ color: "#3174A4" }}>
                  ~
                </span>
              </div>
              <div className="vehicle_stat_meta">
                Release date: <span className="vehicle_stat_data">~</span>
              </div>
            </>
          ) : (
            <>
              <div className="vehicle_stat_meta">
                Latest software version:{" "}
                <span
                  className="vehicle_stat_data"
                  style={{ color: "#3174A4" }}>
                  {deviceUpgradeStats.latest_available_version}
                </span>
              </div>
              <div className="vehicle_stat_meta">
                Release date:{" "}
                <span className="vehicle_stat_data">
                  {deviceUpgradeStats.release_date}
                </span>
              </div>
            </>
          )}
        </div>
      </div>
      <div>
        {!isLoaded ? (
          <div className="list_panel list_panel_style">
            <table className="remote_device_table">
              <tr className="remote_device_table_header">
                <th style={{}}>
                  <input
                    type="checkbox"
                    style={{ width: "17px", height: "17px" }}
                    checked={checkedStateAll}
                    onChange={() => handleOnChangeAllCheckbox()}
                  />
                </th>
                <th className="remote_device_table_header_row">
                  REGISTRATION NO
                </th>
                <th className="remote_device_table_header_row">MODEL</th>
                <th className="remote_device_table_header_row">
                  SOFTWARE VERSION
                </th>
                <th className="remote_device_table_header_row">
                  LAST UPDATE ON
                </th>
                <th className="remote_device_table_header_row">
                  VEHICLE STATE
                </th>
                <th
                  className="remote_device_table_header_row"
                  style={{ width: "16vw" }}>
                  STATUS
                </th>
              </tr>
            </table>

            <div
              style={{
                height: "500px",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <CircularProgress color="primary" />
            </div>
          </div>
        ) : (
          <div className="list_panel list_panel_style">
            <table className="remote_device_table">
              <tr className="remote_device_table_header">
                <th style={{}}>
                  <input
                    type="checkbox"
                    style={{ width: "17px", height: "17px" }}
                    checked={checkedStateAll}
                    onChange={() => handleOnChangeAllCheckbox()}
                  />
                </th>
                <th className="remote_device_table_header_row">
                  REGISTRATION NO
                </th>
                <th className="remote_device_table_header_row">MODEL</th>
                <th className="remote_device_table_header_row">
                  SOFTWARE VERSION
                </th>
                <th className="remote_device_table_header_row">
                  LAST UPDATE ON
                </th>
                <th className="remote_device_table_header_row">
                  VEHICLE STATE
                </th>
                <th className="remote_device_table_header_row">STATUS</th>
              </tr>
              {deviceUpgradeStats.total_vehicles === 0 ? (
                <>
                  <td colSpan={7}>
                    <div className="empty_table_text">
                      No Available vehicles...
                    </div>
                  </td>
                </>
              ) : (
                carList.map((item, index) => (
                  <tr
                    className="remote_device_table_row"
                    key={index}
                    style={{
                      background: checkedState[index + (pageApi - 1) * 8]
                        ? "#F0F8FF"
                        : "",
                    }}>
                    <td>
                      <input
                        style={{ width: "17px", height: "17px" }}
                        type="checkbox"
                        id={`custom-checkbox-${index + (pageApi - 1) * 8}`}
                        // name={name}
                        // value={name}
                        checked={checkedState[index + (pageApi - 1) * 8]}
                        onChange={() =>
                          handleOnChangeCheckbox(
                            index,
                            item["devicename"],
                            item["vin"],
                            item["currentversion"]

                          )
                        }
                      />
                    </td>
                    <td className="row_text_style">{item["registrationno"]}</td>
                    <td className="row_text_style">{item["vehiclemodel"]}</td>
                    <td className="row_text_style">{item["currentversion"]}</td>
                    <td className="row_text_style">
                      {moment(item["last_updated"]).format("Do MMM YYYY")}
                    </td>
                    {item["devicestate"] === "Offline" ? (
                      <td style={{ color: "#555759" }}>
                        <div className="device_status_row">
                          <div className="offline_dot"></div>
                          <div>{item["devicestate"]}</div>
                        </div>
                      </td>
                    ) : (
                      <td style={{ color: "#62AD41" }}>
                        <div className="device_status_row">
                          <div className="online_dot"></div>
                          <div>{item["devicestate"]}</div>
                        </div>
                      </td>
                    )}
                    {item["status"] === "Running Latest Version" ||
                      item["status"] === "Upgrade Scheduled" ? (
                      <td className="upgrade_status_row">
                        <div className="latest_version">
                          <div>{item["status"]}</div>
                        </div>
                      </td>
                    ) : item["status"] === "Needs Upgrade" ? (
                      <td className="upgrade_status_row">
                        <div className="needs_upgrade">
                          <div>{item["status"]}</div>
                        </div>
                      </td>
                    ):item["status"] === "UPGRADE INCOMPATIBLE" ? (
                      <td className="upgrade_status_row">
                        <div className="incompatible">
                          <div>{item["status"]}</div>
                        </div>
                      </td>
                    )
                     : (
                      <td className="upgrade_status_row">
                       <div className="in_progress">
                          <div>
                            <img
                              src={loader}
                              alt="loader"
                              height={"16px"}
                              className="in_progress_dot"
                            />
                          </div>
                          <div>{item["status"]}</div>
                        </div> 
                      
                      </td>
                    )}
                  </tr>
                ))
              )}
            </table>
            <div style={{ margin: "10px" }}>
              <Pagination
                count={parseInt(deviceUpgradeStats.total_vehicles / 8) + 1}
                page={pageApi}
                shape="rounded"
                onChange={(e, value) => setPageApi(value)}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default FotaUpgrade;
