import React, { useState, useEffect } from "react";
import {
    Alert,Button,Typography,CircularProgress,Stack, Dialog
} from "@mui/material";
import axios from 'axios';
import { baseUrl } from '../../constants';




function FotaPublishComponentBtn(props) {    
    
     //Alert Message variables
     const [ notifyToastOpen, setNotifyToast] = useState(false);
     const [ notifyToastMessage, setNotifyToastMessage ] = useState("");
     const [ toastType, setToastType] = useState("");
     const [ loading, setLoading] = useState(false);
     const [open,setOpen]=useState(false);

     

     useEffect(() => {
        if (notifyToastOpen) {
          const timer = setTimeout(() => {
            setNotifyToast(false);
          }, 5000);   
          
          return () => clearTimeout(timer);
        }
      }, [notifyToastOpen]);

     const handleToastOpen = (name, type) => {
        console.log(name);
        setNotifyToast(true);
        setNotifyToastMessage(name);
        setToastType(type);
    };
     
    const getJwtToken = () => {
        let token = localStorage.getItem("token") || undefined;
        return token === undefined ? "" : `${JSON.parse(token)}`;
      };
    //handling open close dialog first-one    
    const publishComponent = async () => {
        console.log("Call esync API")
        console.log(props.config)
        
        setLoading(true)
        setOpen(true)
        await axios.post(baseUrl+"/fota/uploadComponentAndCreatePackage/" ,props.config, {
            headers: { Authorization: `Bearer ${getJwtToken()}` },
          }).then((res)=>{
          console.log(res);  
          setOpen(false);      
          setLoading(false);
          handleToastOpen("Ready to Upgrade  ","success")
          setNotifyToast(false);
          window.location.reload(false);          
        }).catch((err)=>{
          console.log(err);
          setOpen(false);
          setLoading(false);
        })
    };

    return (
        <div>                     
           
            <Button
                variant="contained"
                onClick={publishComponent}
                disabled ={props.isDisable}
                className="upgradeButton"
                disableElevation
                sx={{
                    width: "150px",
                    height: "36px",
                    borderRadius: "0px",
                    bgcolor: "#3174A4",
                    color: "black",
                    fontWeight: 400,
                    textTransform: "none",
                    marginLeft:"5px",
                
                    "&:hover": { backgroundColor: "#3174A4" },
                }}
            >
                <Typography
                    sx={{
                        fontWeight: 400,
                        fontSize: "14px",
                        color: props.isDisable ? "#8E939E" : "#FFFFFF",
                    }}
                >
                    Prepare OTA
                </Typography>
            </Button>
            <Dialog
                open = {open}
                aria-labelledby = 'confirm-multi-OS-update'
                aria-describedby= 'confirm-multi-OS-choice'
                disableEscapeKeyDown
                PaperProps={{
                    sx: {
                        width: "500px",
                        borderRadius: "0px",
                        height: undefined
                    }
                }}
            >
            {   loading ?
                    (<Stack direction="column" sx = {{display: "flex",alignItems: "center",textAlign: "center",position: "center", justifyContent: "center", pt: "43px", pb:"43px"}}>
                        <CircularProgress color="primary"/>
                        <Typography className='Initialising'>Preparing OTA<span className="loader__dot">.</span><span className="loader__dot">.</span><span className="loader__dot">.</span></Typography>
                    </Stack>):<></>
            }
            </Dialog>

            <div className='update_alert'>
                {notifyToastOpen &&
                    <Alert severity={toastType}>
                        <Typography className='alertMessage'>{notifyToastMessage}</Typography>
                    </Alert>}
            </div>
        </div>
    );
}

export default FotaPublishComponentBtn;