import React, { useState, useEffect } from "react";
import VehicleIdentify from "../VehicleDashboard/VehicleIdentify";
import "./index.css";
import dayjs from "dayjs";
import { Line, Bar } from "react-chartjs-2";
import Chart from "chart.js/auto";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { Stack, Box } from "@mui/system";
import axios from "axios";
import { Button } from "@mui/material";
import annotationPlugin from "chartjs-plugin-annotation";
import Typography from "@mui/material/Typography";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useLocation } from "react-router-dom";
import { baseUrl } from "../../../constants";

Chart.register(annotationPlugin);
var vinDat;
var close = 0;

const Analytics = () => {
  // data comming from Veichle identificatio
  const [vinData, setVinData] = useState(null);
  const [vehicleData, setVehicleData] = useState([]);
  const [warningPopup, showWarningPopup] = useState(0);

  // graphs data array

  const [speedData, setSpeedData] = useState([]);
  const [rpmData, setRpmData] = useState([]);
  const [temperatureData, setTempreatureData] = useState([]);
  const [batteryDataArr, setBatteryDataArr] = useState([]);

  const location = useLocation();

  useEffect(() => {
    const res = location.pathname.slice(22);
    var val = "";
    for (var i = 0; i < res.length; i++) {
      if (res[i] === "/") break;
      val += res[i];
    }
    setVinData(val);
    vinDat = val;
  });

  let navigate = useNavigate();
  useEffect(() => {
    verifyCurrentUser();
  });
  const getJwtToken = () => {
    let token = localStorage.getItem("token") || undefined;
    return token === undefined ? "" : `${JSON.parse(token)}`;
  };
  const verifyCurrentUser = async () => {
    await axios
      .post(baseUrl + "/api/user/verify/", null, {
        headers: { Authorization: `Bearer ${getJwtToken()}` },
      })
      .then((result) => {
        console.log(result);
        console.log("Correct User Loged-In");
        if (result.data.bool != 1) {
          navigate("/login");
        }
      });
  };

  const handleData = (arr) => {
    // console.log(vinData + " " + arr.vin +"aaaaaaaaaaaaaaa")
    // console.log(arr.bolll);
    if (arr.graph === 0) {
      setSpeedData([
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
      ]);
      setRpmData([
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
      ]);
      setTempreatureData([
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
      ]);
      setBatteryDataArr([
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
      ]);
      setVinData(arr.vin);
      // vinDat = arr.vi/n;
    } else if (close === 0) {
      // console.log(arr)
      setVehicleData(arr);
    }
  };

  useEffect(() => {
    const arr = vehicleData;
    var newarr = speedData;
    newarr.pop();
    if (arr.speed < 500) newarr.unshift(arr.speed);
    else newarr.unshift(null);
    setSpeedData(newarr);

    console.log(speedData);

    newarr = rpmData;
    newarr.pop();
    if (arr.rpm < 15000) newarr.unshift(arr.rpm);
    else newarr.unshift(null);
    setRpmData(newarr);

    newarr = temperatureData;
    newarr.pop();
    if (arr.temperature < 200) newarr.unshift(arr.temperature);
    else newarr.unshift(null);
    setTempreatureData(newarr);

    newarr = batteryDataArr;
    newarr.pop();
    if (arr.battery < 100) newarr.unshift(arr.battery.toFixed(1));
    else newarr.unshift(null);
    setBatteryDataArr(newarr);
    console.log(batteryDataArr);
  }, [vehicleData]);

  const livePeriod = [
    "current",
    "1s",
    "2s",
    "3s",
    "4s",
    "5s",
    "6s",
    "7s",
    "8s",
    "9s",
    "10s",
    "11s",
    "12s",
    "13s",
    "14s",
  ];
  const [hisoricPeriod, setHistoricPeriod] = useState([
    "Day15",
    "Day14",
    "Day13",
    "Day12",
    "Day11",
    "Day10",
    "Day9",
    "Day8",
    "Day7",
    "Day6",
    "Day5",
    "Day4",
    "Day3",
    "Day2",
    "Day1",
  ]);
  const [label, setLabel] = useState(livePeriod);

  // Static Api call

  const url = baseUrl + "/basicDetails/interval/";

  const [batterythreshold, setBatteryThreshold] = useState(0);
  const [rpmthreshold, setRPMThreshold] = useState(0);
  const [speedthreshold, setSpeedThreshold] = useState(0);
  const [temperaturethreshold, setTemperatureThreshold] = useState(0);

  useEffect(() => {
    const body = { vin: vinData };
    setSpeedData([
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ]);
    setRpmData([
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ]);
    setTempreatureData([
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ]);
    setBatteryDataArr([
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ]);
    const fetchDeviceStats = async () => {
      await axios
        .post(url, body, {
          headers: { Authorization: `Bearer ${getJwtToken()}` },
        })
        .then((res) => {
          const temp = res.data.result;
          // console.log(temp)
          temp.map((item) => {
            setSpeedData((oldArray) => [...oldArray, item.speed]);
            setRpmData((oldArray) => [...oldArray, item.rpm]);
            setTempreatureData((oldArray) => [...oldArray, item.temperature]);
            setBatteryDataArr((oldArray) => [...oldArray, item.battery]);
          });
        })
        .catch((err)=>{
          console.log(err);
        });
    };
    const url3 = baseUrl + "/basicDetails/threshold/data/";

    const fetchThershold = async () => {
      await axios
        .post(url3, body, {
          headers: { Authorization: `Bearer ${getJwtToken()}` },
        })
        .then((res) => {
          const temp = res.data.result;
          // console.log(temp)
          setTemperatureThreshold(temp.temperature);
          setBatteryThreshold(temp.battery);
          setRPMThreshold(temp.rpm);
          setSpeedThreshold(temp.speed);
        })
        .catch((err)=>{
          console.log(err);
        });
    };
    if(vinData != null){
    fetchThershold();
    fetchDeviceStats();
    }
  }, [vinData]);

  useEffect(() => {
    setSpeedData([
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ]);
    setRpmData([
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ]);
    setTempreatureData([
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ]);
    setBatteryDataArr([
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ]);
  }, [vinDat]);

  // grpah time

  const [radioValue, setRadioValue] = useState("current");
  const [startDate, setStartDate] = useState(null);
  const [startDateConv, setStartDateConv] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [endDateConv, setEndDateConv] = useState(null);
  const [showNullInputPopup, setShowNullInputPopup] = useState(0);

  const changeGraphTime = (event) => {
    setRadioValue(event.target.value);
    if (event.target.value === "period") {
      close = 1;
      setEndDate(null);
      setStartDate(null);
    } else {
      close = 0;
      setSpeedData([
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
      ]);
      setRpmData([
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
      ]);
      setTempreatureData([
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
      ]);
      setBatteryDataArr([
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
      ]);
      setLabel(livePeriod);
    }
  };

  const urlTimePeriod = baseUrl + "/basicDetails/range/";
  const bodyTime = { vin: vinData, startTime: startDate, endTime: endDate };

  const [maxRPM, setMaxRPM] = useState([]);
  const [minRPM, setMinRPM] = useState([]);
  const [maxSpeed, setMaxSpeed] = useState([]);
  const [minSpeed, setMinSpeed] = useState([]);
  const [maxTemp, setMaxTemp] = useState([]);
  const [minTemp, setMinTemp] = useState([]);
  const [minBat, setMinBat] = useState([]);
  const [maxBat, setMaxBat] = useState([]);

  const getTimePeriodData = () => {
    setSpeedData([]);
    setRpmData([]);
    setTempreatureData([]);
    setBatteryDataArr([]);
    setLabel(hisoricPeriod);
    const fetchDevicePeriodData = async () => {
      // console.log(bodyTime)

      await axios
        .post(urlTimePeriod, bodyTime, {
          headers: { Authorization: `Bearer ${getJwtToken()}` },
        })
        .then((res) => {
          const temp = res.data.result;
          // console.log(temp);
          const temp22 = temp.time;
          setBatteryDataArr(temp.battery);
          setSpeedData(temp.speed);
          setRpmData(temp.rpm);
          setTempreatureData(temp.temperature);

          /////
          setMaxRPM(temp.max_rpm);
          setMinRPM(temp.min_rpm);

          setMaxTemp(temp.max_temperature);
          setMinTemp(temp.min_temperature);

          setMaxSpeed(temp.max_speed);
          setMinSpeed(temp.min_speed);

          setMaxBat(temp.max_battery);
          setMinBat(temp.min_battery);

          setLabel(temp22);
        });
    };
    fetchDevicePeriodData();

    console.log(startDate + "startttt");
    console.log(endDate + "enddddd");
  };

  let width, height, gradient;
  function getGradient(ctx, chartArea) {
    const chartWidth = chartArea.right - chartArea.left;
    const chartHeight = chartArea.bottom - chartArea.top;
    if (!gradient || width !== chartWidth || height !== chartHeight) {
      // Create the gradient because this is either the first render
      // or the size of the chart has changed
      width = chartWidth;
      height = chartHeight;
      gradient = ctx.createLinearGradient(
        0,
        chartArea.bottom,
        0,
        chartArea.top
      );
      gradient.addColorStop(0, " #98B642");
      // gradient.addColorStop(0.33,  '#FF7777');
      gradient.addColorStop(0.66, "#FFD44A");
      gradient.addColorStop(1, " #FF7777");
    }

    return gradient;
  }

  const up = (ctx, value) =>
    ctx.p0.parsed.y < ctx.p1.parse.y ? value : undefined;
  const down = (ctx, value) =>
    ctx.p0.parsed.y > ctx.p1.parse.y ? value : undefined;

  const data = {
    labels: label,
    type: "line",
    datasets: [
      {
        data: speedData,
        label: "speed",
        backgroundColor: "rgba(147, 186, 69,0.2)",
        borderColor: function (context) {
          const chart = context.chart;
          const { ctx, chartArea } = chart;
          if (!chartArea) {
            // This case happens on initial chart load
            return;
          }
          return getGradient(ctx, chartArea);
        },
        pointBorderWidth: 2,
        // borderColor: 'red',
        // pointBorderColor: 'red',
        tension: 0.4,
        segment: {
          borderColor: (context) => {
            if (context.p1.parsed.y >= speedthreshold) return "#FF7777";
            else if (context.p1.parsed.y >= speedthreshold / 2)
              return "#FFD44A";
            else return "#98B642";
          },
        },
        fill: false,
      },
    ],
  };

  //bar chart

  const backgroundcolor = [];
  for (var i = 0; i < temperatureData.length; i++) {
    if (temperatureData[i] >= temperaturethreshold)
      backgroundcolor.push("#FF7777");
    else if (temperatureData[i] >= temperaturethreshold / 2)
      backgroundcolor.push("#FFD44A");
    else backgroundcolor.push("#98B642");
  }

  const data2 = {
    labels: label,
    datasets: [
      {
        data: temperatureData,
        // label: "Temperature",
        // borderColor: function(context) {
        //   const chart = context.chart;
        //   const {ctx, chartArea} = chart;
        //   if (!chartArea) {
        //     // This case happens on initial chart load
        //     return;
        //   }
        //   return getGradient(ctx, chartArea);
        // },
        backgroundColor: backgroundcolor,
        borderColor: "#93BA45",
        pointBorderColor: "#93BA45",
        pointBorderWidth: 5,
        tension: 0.4,
        segment: {
          borderColor: (context) => {
            if (context.p1.parsed.y >= temperaturethreshold) return "#FF7777";
            else if (context.p1.parsed.y >= temperaturethreshold / 2)
              return "#FFD44A";
            else return "#98B642";
          },
        },
        fill: true,
      },
    ],
  };

  const data3 = {
    labels: label,
    datasets: [
      {
        data: rpmData,
        // label: "RPM",
        // backgroundColor: "rgba(147, 186, 69,0.2)",
        borderColor: function (context) {
          const chart = context.chart;
          const { ctx, chartArea } = chart;
          if (!chartArea) {
            // This case happens on initial chart load
            return;
          }
          return getGradient(ctx, chartArea);
        },
        pointBorderWidth: 2,
        tension: 0.4,
        segment: {
          borderColor: (context) => {
            if (context.p1.parsed.y >= rpmthreshold) return "#FF7777";
            else if (context.p1.parsed.y >= rpmthreshold / 2) return "#FFD44A";
            else return "#98B642";
          },
        },
        fill: false,
      },
    ],
  };

  const data4 = {
    labels: label,
    datasets: [
      {
        data: batteryDataArr,
        // label: "battery",
        // backgroundColor: function(context) {
        //   const chart = context.chart;
        //   const {ctx, chartArea} = chart;
        //   if (!chartArea) {
        //     // This case happens on initial chart load
        //     return;
        //   }
        //   return getGradient(ctx, chartArea);
        // },
        borderColor: function (context) {
          const chart = context.chart;
          const { ctx, chartArea } = chart;
          if (!chartArea) {
            // This case happens on initial chart load
            return;
          }
          return getGradient(ctx, chartArea);
        },
        pointBorderWidth: 0,
        tension: 0.4,
        segment: {
          borderColor: (context) => {
            if (context.p1.parsed.y > 1.8 * batterythreshold) return "#98B642";
            else if (context.p1.parsed.y > batterythreshold) return "#FFD44A";
            else return "#FF7777";
          },
          backgroundColor: (context) => {
            console.log(
              batteryDataArr[context.p1DataIndex] + " " + batterythreshold
            );
            if (context.p1.parsed.y > 1.8 * batterythreshold) return "#98B642";
            else if (context.p1.parsed.y > batterythreshold) return "#FFD44A";
            else return "#FF7777";
          },
        },
        fill: true,
      },
    ],
  };

  const tempreatureOptions = {
    plugins: {
      legend: false,
      datalabels: false,
      annotation: {
        annotations: {
          line1: {
            type: "line",
            yMin: temperaturethreshold,
            yMax: temperaturethreshold,
            borderColor: "rgb(255, 99, 132)",
            borderWidth: 1,
            label: {
              display: true,
              content: temperaturethreshold,
              position: "end",
              yAdjust: 0,
              xAdjust: 3,
              padding: 4,
              font: {
                size: 10,
                weight: "normal",
              },
            },
          },
        },
      },
      // title: {
      //   display: true,
      //   text: 'Temperature (°C)',
      //   align:'start',
      //   font:{
      //     weight:'bold',
      //     size:15,
      //   },
      //   padding: {
      //       top: 12,
      //       left: 25,
      //       bottom:23,

      //   }
      // },
      tooltip: {
        callbacks: {
          label: function (context) {
            // console.log(context.dataIndex)

            var multistringText = [`Avg : ${context.formattedValue}`];
            // do some stuff
            if (close) {
              multistringText.push(`Max : ${maxTemp[context.dataIndex]}`);
              multistringText.push(`Min : ${minTemp[context.dataIndex]}`);
            }

            return multistringText;
          },
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        // min:20,
        // max:40,
        ticks: {
          stepSize: 4,
          callback: (value) => value,
        },
        grid: {
          borderDash: [10],
          color: "rgba(117, 180, 225, 0.4)",
        },
      },
    },
  };
  const rpmOptions = {
    plugins: {
      legend: false,
      datalabels: false,
      annotation: {
        annotations: {
          line1: {
            type: "line",
            yMin: rpmthreshold,
            yMax: rpmthreshold,
            borderColor: "rgb(255, 99, 132)",
            borderWidth: 1,
            label: {
              display: true,
              content: rpmthreshold,
              position: "end",
              yAdjust: 0,
              xAdjust: 3,
              padding: 4,
              font: {
                size: 10,
                weight: "normal",
              },
            },
          },
        },
      },
      // title: {
      //   display: true,
      //   text: 'RPM (rpm)',
      //   align:'start',
      //   font:{
      //     weight:'bold',
      //     size:15,
      //   },
      //   padding: {
      //       top: 12,
      //       left: 25,
      //       bottom:23,

      //   }
      // },
      tooltip: {
        callbacks: {
          label: function (context) {
            console.log(context.dataIndex);

            var multistringText = [`Avg : ${context.formattedValue}`];
            // do some stuff
            if (close) {
              multistringText.push(`Max : ${maxRPM[context.dataIndex]}`);
              multistringText.push(`Min : ${minRPM[context.dataIndex]}`);
            }

            return multistringText;
          },
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        // min:1000,
        // max:2000,
        ticks: {
          // stepSize: 0,
          callback: (value) => value,
        },
        grid: {
          borderDash: [10],
          color: "rgba(117, 180, 225, 0.4)",
        },
      },
    },
  };
  const speedOptions = {
    plugins: {
      datalabels: false,
      legend: false,
      annotation: {
        annotations: {
          line1: {
            type: "line",
            yMin: speedthreshold,
            yMax: speedthreshold,
            borderColor: "rgb(255, 99, 132)",
            borderWidth: 1,
            label: {
              display: true,
              content: speedthreshold,
              position: "end",
              yAdjust: 0,
              xAdjust: 3,
              padding: 4,
              font: {
                size: 10,
                weight: "normal",
              },
            },
          },
        },
      },
      // title: {
      //   display: true,
      //   text: 'Speed (Km/h)',
      //   align:'start',
      //   font:{
      //     weight:'bold',
      //     size:15,
      //   },
      //   padding: {
      //       top: 12,
      //       left: 25,
      //       bottom:23,

      //   }
      // },
      tooltip: {
        callbacks: {
          label: function (context) {
            console.log(context.dataIndex);

            var multistringText = [`Avg : ${context.formattedValue}`];
            // do some stuff
            if (close) {
              multistringText.push(`Max : ${maxSpeed[context.dataIndex]}`);
              multistringText.push(`Min : ${minSpeed[context.dataIndex]}`);
            }

            return multistringText;
          },
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        // min:18,
        // max:30,
        ticks: {
          // stepSize: 20,
          callback: (value) => value,
        },
        grid: {
          borderDash: [10],
          color: "rgba(117, 180, 225, 0.4)",
        },
      },
    },
  };
  const batteryOptions = {
    plugins: {
      legend: false,
      datalabels: false,
      annotation: {
        annotations: {
          line1: {
            type: "line",
            yMin: batterythreshold,
            yMax: batterythreshold,
            borderColor: "rgb(255, 99, 132)",
            borderWidth: 1,
            label: {
              display: true,
              content: batterythreshold,
              position: "end",
              yAdjust: 0,
              xAdjust: 3,
              padding: 4,
              font: {
                size: 10,
                weight: "normal",
              },
            },
          },
        },
      },
      // title: {
      //   display: true,
      //   text: 'Battery (Volts)',
      //   align:'start',
      //   font:{
      //     weight:'bold',
      //     size:15,
      //   },
      //   padding: {
      //       top: 12,
      //       left: 25,
      //       bottom:23,

      //   }
      // },
      tooltip: {
        callbacks: {
          label: function (context) {
            console.log(context.dataIndex);

            var multistringText = [`Avg : ${context.formattedValue}`];
            // do some stuff
            if (close) {
              multistringText.push(`Max : ${maxBat[context.dataIndex]}`);
              multistringText.push(`Min : ${minBat[context.dataIndex]}`);
            }

            return multistringText;
          },
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        // min:0,
        // max:100,
        ticks: {
          // stepSize: 25,
          userCallback: function (label, index, labels) {
            // when the floored value is the same as the value we have a whole number
            if (label.toFixed(1) === label) {
              return label;
            }
          },
        },
        grid: {
          borderDash: [10],
          color: "rgba(117, 180, 225, 0.4)",
        },
      },
    },
  };

  console.log(showNullInputPopup);

  return (
    <div className="main_panel">
      {warningPopup === 1 ? (
        <div className="warning_box">
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  showWarningPopup(0);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ mb: 2 }}
          >
            End Date is less than start Date
          </Alert>
        </div>
      ) : null}

      {showNullInputPopup == 1 ? (
        <div className="warning_box">
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setShowNullInputPopup(0);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ mb: 2 }}
          >
            Select Start Date and End Date
          </Alert>
        </div>
      ) : null}

      <div className="bottom_main_area">
        <div className="bottom_main_left_area">
          <div className="dashboard_main_area dashboard_main_area_analytics">
            <VehicleIdentify handleData={handleData} />
            <div
              className="right_side"
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "4vh",
                backgroundColor: "white",
                borderTop: "2px solid #93BA45",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <div className="select_timeline_chart">
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      onChange={changeGraphTime}
                      value={radioValue}
                    >
                      <FormControlLabel
                        value="current"
                        className="graph_radio"
                        control={<Radio color="success" />}
                        label={
                          <Typography fontSize={".8vmax"}>Live Data</Typography>
                        }
                      />
                      <FormControlLabel
                        value="period"
                        className="graph_radio"
                        control={<Radio color="success" />}
                        label={
                          <Typography fontSize={".75vmax"}>
                            Trend Data
                          </Typography>
                        }
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
                {radioValue === "period" ? (
                  <Stack
                    direction="row"
                    spacing="5px"
                    sx={{
                      width: "480px",
                    }}
                  >
                    <Box
                      sx={{
                        height: "10px",
                      }}
                    >
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <MobileDateTimePicker
                          renderInput={(props) => (
                            <TextField
                              size="small"
                              {...props}
                              placeholder="MM-DD-YYYY"
                              InputLabelProps={{ shrink: true }}
                            />
                          )}
                          className="start_date"
                          label="Start Date"
                          // open= {opendatetime}
                          value={startDate}
                          onChange={(newValue) => {
                            setStartDate(newValue["$d"].toString());
                            setStartDateConv(newValue);
                            // getTimePeriodData()
                          }}
                        />
                      </LocalizationProvider>
                    </Box>
                    <Box>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <MobileDateTimePicker
                          renderInput={(props) => (
                            <TextField
                              size="small"
                              {...props}
                              placeholder="MM-DD-YYYY"
                              InputLabelProps={{ shrink: true }}
                            />
                          )}
                          label="End Date"
                          value={endDate}
                          onAccept={(newValue) => {
                            setEndDate(newValue["$d"].toString());
                            setEndDateConv(newValue);
                          }}
                          onChange={(newValue) => {
                            setEndDate(newValue["$d"].toString());
                            setEndDateConv(newValue);
                            // getTimePeriodData()
                          }}
                        />
                      </LocalizationProvider>
                    </Box>
                    <div
                      onClick={() => {
                        if (endDateConv === null || startDateConv === null) {
                          setShowNullInputPopup(1);
                        } else if (endDateConv <= startDateConv) {
                          console.log(endDate);
                          console.log(startDate);
                          showWarningPopup(1);
                        } else {
                          getTimePeriodData();
                          showWarningPopup(0);
                        }
                      }}
                      className="show_graph"
                    >
                      Confirm
                    </div>
                  </Stack>
                ) : null}
              </div>

              <div className="line_charts">
                <div
                  className="linec1"
                  style={{ width: "25vw", height: "25vh", padding: "6px" }}
                >
                  <div className="speed_legend">Speed</div>
                  <div className="display_legend_icon">
                    <div className="speed_legend_unit">(Km/h)</div>
                    <div className="color_legend">
                      <div className="color_legend_container">
                        <div className="good_color_box clb"></div>
                        <div className="trend_condition_text1">Good</div>
                        <div className="mode_color_box clb"></div>
                        <div className="trend_condition_text1">Moderate</div>
                        <div className="crit_color_box clb"></div>
                        <div className="trend_condition_text1">Critical</div>
                      </div>
                    </div>
                  </div>
                  <Line
                    data={data}
                    height={"112%"}
                    options={speedOptions}
                  ></Line>
                </div>
                <div
                  className="linec2"
                  style={{ width: "25vw", height: "25vh", padding: "6px" }}
                >
                  <div className="speed_legend">RPM</div>
                  <div className="display_legend_icon">
                    <div className="speed_legend_unit">(rpm)</div>
                    <div className="color_legend">
                      <div className="color_legend_container">
                        <div className="good_color_box clb"></div>
                        <div className="trend_condition_text1">Good</div>
                        <div className="mode_color_box clb"></div>
                        <div className="trend_condition_text1">Moderate</div>
                        <div className="crit_color_box clb"></div>
                        <div className="trend_condition_text1">Critical</div>
                      </div>
                    </div>
                  </div>
                  <Line
                    data={data3}
                    height={"112%"}
                    options={rpmOptions}
                  ></Line>
                </div>
              </div>
              <div className="bar_chart_main">
                <div
                  className="linec1"
                  style={{ width: "25vw", height: "25vh", padding: "6px" }}
                >
                  <div className="speed_legend">Temperature</div>
                  <div className="display_legend_icon">
                    <div className="speed_legend_unit">(&deg; C)</div>
                    <div className="color_legend">
                      <div className="color_legend_container">
                        <div className="good_color_box clb"></div>
                        <div className="trend_condition_text1">Good</div>
                        <div className="mode_color_box clb"></div>
                        <div className="trend_condition_text1">Moderate</div>
                        <div className="crit_color_box clb"></div>
                        <div className="trend_condition_text1">Critical</div>
                      </div>
                    </div>
                  </div>
                  <Bar
                    data={data2}
                    height={"112%"}
                    options={tempreatureOptions}
                  ></Bar>
                </div>
                <div
                  className="linec2"
                  style={{ width: "25vw", height: "25vh", padding: "6px" }}
                >
                  <div className="speed_legend">Battery</div>
                  <div className="display_legend_icon">
                    <div className="speed_legend_unit">{`Percentage (%)`}</div>
                    <div className="color_legend">
                      <div className="color_legend_container">
                        <div className="good_color_box clb"></div>
                        <div className="trend_condition_text1">Good</div>
                        <div className="mode_color_box clb"></div>
                        <div className="trend_condition_text1">Moderate</div>
                        <div className="crit_color_box clb"></div>
                        <div className="trend_condition_text1">Critical</div>
                      </div>
                    </div>
                  </div>
                  <Line
                    data={data4}
                    height={"112%"}
                    options={batteryOptions}
                  ></Line>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Analytics;
