import React, { useState, useEffect } from 'react';
import { Alert, Button, CircularProgress,Dialog, DialogTitle, DialogActions, DialogContent, IconButton, Snackbar, Stack, Typography, TextField, DialogContentText, Box, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio} from '@mui/material';
import { ReactComponent as CrossIcon} from "../../Assets/CrossIcon.svg"
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Slide from "@mui/material/Slide";
import axios from 'axios';
import { MobileDatePicker, MobileTimePicker } from '@mui/x-date-pickers';
import InputAdornment from '@mui/material/InputAdornment';
import CalenderIcon from '@mui/icons-material/Event';
import TimeIcon from '@mui/icons-material/AccessTimeOutlined';
import { FotaUrl } from '../../constants';

function UpgradeDialog(props){
    const { onClose, open, device, allDeviceNameList, upgradeDeviceNameList } = props;
    const [ loading, setLoading] = useState(false);
    const [upgradeSheduleList, setList] = useState(new Map());
    const [newMap, setNewMap] = useState(new Map())
    const [ showAll, setShowAll] = useState(true);
    const [scheduleUpgrade, setScheduleUpgrade] = useState(false);
    const [upgradeAllDevice, setUpgradeAllDevice] = useState(false);
    const [firstOpen, setFirstOpen] = useState(true);
    
    useEffect(()=>{
        // console.log(device);
    }, [device])

    useEffect(()=>{
        // console.log(allDeviceNameList)
    },[allDeviceNameList])

    useEffect(()=>{
        // console.log("Available devices : " + upgradeDeviceNameList)
    })

    //setting up current date
    const [openDate, setOpenDate] = useState(false);
    const [openTime, setOpenTime] = useState(false);
    const current = new Date();
    const date = `${current.getFullYear()}-${current.getMonth()+1}-${current.getDate()}T${current.getHours()}:${current.getMinutes()}`;

    var minimumTimeSchdeuleTime = new Date(current.getTime()+(61*60*1000)) //60 minute ahead;
    var finalTime  = `${minimumTimeSchdeuleTime.getFullYear()}-${minimumTimeSchdeuleTime.getMonth()+1}-${minimumTimeSchdeuleTime.getDate()}T${minimumTimeSchdeuleTime.getHours()}:${minimumTimeSchdeuleTime.getMinutes()}`;

    const [time, setTime] = React.useState(dayjs(finalTime));
    const [minSettedTime, setMinSettedTime] = React.useState(dayjs(finalTime));

    let minDate = minimumTimeSchdeuleTime;
    // const maxLimit = `${current.getFullYear()+1}-${current.getMonth()+1}-${current.getDate()}`;

    const newMinDate = new Date(current.getTime()+30*60*1000) //30 minute ahead
    const nextDayDate = new Date(current.getTime() + (24 * 60 * 60 * 1000)); //1 day ahead  

    //next 6 month date
    const maxLimitDate = new Date();
    const [maxLimit, setMaxLimitTime] = useState("");
    useEffect(()=> {
        // maxLimitDate.setMonth(maxLimitDate.getMonth()+6);
        maxLimitDate.setDate(maxLimitDate.getDate()+5);
        setMaxLimitTime(`${maxLimitDate.getFullYear()}-${maxLimitDate.getMonth()+1}-${maxLimitDate.getDate()}`)
        // console.log("next 6 month date: " + maxLimit);
    })    
    
    //Open date component
    const handleDateOpen = () => {
        setOpenDate(true);
    }

    const handleDateClose = () => {
        setOpenDate(false);
    }

    const handleTimeOpen =()=> {
        setOpenTime(true);
    }
    const handleTimeClose =()=> {
        setOpenTime(false);
    }

    //dialog variables
    const [ dialogCount, setDialogCount ] = useState(1);

    //Alert Message variables
    const [ notifyToastOpen, setNotifyToast] = useState(false);
    const [ notifyToastMessage, setNotifyToastMessage ] = useState("");
    const [ toastType, setToastType] = useState("");

    const url = "https://kvia15r7r3.execute-api.ap-northeast-1.amazonaws.com/Prod/ota-update"

    useEffect(() => {
        // console.log("Device List: "+ JSON.stringify(props.device));
        // console.log("newww listttt " + JSON.stringify(props.upgradeDevice))
    }, [props.device])

    let testDate = new Date(new Date().setDate(new Date().getDate()+5));
    let maxTimeForTime = `${testDate.getFullYear()}-${testDate.getMonth()+1}-${testDate.getDate()}T${testDate.getHours()}:${testDate.getMinutes()}`;
    const [maxiTime, setMaxiTime] = React.useState(dayjs(maxTimeForTime))
    useEffect(() => {
        console.log("JSON: " + time.toJSON());
        console.log("JSON: " + time.toISOString());
        console.log("final: " + time.toISOString().substring(0, 16));
        console.log(time.hour() + " hours " + time.minute() + " minute");
        console.log(testDate.getDate(), testDate.getDay(), testDate.toDateString(), "\n", testDate.toString());
    }, [time]);

    //updating time when toggle happen between Upgrade now and Schedule Upgrade
    useEffect(()=>{
        //scheduling is going to be happen
        if(showAll === true){ 
            console.log("time updated")
            testDate = new Date(new Date().setDate(new Date().getDate()+5));
            minimumTimeSchdeuleTime = new Date(current.getTime()+(61*60*1000))
            finalTime = `${minimumTimeSchdeuleTime.getFullYear()}-${minimumTimeSchdeuleTime.getMonth()+1}-${minimumTimeSchdeuleTime.getDate()}T${minimumTimeSchdeuleTime.getHours()}:${minimumTimeSchdeuleTime.getMinutes()}`;
            setTime(dayjs(finalTime));
            setMinSettedTime(dayjs(finalTime));
            minDate = minimumTimeSchdeuleTime;
        }
    },[showAll, firstOpen])

    const handleTimeChange = (newValue) => {
        setTime(newValue);
    };

    // change the searchHistory map with a new Map from the current searchHistory, and the new key, value
    function onChange(key, value) {
        upgradeSheduleList.set(key, value);
        console.log(upgradeSheduleList);
    }

    //Toast functions
    //Opening toast
    const handleToastOpen = (name, type) => {
        console.log(name);
        setNotifyToast(true);
        setNotifyToastMessage(name);
        setToastType(type);
    };

    //Closing toast
    const handleToastClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setNotifyToast(false);
    };

    //toast Animation
    function SlideTransition(props) {
        return <Slide {...props} direction="down" />;
    }

    //Dialog functions
    const handleClose = (event, reason) => {
        if (reason && reason == "backdropClick") 
            return;
        onClose(true);
        // setLoading(false);
        setTimeout(() => {refreshPage()}, 2000);
        // setTimeout(()=>{setDialogCount(1)}, 1000);
    };
    const crossClose = (event, reason) => {
        if(reason && reason == "backdropClick")
            return;
        onClose(true);
        setLoading(false);
        setTimeout(()=>{setDialogCount(1)}, 1000);
        setShowAll(true);
        // setScheduleUpgrade(false);
        // setUpgradeAllDevice(false);
        setFirstOpen(true);
    }

    //refresh current page
    function refreshPage() {
        window.location.reload(false);
    }

    //handleShowAll
    const handleShowAll = (type) => {
        setShowAll(type);
        setFirstOpen(false);
    }
    
    const [upgradeListEmpty, setUpgradeListEmpty] = useState(false);
    const alertCheck = () => {
        var currentTime = new Date();
        currentTime.setTime(currentTime.getTime()+(61*60*1000))
        console.log(currentTime.toISOString().substring(0, 16), "\n", sheduledTime,"\n");
        console.log(time.minute(), "\n", current.getMinutes());
        console.log(time.hour() >= currentTime.getHours() && time.minute() >= current.getMinutes());
        if(time.hour() === currentTime.getHours()){
            if(time.minute() >= currentTime.getMinutes()){
                console.log("30 minute ahead from current time");
                setDialogCount(2);
            }
            else{
                handleToastOpen('Please Select 1 hour ahead  from current time...', "info")
            }
        }
        else if(time.hour() > currentTime.getHours()){
            console.log("correct time frame");
            setDialogCount(2);
        }
        else {
            console.log("before time");
            handleToastOpen('Please Select 1 hour ahead  from current time...', "info")
        }
    }

    //Upgading all selected devices <API CALL>
    const upgradeAll = async() => {
        console.log(props.upgradeDevice)
        console.log("upgrading all trigered...")
        console.log("JSON ENTRY Props: " + JSON.stringify(props.device));
        console.log([...props.device.values()])
        console.log(props.device);

        //intialising response body
        for(const element of props.upgradeDevice){ //new upgrade list [online, name] (need upgrade)
            newMap.set(Object.keys(element)[0], element[Object.keys(element)[0]]) //adding device name and its state in map as Key-Value pair            
            console.log(Object.keys(element)[0]) //getting name of devices
        }
        console.log(newMap);
        setLoading(true);
        //API Calling
        await axios.post(FotaUrl, {
            devices: [...newMap]
        }
        ).then(result => {
            console.log(result);
            setLoading(false);
            handleToastOpen('Upgradation successful, Refreshing...', "success")
            handleClose();            
        }).catch(error => {
            handleToastOpen('Upgradation unsuccessful, Refreshing...', "error")
            handleClose();
        })
    }
    //Scheduling Upgade, all selected devices <API CALL>
    let sheduledTime  = {
        start_time: time.toISOString().substring(0, 16)
    };
    const scheduleAll = async() => {
        // setLoading(true);
        console.log("Scheduling all...")
        setLoading(true);

        // newMap.set("Telematics-IOT-Device","online"); //test data
        //intialising
        for(const element of props.device){ //upgrade list (online, name) []
            newMap.set(Object.keys(element)[0], element[Object.keys(element)[0]]) //adding device name and its state in map as Key-Value pair            
            console.log(Object.keys(element)[0]) //getting name if devices
        }
        console.log(sheduledTime, [...newMap.keys()])

        //API calling
        await axios.post(FotaUrl, {
            devices: [...newMap],
            schedule_details: sheduledTime
        }
        ).then(result => {
            console.log(result);
            setLoading(false);
            handleToastOpen('Scheduling successful, Refreshing...', "success")
            handleClose();
        }).catch(error => {
            handleToastOpen('Upgradation unsuccessful, Refreshing...', "error")
            handleClose();
        })
    }
    let greenButtonStyle = {
        borderRadius:"0px", 
        width:"220px", 
        height: "36px",
        fontWeight: 400,
        fontSize: "14px",
        color: "white",
        textTransform: "none",
        bgcolor: "#3174A4",
        "&:hover":{backgroundColor: "#3174A4"}
    }
    let whiteButtonStyle = {
        borderRadius:"0px",
        width:"220px", 
        height: "36px",
        fontWeight: 400,
        fontSize: "14px",
        color: "black",
        textTransform: "none",
        borderColor:"white",
        bgcolor:"#F6F6F6",
        padding: "0px",
        "&:hover": {borderColor: "white"}
    }
    return (
        <div>
            {/* <Snackbar
                open={notifyToastOpen}
                autoHideDuration={4000}
                onClose={handleToastClose}
                TransitionComponent={SlideTransition}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
                <Alert severity={toastType}>
                    <Typography className='alertMessage'>{notifyToastOpen ? notifyToastMessage:undefined}</Typography>
                </Alert>
            </Snackbar> */}
            <div className='update_alert'>
            <Alert severity={toastType}>
                    <Typography className='alertMessage'>{notifyToastOpen ? notifyToastMessage:undefined}</Typography>
                </Alert>
            </div>
            <Dialog
                onClose={handleClose}
                open = {open}
                aria-labelledby = 'confirm-multi-OS-update'
                aria-describedby= 'confirm-multi-OS-choice'
                disableEscapeKeyDown
                PaperProps={{
                    sx: {
                        width: "500px",
                        borderRadius: "0px",
                        height: (dialogCount === 1)?"540px":undefined
                    }
                }}
            >
                {
                    loading ?
                    (<Stack direction="column" sx = {{display: "flex",alignItems: "center",textAlign: "center",position: "center", justifyContent: "center", pt: "43px", pb:"43px"}}>
                        <CircularProgress color="primary"/>
                        <Typography className='Initialising'>Initializing<span className="loader__dot">.</span><span className="loader__dot">.</span><span className="loader__dot">.</span></Typography>
                    </Stack>)
                    :
                    (<>
                    <DialogTitle
                        sx = {{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            height: "10px",
                            flexFLow: "row nowrap",
                            bgcolor: "#000",
                        }}
                    >
                        <Typography variant='h4'
                            sx = {{
                                fontStyle: "normal",
                                fontWeight: 700,
                                fontSize: "16px",
                                lineHeight: "21px",
                                color: "#FFF"
                            }}
                        >
                            {
                                (dialogCount === 1) ? "Software Upgrade" 
                                    : ((dialogCount === 2) || (dialogCount === 4) ) ? "Software Upgrade Confirmation"
                                        : (dialogCount === 3) ? "Schedule Upgrade"
                                            :undefined
                            }
                        </Typography>
                        <IconButton onClick = {crossClose}><CrossIcon/></IconButton>
                    </DialogTitle>
                    <DialogContent sx = {{alignContent:"left", overflow:"hidden"}}>
                            <DialogContentText sx = {{align:"justify",fontSize:"14px", pt:"10px", justifyContent:"left", textAlign:"left"}}>
                                {
                                    (dialogCount === 1) ? `Following vehicles / models are selected for software upgrade.`
                                        :(dialogCount === 2) ? `Are you sure you want to ${(showAll) ? "Schedule Upgrade" : "Upgrade"} the selected vehicles now?`
                                            :<></>
                                }

                            </DialogContentText>
                            {(dialogCount === 1)? 
                            <div>
                                <Box sx = {{border:1, height:"200px", mt:"20px", borderColor:"#E9ECEF", overflow:"auto", padding:"5px"}}>
                                    {
                                        (showAll)?
                                            (allDeviceNameList.length < 1) ? 
                                                <div className='emptyContent'>Empty...</div>
                                                :
                                                allDeviceNameList.map((item, index) => {
                                                // console.log(item[0], " ", item[1]);
                                                return (<div style = {{marginLeft:"12px", mt:"5px", display:"flex", flexDirection:"row"}}>{<div className='Listdot'></div>}{`${item[0]} `}({item[1]})</div>)
                                            }):
                                            (upgradeDeviceNameList.length < 1)?
                                                <div className = 'emptyContent2'>Zero Online Devices...</div>
                                                :
                                                upgradeDeviceNameList.map((item, index) => {
                                                    return (<div style = {{marginLeft:"12px", mt:"5px", display:"flex", flexDirection:"row"}}>{<div className='Listdot'></div>}{`${item[0]} `}({item[1]})</div>)
                                                })
                                    }
                                </Box>
                                <div className='radioButton'>
                                    <FormControl>
                                        <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            defaultValue={showAll}
                                            name="radio-buttons-group"
                                        >
                                            <FormControlLabel value="Upgrade Now" control={<Radio />} onClick = {()=>{handleShowAll(false)}} label="Upgrade Now" />
                                            <FormControlLabel value="Schedule Upgrade" control={<Radio />} onClick = {()=>{handleShowAll(true)}} label="Schedule Upgrade" />
                                        </RadioGroup>
                                    </FormControl>
                                </div>
                                <div style = {{color:"red", fontSize:"12px", marginBottom:"4px",height:"17px",display:(!showAll)?"block":"none"}}>
                                    
                                </div>
                                <div style = {{color:(firstOpen)?"#404145":"red", fontSize:"12px", marginBottom:"4px",display:(firstOpen)?"none":(showAll)?"block":"none", paddingLeft:"25px"}}>
                                {/* <div style = {{color:(!showAll)?"#404145":"red", fontSize:"12px", marginBottom:"4px", marginTop:"-5px", paddingLeft:"25px"}}> */}
                                    *Note: Min 1 hr from current time 
                                </div>
                                <Box sx = {{overflow:"auto", }}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <Stack direction="row" spacing={0.5}>
                                            <Stack direction="column" spacing={0.5}>
                                                <Typography sx = {{fontSize:"12px", color:"#8B8F9A"}}>Date:</Typography>
                                                <MobileDatePicker
                                                    disabled={(firstOpen)?true:(showAll===false) ? true : false}
                                                    disablePast = {true}
                                                    inputFormat="MM/DD/YYYY"
                                                    value={time}
                                                    onOpen = {handleDateOpen}
                                                    onClose = {handleDateClose}
                                                    minDate={minDate}
                                                    maxDate={maxLimit}
                                                    onChange={handleTimeChange}
                                                    renderInput={(params) => <TextField 
                                                                                {...params}
                                                                                size="small"
                                                                                InputProps={{
                                                                                    endAdornment: (
                                                                                    <InputAdornment position="end">
                                                                                        <IconButton edge="end" onClick={handleDateOpen}>
                                                                                        <CalenderIcon />
                                                                                        </IconButton>
                                                                                    </InputAdornment>
                                                                                    ),
                                                                                }} 
                                                                    />}
                                                />
                                            </Stack>
                                            <Stack direction="column" spacing={0.5}>
                                                <Typography sx = {{fontSize:"12px", color:"#8B8F9A"}}>Time:</Typography>
                                                <MobileTimePicker
                                                    disabled={(firstOpen)?true:(showAll===false) ? true : false}
                                                    value = {time}
                                                    minTime = {time.date() != minSettedTime.date() ? undefined :minSettedTime}
                                                    maxTime = {time.date() === testDate.getDate() ? maxiTime : undefined}
                                                    onChange = {handleTimeChange}
                                                    onOpen = {handleTimeOpen}
                                                    onClose = {handleTimeClose}
                                                    renderInput = {(params) => <TextField
                                                                                    {...params}
                                                                                    size="small"
                                                                                    InputProps={{
                                                                                        endAdornment:(
                                                                                            <InputAdornment position='end'>
                                                                                                <IconButton edge="end" onClick={handleTimeOpen}>
                                                                                                    <TimeIcon/>
                                                                                                </IconButton>
                                                                                            </InputAdornment>
                                                                                        )
                                                                                    }}
                                                                                /> }
                                                />
                                            </Stack>
                                        </Stack>
                                    </LocalizationProvider>
                                </Box>
                            </div>
                            :<></>}                          
                    </DialogContent>
                    <DialogActions style = {{ justifyContent: "center" }}>
                        {
                            (dialogCount === 1) ? 
                                (<Stack direction = "row" spacing={1}>
                                    <Button 
                                        variant='outlined'
                                        onClick = {handleClose}
                                        className = "upgradeButton"
                                        disableElevation
                                        sx = {whiteButtonStyle}
                                    >
                                        Cancel
                                    </Button>
                                    <Button 
                                        disabled = {(firstOpen)?true:(!showAll  && upgradeDeviceNameList.length < 1 )? true : (showAll && allDeviceNameList.length < 1)? true : false}
                                        variant='contained' 
                                        // onClick = {()=>{setDialogCount(2)}}alertCheck
                                        onClick={(showAll===true)?alertCheck:()=>{setDialogCount(2)}}
                                        sx = {greenButtonStyle} 
                                    >
                                        Continue
                                    </Button>
                                </Stack>)
                            :(dialogCount === 2) ?
                                (<Stack direction="row" spacing = {1}>
                                    <Button onClick={crossClose} variant="outlined" sx = {whiteButtonStyle}>No</Button>
                                    <Button onClick={(showAll) ? scheduleAll : upgradeAll} variant="contained"  sx = {greenButtonStyle}>Yes</Button>
                                </Stack>)
                            :undefined
                        }
                    </DialogActions>
                    </>)
                }
            </Dialog>
        </div>
    )
}

function UpgradeButton(props) {
    const[isDisable, setIsDisable] = useState(props.isDisable);
    const [open, setOpen] = useState(false);
    //handling open close dialog first-one
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = (value) => {
        setOpen(false);
    };   
    return (
        <div>
            <Button 
                variant="contained" 
                onClick={handleClickOpen}
                disabled = {(props.totalSelected < 1) ? true : false}
                className = "upgradeButton"
                disableElevation
                sx = {{ 
                    width:"160px",
                    height:"36px",
                    borderRadius: "0px",
                    bgcolor: "#3174A4",
                    color: "black",
                    fontWeight: 400,
                    textTransform: "none",
                    "&:hover": {backgroundColor: "#3174A4", }
                }}
            >
                <Typography sx = {{
                    fontWeight: 400,
                    fontSize:"14px",
                    color:(props.totalSelected < 1) ? "#8E939E" : "#FFFFFF"
                }}>Upgrade</Typography>
            </Button>
            <UpgradeDialog
                open={open}
                onClose={handleClose}
                device_name = {props.device_name}
                device_state = {props.device_state}
                deviceList = {props.deviceList}
                count = {props.count}
                totalSelected = {props.totalSelected}
                device = {props.device}
                upgradeDevice = {props.upgradeDevice}
                allDeviceNameList={props.allDeviceNameList}
                upgradeDeviceNameList={props.upgradeDeviceNameList}
                disableBackdropClick
            />
        </div>
    )
}

export default UpgradeButton