import React, { useEffect, useState } from "react";
import Map, { Marker } from "react-map-gl";
import axios from "axios";

function Maps({ vehicleData }) {
  const [address, setAddress] = useState("");
  // const lat = 12.9;
  // const lng = 77.6;
  const [lat, setLat] = useState(35.52397443761795);
  const [lng, setLng] = useState(-98.85205304506214);
  const [zoom, setZoom] = useState(14);

  // const [viewState, setViewState] = React.useState({
  //   longitude: lng,
  //   latitude: lat,
  //   zoom: 3.5
  // });

  const [viewport, setViewPort] = useState({
    longitude: lng,
    latitude: lat,
    width: "28vw",
    height: "37vh",
    zoom: zoom,
  });

  useEffect(() => {
    if (vehicleData !== undefined) {
      if (
        vehicleData.latitude !== undefined &&
        Math.abs(vehicleData.latitude) <= 90 &&
        Math.abs(vehicleData.longitude) <= 180
      ) {
        setLat(vehicleData.latitude);
        setLng(vehicleData.longitude);
        const url = `https://us1.locationiq.com/v1/reverse?key=pk.50a363bc9e7f747892fd053ed896287f&lat=${vehicleData.latitude}&lon=${vehicleData.longitude}&format=json`;
        axios.get(url).then((res) => {
          // console.log(res.data);

          var lctn = "";
          if (res.data.address.community_centre)
            lctn += res.data.address.community_centre;
          if (res.data.address.road) lctn += res.data.address.road + ", ";
          if (res.data.address.city_district)
            lctn += res.data.address.city_district + ", ";
          if (res.data.address.city) lctn += res.data.address.city + ", ";
          if (res.data.address.state) lctn += res.data.address.state + ", ";
          if (res.data.address.postcode)
            lctn += res.data.address.postcode + ", ";
          if (res.data.address.country) lctn += res.data.address.country + " ";
          if (res.data.address.monitoring_station)
            lctn += res.data.address.monitoring_station + " ";
          setAddress(lctn);
        })
        .catch((err)=>{
          console.log(err);
        });
      }
      else {
        setAddress("");
      }
    }
  });

  useEffect(() => {
    setViewPort({
      longitude: lng,
      latitude: lat,
      width: "28vw",
      height: "39vh",
      zoom: zoom,
    });
  }, [lat, lng, zoom]);

  return (
    <div className="location_area">
      {/* <ReactMapGL
        {...viewport}
        mapboxAccessToken='pk.eyJ1IjoiZGVlcGFrc2luZ2g5IiwiYSI6ImNsYXZ6ZmEyNTAyM3Azb3A3MzI0amw1MGwifQ.XR9N3Q6tAdjoLLybOqj_ig'
        mapStyle="mapbox://styles/mapbox/streets-v12"
        onViewportChange={viewpor => {
          setViewPort(viewpor);
        }}
      >
        
      </ReactMapGL> */}
      <Map
        mapboxAccessToken="pk.eyJ1IjoiZGVlcGFrc2luZ2g5IiwiYSI6ImNsYXZ6ZmEyNTAyM3Azb3A3MzI0amw1MGwifQ.XR9N3Q6tAdjoLLybOqj_ig"
        {...viewport}
        onMove={(evt) => setViewPort(evt.viewState)}
        onViewportChange={(viewpor) => {
          setZoom(viewpor.zoom);
          setViewPort(viewpor);
        }}
        // style={{borderRadius: '12px'}}
        mapStyle="mapbox://styles/mapbox/streets-v12"
      >
        <div className="address_map">
          <div className="current_location_map">Current Location : </div>
          <div className="address_location">{address}</div>
        </div>
        <Marker longitude={lng} latitude={lat} />
      </Map>
    </div>
  );
}

export default Maps;
