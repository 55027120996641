import React, { useRef } from "react";
import { useLocation, useNavigate } from 'react-router-dom';

const RenderModels = ({models, handleData}) => {
    const navigate = useNavigate();
    const selectedModel = useRef();
    
    
    
    const modelstyling = () => {
        navigate(`/dashboard/home/model/${models.vin}/vehicleDashboard`)
        // selectedModel.current.style.backgroundColor = "#E0E3E8";
        handleData(models.vin)
        
        
    }
    return (
        <div ref={selectedModel}>
            <div className='fleet_maange_state_level'  style={{cursor: "pointer"}} onClick={() => {modelstyling()}}>
                <div style={{marginLeft: '1.5vw'}} >
                    <div className='fleet_manage_state_level_name'>{models.registrationno}</div>
                    <div style={{color:'#828798', fontSize: '10px'}}>{models.model}</div>
                </div>
                
            </div>
        </div>
    )
}

export default RenderModels;