import React from "react";
import { Bar } from "react-chartjs-2";
import Chart from "chart.js/auto";
import axios from "axios";
import annotationPlugin from "chartjs-plugin-annotation";

Chart.register(annotationPlugin);
// {
//     data: surgeDat,
//     backgroundColor: backColor,
//     borderColor: backColor,
//     borderRadius: 40,
//     barPercentage: 0.6,
//     categoryPercentage: 0.7,
//   }
export const Operations = ({ OnTrip, avilableSeries, oprtnLabel }) => {
  const data2 = {
    labels: oprtnLabel,
    datasets: [
      {
        label: "Average Service Time",
        data: OnTrip,
        backgroundColor: "#3174A4",
        borderRadius: 1000,
        barPercentage: 0.5,
        categoryPercentage: 0.6,
        // borderSkipped: false
      },
      {
        label: "Average Idle Time",
        data: avilableSeries,
        backgroundColor: "#75B4E1",
        borderRadius: 1000,
        barPercentage: 0.5,
        categoryPercentage: 0.6,
        // borderSkipped: false
      },
    ],
  };
  const options = {
    plugins: {
      title: {
        display: false,
      },
      legend: false,
      datalabels: false,
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
        ticks: {
          fonstSize: 10,
        },
      },
      y: {
        stacked: true,
        title: {
          display: true,
          text: "Time in Hrs",
          font: {
            family: "Poppins",
            size: 11,
          },
          padding: { top: 0, left: 0, right: 0, bottom: 20 },
        },
        grid: {
          display: false,
        },
      },
    },
  };
  return (
    <div
      style={{
        width: "40vw",
        height: "24vh",
        marginLeft: "4vw",
        marginTop: "5px",
      }}
    >
      <Bar data={data2} options={options} />
    </div>
  );
};
