import React, { useEffect, useState } from "react";
import "./index.css";
import Maps from "./Maps";
import VehicleIdentify from "./VehicleIdentify";
import Reminder from "./Reminder";
import Engine from "./Engine";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../../../constants";

const VehicleDashboard = () => {
  let navigate = useNavigate();
  // const pathname = useLocation().pathname.split("/").pop() || "";
  const [vehicleData, setVehicleData] = useState([]);
  // useEffect(() => {
  //     if(pathname === ""){
  //         navigate("/home");
  //     }
  // },[])

  const handleData = (arr) => {
    // console.log(arr);
    setVehicleData(arr);
  };
  useEffect(() => {
    verifyCurrentUser();
  });

  const getJwtToken = () => {
    let token = localStorage.getItem("token") || undefined;
    return token === undefined ? "" : `${JSON.parse(token)}`;
  };

  const verifyCurrentUser = async () => {
    await axios
      .post(baseUrl + "/api/user/verify/", null, {
        headers: { Authorization: `Bearer ${getJwtToken()}` },
      })
      .then((result) => {
        console.log(result);
        console.log("Correct User Loged-In");
        if (result.data.bool != 1) {
          navigate("/login");
        }
      });
  };

  return (
    <div className="main_panel">
      <div className="bottom_main_area">
        <div className="bottom_main_left_area">
          <div className="dashboard_main_area">
            <VehicleIdentify handleData={handleData} />
            <div className="dashboard_right_side">
              <Engine vehicleData={vehicleData} />
              <div className="dashboard_right_bottom">
                <Maps vehicleData={vehicleData} />
                <Reminder vehicleData={vehicleData} />
              </div>
            </div>
          </div>
        </div>

        <div className="bottom_main_right_area">
          {/* <Profile/>
                    <Map/> */}
        </div>
      </div>
    </div>
  );
};

export default VehicleDashboard;
