import React, { useState, useEffect } from "react";
import "./softwareUpgrade.css";
import { baseSocketUrl, baseUrl, IntervalAPI ,PesSupported } from "../../constants";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Box, Typography ,Alert } from "@mui/material";
import GatewayImage from "../../Assets/gatewayImage.png";
import EcuImage from "../../Assets/ecuImage.png";

function InitialPage() {
  let navigate = useNavigate();

  const [model, setModel] = useState("Porsche GT3 RS");
  const [modelList, setModelList] = useState([]);
  const [gatewayList, setGatewayList] = useState([]);
  const [ecuList, setEcuList] = useState([]);
  const [ notifyToastOpen, setNotifyToast] = useState(false);
  const [ notifyToastMessage, setNotifyToastMessage ] = useState("");
  const [ toastType, setToastType] = useState("");

  useEffect(() => {
    console.log(model);
    localStorage.setItem('model', model);
  }, [model]);

  useEffect(() => {
    if (notifyToastOpen) {
      const timer = setTimeout(() => {
        setNotifyToast(false);
      }, 5000);   
      
      return () => clearTimeout(timer);
    }
  }, [notifyToastOpen]);

  // To Select Model
  const handleModel = (event) => {
    setModel(event.target.value);
    localStorage.setItem('model', event.target.value);
  };

  const handleToastOpen = (name, type) => {
    console.log(name);
    setNotifyToast(true);
    setNotifyToastMessage(name);
    setToastType(type);
  };

  // Api for Model List
  const getJwtToken = () => {
    let token = localStorage.getItem("token") || undefined;
    return token === undefined ? "" : `${JSON.parse(token)}`;
  };
  const url = baseUrl + "/get/vehiclemodels/";
  useEffect(() => {
    const fetchModelList = async () => {
      await axios
        .get(url, {
          headers: { Authorization: `Bearer ${getJwtToken()}` },
        })
        .then((res) => {
          const temp = res.data;
          setModelList(temp.vehicle_model_names);
          console.log(temp.vehicle_model_names);
        });
    };
    fetchModelList();
  }, []);

  // Api for harware List List
  const body4 = { vehiclemodel: model };
  useEffect(() => {
    const fetchModelList = async () => {
      await axios
        .post(baseUrl + "/get/hardwaretypes/", body4, {
          headers: { Authorization: `Bearer ${getJwtToken()}` },
        })
        .then((res) => {
          console.log(res, "\n", res.data.gateways, "\n", res.data.clients);
          setGatewayList(res.data.gateways);
          setEcuList(res.data.clients);
          console.log(gatewayList, "\n", ecuList);
        });
    };
    fetchModelList();
  }, [model]);

  return (
    <div className="initialSotwareUpgradePage">
      <div className="model_row">
        <div className="model_selector">
          <div>Model: </div>
          <div>
            <FormControl sx={{ minWidth: 199, minHeight: 36 }}>
              <Select
                value={model}
                onChange={handleModel}
                displayEmpty={true}
                style={{
                  height: 36,
                  fontFamily: "Poppins",
                  background: "white",
                  borderRadius: "0",
                }}
              >
                {modelList.map((item, index) => (
                  <MenuItem value={item}>{item}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
      </div>

      <Box sx={{ display: "flex", flexDirection: "row", marginTop: "2%" }}>
        <Typography className="boxName">GATEWAY </Typography>
        <div className="horizontalLine"></div>
      </Box>
      <div className="cards">
        {gatewayList.map((item, index) => (
          <div
            className="containerBox"
            onClick={() => {
              if(item[2]==PesSupported){
              navigate(`/dashboard/softwareUpgrade/${model}/${item[0]}`);
              }
              else{
                handleToastOpen("Currently Not Supported","info")
              }
            }}
          >
            <img src={GatewayImage} alt="gateway" className="image" />
            <div className="bottom-left">{item[0]}</div>
          </div>
        ))}
      </div>

      <Box sx={{ display: "flex", flexDirection: "row", marginTop: "2%" }}>
        <Typography className="boxName">ECU </Typography>
        <div className="horizontalLine"></div>
      </Box>
      <div className="cards">
        {ecuList.map((item, index) => (
          <div
            className="containerBox"
            onClick={() => {
              if(item[2]==PesSupported){
              navigate(`/dashboard/softwareUpgrade/${model}/${item[0]}`);
              }
              else{
                handleToastOpen("Currently Not Supported","info")
              }
            }}
          >
            <img src={EcuImage} alt="gateway" className="image" />
            <div className="bottom-left">{item[0]}</div>
          </div>
        ))}
      </div>
      <div className='update_alert'>
                {notifyToastOpen &&
                    <Alert severity={toastType}>
                        <Typography className='alertMessage'>{notifyToastMessage}</Typography>
                    </Alert>}
      </div>
    </div>
  );
}

export default InitialPage;
