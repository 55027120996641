import React, { useState } from 'react';
import './popUpReport.css';
import {
  Dialog as MuiDialog,
  DialogTitle,
  Typography,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  Button,
  Stack,
  CircularProgress,
} from "@mui/material";
import { ReactComponent as CrossIcon } from "../../Assets/CrossIcon.svg";
import LogsTable from '../TableView/LogsTable';
const ReportPopUp = ({ 
  //  onNoClick,
  dialogOpen,
  toggleDialog,
  title,
  content,
  actionsEnabled = false,
  loading = false,
  fullScreen = false,
  small = false,
  onSuccess,
  vin,
    }) => {
  
  const currentDate = new Date().toLocaleDateString();
  // const [responseText, setResponseText] = useState(
  //   "Short Summary: The driver distraction application is incorrectly detecting distractions in the cluster, such as 'Hair & Makeup' alerts even when the driver is driving safely.\n\nRoot cause analysis: The root cause of this issue lies in the algorithm used by the driver distraction application. It seems that the algorithm is not accurately differentiating between actual distractions and normal driving behavior. This could be due to a flaw in the programming logic or a lack of sufficient training data for the algorithm.\n\nDetailed description: The driver distraction application is an important feature that helps ensure the safety of the driver by alerting them to potential distractions. However, in this case, the application is providing incorrect detections in the instrument cluster. Even when the driver is driving safely, the application displays distractions like 'Hair & Makeup' alerts, which are clearly false positives.\n\nTo address this issue, a thorough analysis of the algorithm used by the driver distraction application is required. This analysis should focus on identifying any flaws in the programming logic that may be causing the incorrect detections. Additionally, it is important to evaluate the training data used to train the algorithm. If the algorithm has not been trained on a diverse range of driving scenarios, it may not be able to accurately differentiate between distractions and normal driving behavior.\n\nOnce the root cause of the issue is identified, appropriate measures can be taken to rectify it. This may involve updating the algorithm with improved logic or providing additional training data to enhance its accuracy. It is crucial to ensure that the driver distraction application functions correctly to provide reliable alerts and contribute to the overall safety of the vehicle."
  // );
  // console.log("Table data",content);

return (
  <MuiDialog
  open={dialogOpen}
  PaperProps={small ? styles.paperPropsSmall : styles.paperProps}
  fullScreen={fullScreen}
  maxWidth={"md"}
>     
<DialogTitle sx={styles.titleContainer}>
          <Typography variant="h4" sx={styles.title}>
            {title}
          </Typography>
          <IconButton onClick={toggleDialog}>
            <CrossIcon />
          </IconButton>
        </DialogTitle>
 

{content== null ? (
  <div style={small ? styles.loaderSmall : styles.loader}>
    <CircularProgress color="primary" />
  </div>
) : (
  // dialogBody()
  <div style={{padding:'20px'}}>
  <LogsTable data={content} vin={vin}/>
  </div>
)}
 </MuiDialog>
  // <div className="Scanpopup">
   
  //   <div className="Scanpopup-content">
  //   <div className='Scanpopup-title'>
  //       Vehicle Scanning
  //   </div>
    
  //     <div className='Scanissue-description'>
     
  
  //   <Player src="Vehicle_Scan.mp4" />
  //       </div>
  //     {/* <button  className='yesButton' onClick={onYesClick}>Create Jira Ticket</button> */}
  //     <button className='ScancloseButton' onClick={onNoClick}>Close</button>
  //   </div>
  // </div>
);
};

export default ReportPopUp;
const styles = {
  paperProps: {
    sx: {
      borderRadius: "0px",
      minWidth: "90vw",
      minHeight: "90vh",
    },
  },
  paperPropsSmall: {
    sx: {
      borderRadius: "0px",
      minWidth: "60vh",
      minHeight: "30vh",
    },
  },
  titleContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "10px",
    flexFLow: "row nowrap",
    bgcolor: "#000",
  },
  title: {
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "21px",
    color: "#FFF",
  },
  greenButtonStyle: {
    borderRadius: "0px",
    width: "220px",
    height: "36px",
    fontWeight: 400,
    fontSize: "14px",
    color: "white",
    textTransform: "none",
    bgcolor: "#3174A4",
    "&:hover": { backgroundColor: "#3174A4" },
  },
  whiteButtonStyle: {
    borderRadius: "0px",
    width: "220px",
    height: "36px",
    fontWeight: 400,
    fontSize: "14px",
    color: "black",
    textTransform: "none",
    borderColor: "white",
    bgcolor: "#F6F6F6",
    padding: "0px",
    "&:hover": { borderColor: "white" },
  },
  dialogContent: {
    // display: "flex",
    // flexDirection: "column",
    // justifyContent: "space-between",
    // alignContent: "left",
    marginTop: "24px",
    overflow: "hidden",
    overflowY: "auto",
  },
  dialogContentText: {
    fontSize: "16px",
    textAlign: "center",
    padding: '8px'
  },
  dialogActions: { padding: "0px 24px 12px 24px" },
  loader: {
    minHeight: "80vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  loaderSmall: {
    minHeight: "25vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};
