import * as React from "react";
import MuiAccordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TableView from "../TableView/TableView";
import {useState, useEffect } from "react";

const Accordion = ({ data }) => {

  const [expanded, setExpanded] = useState(0);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  if (data.length === 0)
    return <div style={styles.centered}>No Data Found!</div>;
  return (
    <div>
      {data.map((scan, i) => (
        <MuiAccordion key={scan?.GATEWAY?.VIN} 
        expanded={expanded === i}
        onChange={handleChange(i)}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>{`SCAN ${i + 1}`}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TableView scan={scan}/>
          </AccordionDetails>
          {/* <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}> Scan result logged into JIRA and notified the developer team</div> */}
        </MuiAccordion>
      ))}
    </div>
  );
};

export default Accordion;

const styles = {
  centered: {
    minWidth: "70vw",
    minHeight: "70vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  heading: {
    fontSize: "1rem",
    fontWeight: "bold",
    marginBottom: "24px",
    color: "#aeacac",
  },
  label: {
    fontSize: "0.875rem",
  },
  value: {},
};
